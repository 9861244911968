import { supabase } from "../../supabase";
import { uploadPhotoEmergency, uploadPhotoWaste } from "./EditSiteFn";

export async function NewSiteFn(data: any) {
  //Site Aerial
  data.image = await uploadPhoto(data);

  //Emergency Form Change
  if (data.contactFormLink) {
    data.contactFormLink = await uploadPhotoEmergency(data, undefined); //no existing
  } else {
    data.contactFormLink = "";
  }

  //Waste Form Change
  if (data.wasteFormLink) {
    data.wasteFormLink = await uploadPhotoWaste(data, undefined); //no existing
  } else {
    data.wasteFormLink = "";
  }

  delete data.picture;

  return new Promise(function (resolve, reject) {
    supabase
      .from("sites")
      .insert(data)
      .then((res) => {
        if (res.error === null) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      });
  });
}

async function uploadPhoto(info: any) {
  await supabase.storage
    .from("sites")
    .upload(`${info.siteNumber}/aerial`, info.image[0]);

  return `${info.siteNumber}/aerial`;
}
