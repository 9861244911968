import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Delete } from "@mui/icons-material";
import styled from "@emotion/styled";
import { OPERATING_COMPANIES, REPORTING_TYPES } from "../../../SharedConstants";
import { UploadSdsSheetFn } from "../../../backends/commands/UploadSdsSheetFn";
import { StyledInner, StyledWrapper } from "../EmergencyResponse.styles";
import { UploadDragger } from "../../../components/uploadDragger/UploadDragger";
import { UploadContactPdfFn } from "../../../backends/commands/UploadContactPdfFn";

interface Contact {
  company: string;
  clientName: string;
  number?: string;
  email?: string;
  address?: string;
  tags?: Array<string>;
}

interface UploadContactDialogProps {
  contact?: Contact;
  onSuccess: () => void;
  state: string;
}

export const UploadPdfDialog = ({
  contact,
  onSuccess,
  state,
}: UploadContactDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 619px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [fileError, setFileError] = useState(false);
  const [files, setFiles] = useState<Array<any>>([]);

  const [showFileName, setShowFileName] = useState();

  const handleReset = () => {
    setFileError(false);
    setFiles([]);
    setErrorText("");
    setError(false);
    setShowFileName(undefined);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
    handleReset();
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async () => {
    if (!files.length) {
      return;
    }

    setErrorText("");
    setError(false);
    setShowSpinner(true);

    const response = await UploadContactPdfFn(files[0], state);

    if (!response) {
      setErrorText("Error adding PDF");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleFileSelected = (e: any) => {
    setShowFileName(e.documentName);
    setFiles([e]);
  };

  return (
    <div>
      {iconOnly ? (
        <Button
          onClick={handleClickOpen}
          style={{ fontFamily: "'Maven Pro', sans-serif" }}
          color="success"
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      ) : (
        <Tooltip title="Add PDF">
          <IconButton
            onClick={handleClickOpen}
            style={{ fontFamily: "'Maven Pro', sans-serif" }}
            color="success"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledWrapper>
          <IconButton
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
            size="large"
            disabled={showSpinner}
          >
            <CloseIcon />
          </IconButton>
          {/* BOUNDARY INSIDE */}
          <FadeIn>
            <StyledInner>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                <b>Emergency Response PDF</b>
              </Typography>

              {showFileName !== undefined ? (
                <p
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    textAlign: "center",
                    margin: 10,
                  }}
                >
                  {showFileName}
                </p>
              ) : (
                <div
                  style={{
                    marginTop: 10,
                    textAlign: "center",
                    border: fileError ? "1px solid red" : "none",
                    width: "auto",
                    padding: 10,
                  }}
                >
                  <UploadDragger returnDocument={handleFileSelected} />
                </div>
              )}

              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "auto",
                }}
              >
                <Button
                  disabled={showSpinner}
                  variant="contained"
                  style={{
                    marginTop: 30,
                    width: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontFamily: "'Maven Pro', sans-serif",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={handleSave}
                >
                  {showSpinner ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    <>Submit</>
                  )}
                </Button>
              </div>
              {error ? (
                <Typography style={{ color: "red", textAlign: "center" }}>
                  *{errorText}
                </Typography>
              ) : null}
            </StyledInner>
          </FadeIn>
        </StyledWrapper>
      </Dialog>
    </div>
  );
};

const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

const StyledSelectField = styled(Select)`
  & .MuiOutlinedInput-input {
    border-color: black;
  }

  & .MuiInputBase-formControl {
    border-color: black;
  }

  &:before {
    border-color: black;
  }

  &:after {
    border-color: black;
  }
`;
