import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { contactsPdfs } from "../store/Store";
import { useEffect } from "react";
import { GetContactsPdfsFn } from "../backends/queries/GetContactsPdfsFn";

export const useContactsPdfs = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery(
    "contactsPdfs",
    GetContactsPdfsFn
  );
  const [contactsPdfsList, setContactsPdfsList] = useRecoilState(contactsPdfs);

  useEffect(() => {
    isSuccess && data.data && setContactsPdfsList(data.data);
  }, [data?.data, isSuccess, setContactsPdfsList]);

  return {
    contactsPdfs: contactsPdfsList,
    refreshContactsPdfs: refetch,
    contactsPdfsloading: isLoading,
  };
};
