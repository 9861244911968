import Alaska from "./stateImages/Alaska.jpg";
import Arizona from "./stateImages/Arizona.jpg";
import California from "./stateImages/California.jpg";
import Hawaii from "./stateImages/Hawaii.jpg";
import Idaho from "./stateImages/Idaho.jpg";
import Montana from "./stateImages/Montana.jpg";
import Nevada from "./stateImages/Nevada.jpg";
import Oregon from "./stateImages/Oregon.jpg";
import Washington from "./stateImages/Washington.jpg";
import * as Yup from "yup";

export const getStateImage = (state: string) => {
  switch (state) {
    case "Alaska":
      return Alaska;
    case "Arizona":
      return Arizona;
    case "California":
      return California;
    case "Hawaii":
      return Hawaii;
    case "Idaho":
      return Idaho;
    case "Montana":
      return Montana;
    case "Nevada":
      return Nevada;
    case "Oregon":
      return Oregon;
    case "Washington":
    default:
      return Washington;
  }
};

export const YupSchemaContacts = () => {
  return Yup.object().shape({
    company: Yup.string().required("Required"),
    clientName: Yup.string().required("Required"),
    number: Yup.string()
      .matches(
        /^(1-)?\d{3}-\d{3}-\d{4}$/,
        "Must include '-' character. Example: 555-555-5555"
      )
      .notRequired(),
    email: Yup.string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid Email")
      .notRequired(),
    address: Yup.string().notRequired(),
    tags: Yup.array().of(Yup.string().notRequired()).notRequired(),
  });
};
