import { LibraryItemDefinition } from "./Library.types";

import { SdsItemComponent } from "./Library.components";
import {
  AdminPanelSettings,
  BugReport,
  CalendarMonth,
  CheckBox,
  // DocumentScanner,
  FilterHdr,
  HealthAndSafety,
  LocalHospital,
  Public,
} from "@mui/icons-material";

const FacilitiesItem: LibraryItemDefinition = {
  link: "/facilities",
  label: "Papé Facilities",
  intro: "A list of all available sites and reporting information.",
  icon: FilterHdr,
  svgColor: "#B6E388",
};

const EmergencyItem: LibraryItemDefinition = {
  link: "/emergency-response",
  label: "Emergency Response",
  intro: "Emergency contacts and PDFs by state.",
  icon: LocalHospital,
  svgColor: "#F65A83",
};

const ComplianceOverviewItem: LibraryItemDefinition = {
  link: "/compliance-overview",
  label: "Compliance Overview",
  intro: "Overview of all sites showing reporting completion status.",
  icon: CheckBox,
  svgColor: "#8CC0DE",
};

const CalendarItem: LibraryItemDefinition = {
  link: "/calendar",
  label: "Calendar",
  intro: "Calendar view with past and upcoming reporting.",
  icon: CalendarMonth,
  svgColor: "#ECB390",
};

// const DocumentEditorItem: LibraryItemDefinition = {
//   link: "/training",
//   label: "Document Editor",
//   intro: "PDF document editor and archive of past reports submitted.",
//   disabled: true,
//   icon: DocumentScanner,
//   svgColor: "#F7F6DC",
// };

const SDSItem: LibraryItemDefinition = {
  link: "/sds-library",
  label: "SDS Library",
  intro: "Safety Data Sheets and archive.",
  icon: HealthAndSafety,
  svgColor: "#54BAB9",
  component: SdsItemComponent,
};

const GlobalDocumentsItem: LibraryItemDefinition = {
  link: "/global-documents",
  label: "Global Documents",
  intro: "Helpful documents not site specific.",
  icon: Public,
  svgColor: "#6E85B7",
};

const FeedbackItem: LibraryItemDefinition = {
  link: "/feedback",
  label: "Feedback Form",
  intro:
    "Feedback, suggestions, and bug reporting for the site development team.",
  icon: BugReport,
  svgColor: "#FFB72B",
};

const AdministratorItem: LibraryItemDefinition = {
  link: "/administrator",
  label: "Administrator",
  intro: "User privileges and new user requests.",
  icon: AdminPanelSettings,
  svgColor: "#d52bff",
};

export const LibraryItems: Array<LibraryItemDefinition> = [
  FacilitiesItem,
  ComplianceOverviewItem,
  EmergencyItem,
  SDSItem,
  GlobalDocumentsItem,
  CalendarItem,
  FeedbackItem,
  AdministratorItem,
];
