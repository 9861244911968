import { Close, Done, Storage } from "@mui/icons-material";
import { Switch, Typography } from "@mui/material";
import { ChangeFieldStatusFn } from "../../../backends/commands/ChangeFieldStatusFn";

export const YearlyType = (field: any, site: any, onSuccess: any) => {
  const switchEvent = async (site: any, field: any, fieldName: any) => {
    const data = {
      site: site,
      field: field,
      fieldName: fieldName,
    };

    const updatedInfo: any = await ChangeFieldStatusFn(data);

    if (updatedInfo) {
      onSuccess(updatedInfo.data[0]);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography
        style={{
          fontSize: 14.5,
          fontFamily: "'Maven Pro', sans-serif",
        }}
      >
        Yearly status: {field.status}
      </Typography>

      {field.status === "Done" ? (
        <Done
          style={{
            color: "green",
            fontSize: 21,
            marginLeft: 2,
          }}
        />
      ) : (
        <Close
          style={{
            color: "red",
            fontSize: 21,
            marginLeft: 2,
          }}
        />
      )}
      <Switch
        checked={field.status === "Done"}
        color="success"
        onChange={() => switchEvent(site, field, field.name)}
      />
    </div>
  );
};

export const MonthyType = (field: any, site: any, onSuccess: any) => {
  const switchEvent = async (site: any, field: any, fieldName: any) => {
    const data = {
      site: site,
      field: field,
      fieldName: fieldName,
    };

    const updatedInfo: any = await ChangeFieldStatusFn(data);

    if (updatedInfo) {
      onSuccess(updatedInfo.data[0]);
    }
  };

  return (
    <>
      <Typography
        style={{
          fontSize: 14.5,
          marginTop: 15,
          fontFamily: "'Maven Pro', sans-serif",
        }}
      >
        Monthly Statuses:
      </Typography>
      {field.months.map((month: any, idx: number) => (
        <div key={idx} style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ fontFamily: "'Maven Pro', sans-serif", fontSize: 14.5 }}
          >
            {month.month}
          </Typography>
          {month.status === "Done" ? (
            <Done
              style={{
                color: "green",
                fontSize: 21,
                marginLeft: 2,
              }}
            />
          ) : (
            <Close
              style={{
                color: "red",
                fontSize: 21,
                marginLeft: 2,
              }}
            />
          )}
          <Switch
            checked={month.status === "Done"}
            color="success"
            onChange={() => switchEvent(site, month, field.name)}
          />
        </div>
      ))}
    </>
  );
};

export const QuarterlyType = (field: any, site: any, onSuccess: any) => {
  const switchEvent = async (site: any, field: any, fieldName: any) => {
    const data = {
      site: site,
      field: field,
      fieldName: fieldName,
    };

    const updatedInfo: any = await ChangeFieldStatusFn(data);

    if (updatedInfo) {
      onSuccess(updatedInfo.data[0]);
    }
  };

  return (
    <>
      <Typography
        style={{
          fontSize: 14.5,
          fontFamily: "'Maven Pro', sans-serif",
        }}
      >
        Quarterly Statuses:
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: 400,
          marginBottom: 20,
        }}
      >
        {field.quarters.map((q: any, idx: number) => (
          <div key={idx} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontFamily: "'Maven Pro', sans-serif",
                fontSize: 14.5,
              }}
            >
              {q.name}
            </Typography>
            {q.status === "Done" ? (
              <Done
                style={{
                  color: "green",
                  fontSize: 21,
                  marginLeft: 2,
                }}
              />
            ) : (
              <Close
                style={{
                  color: "red",
                  fontSize: 21,
                  marginLeft: 2,
                }}
              />
            )}
            <Switch
              checked={q.status === "Done"}
              color="success"
              onChange={() => switchEvent(site, q, field.name)}
            />
          </div>
        ))}
      </div>
      <Typography
        style={{
          marginBottom: 5,
          fontFamily: "'Maven Pro', sans-serif",
          fontSize: 14.5,
        }}
      >
        Q1: July, August, September
      </Typography>
      <Typography
        style={{
          marginBottom: 5,
          fontFamily: "'Maven Pro', sans-serif",
          fontSize: 14.5,
        }}
      >
        Q2: October, November, December
      </Typography>
      <Typography
        style={{
          marginBottom: 5,
          fontFamily: "'Maven Pro', sans-serif",
          fontSize: 14.5,
        }}
      >
        Q3: January, February, March
      </Typography>
      <Typography
        style={{
          marginBottom: 5,
          fontFamily: "'Maven Pro', sans-serif",
          fontSize: 14.5,
        }}
      >
        Q4: April, May, June
      </Typography>
    </>
  );
};

export const getExternalDbLink = (reportType: string, field: any) => {
  switch (reportType) {
    case "Tier II":
    case "Waste Management":
    case "Stormwater":
    case "Wastewater":
      return (
        <a href={field.externalDB} target="_blank" rel="noreferrer">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Storage style={{ fontSize: 18, marginTop: 1 }} />

            <Typography
              style={{
                fontFamily: "'Maven Pro', sans-serif",
                fontSize: 15,
                marginLeft: 6,
              }}
            >
              External Database
            </Typography>
          </div>
        </a>
      );
    default:
  }
};
