import { supabase } from "../../supabase";

export const UploadSdsSheetFn = async (values: any) => {
  return new Promise(async function (resolve, reject) {
    for (const data of values.documents) {
      let urlUpload = await uploadPhoto(data);

      if (urlUpload.error) {
        return resolve(false);
      }

      data.path = `sheets/${data.documentName}`;
      await supabase.from("sds").insert(data);
    }

    return resolve(true);
  });
};

async function uploadPhoto(info: any) {
  const test = await supabase.storage
    .from("sds")
    .upload(`sheets/${info.documentName}`, info.path);

  return test;
}
