import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Delete } from "@mui/icons-material";
import styled from "@emotion/styled";
import { YupSchema } from "../SdsLibrary.utils";
import { StyledInner, StyledWrapper } from "../SdsLibrary.styles";
import { OPERATING_COMPANIES } from "../../../SharedConstants";
import { UploadSdsSheetFn } from "../../../backends/commands/UploadSdsSheetFn";

interface SDSDocument {
  documentName: string;
  casNumber: Array<string>;
  archived: boolean;
}

interface Values {
  documents: Array<SDSDocument>;
}

const UploadDialog = (props: any) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 1082px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async (values: any) => {
    const existingNames = props.sds.map((sheet: any) => sheet.documentName);
    const newNames = values.documents.map((sheet: any) => sheet.documentName);
    const foundName = existingNames.some((r: any) => newNames.includes(r));

    if (foundName) {
      setErrorText("Document name(s) already exists");
      setError(true);
      return;
    }

    setErrorText("");
    setError(false);

    setShowSpinner(true);

    const response = await UploadSdsSheetFn(values);

    if (!response) {
      setErrorText("Upload failed");
      setError(true);
      setShowSpinner(false);
    } else {
      props.onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  return (
    <div>
      {iconOnly ? (
        <Button
          onClick={handleClickOpen}
          style={{ fontFamily: "'Maven Pro', sans-serif" }}
          color="success"
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      ) : (
        <Tooltip title="Add Safety Data Sheet">
          <IconButton
            onClick={handleClickOpen}
            style={{ fontFamily: "'Maven Pro', sans-serif" }}
            color="success"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik<Values>
          enableReinitialize
          initialValues={{
            documents: [],
          }}
          validationSchema={YupSchema}
          onSubmit={handleSave}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <StyledWrapper>
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={showSpinner}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <FadeIn>
                  <StyledInner>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      <b>Upload SDS (Safety Data Sheet)</b>
                    </Typography>
                    {values.documents.length === 0 && (
                      <UploadComponent setFieldValue={setFieldValue} />
                    )}

                    <FieldArray name="documents">
                      {({ remove }) => (
                        <div
                          style={{
                            width: "100%",
                            overflowY: "auto",
                            padding: "10px 0 10px 0",
                          }}
                        >
                          {values.documents.length > 0 &&
                            values.documents.map((document, idx) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: 10,
                                      fontFamily: "'Maven Pro', sans-serif",
                                    }}
                                  >
                                    <b>{values.documents[idx].documentName}</b>
                                  </p>
                                  <div style={{ flexGrow: 1 }} />
                                  <div
                                    style={{
                                      margin: "auto",
                                      marginLeft: 5,
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => remove(idx)}
                                      disabled={showSpinner}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                </div>
                                <StyledTextField
                                  disabled={showSpinner}
                                  fullWidth
                                  style={{
                                    marginBottom: 10,
                                    fontFamily: "'Maven Pro', sans-serif",
                                  }}
                                  label="Chemical Name"
                                  id="outlined-size-normal"
                                  name={`documents.${idx}.chemicalName`}
                                  // error={
                                  //   !!errors.documents?.[idx].chemicalName &&
                                  //   touched.documents?.[idx].chemicalName
                                  // }
                                  variant="outlined"
                                  onChange={handleChange}
                                />
                                <div>
                                  <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                  >
                                    <InputLabel
                                      id="outlined-age-native-simple"
                                      style={{
                                        fontFamily: "'Maven Pro', sans-serif",
                                      }}
                                    >
                                      Operating Company
                                    </InputLabel>
                                    <StyledSelectField
                                      MenuProps={{
                                        disableScrollLock: true,
                                      }}
                                      disabled={showSpinner}
                                      labelId="outlined-age-native-simple"
                                      label="Operating Company"
                                      id="outlined-age-native-simple"
                                      name="document.operatingCompany"
                                      style={{
                                        fontFamily: "'Maven Pro', sans-serif",
                                      }}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `documents.${idx}.operatingCompany`,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {OPERATING_COMPANIES.map((oc, idx) => (
                                        <MenuItem
                                          style={{
                                            fontFamily:
                                              "'Maven Pro', sans-serif",
                                          }}
                                          key={idx}
                                          value={oc}
                                        >
                                          {oc}
                                        </MenuItem>
                                      ))}
                                    </StyledSelectField>
                                  </FormControl>
                                </div>
                                <Form>
                                  <FieldArray
                                    name={`documents.${idx}.casNumber`}
                                  >
                                    {({ remove }) => (
                                      <div
                                        style={{
                                          width: "100%",
                                          overflowY: "auto",
                                          maxHeight: 150,
                                          marginTop: 10,
                                          // padding: "10px 0 10px 0",
                                        }}
                                      >
                                        {values.documents[idx].casNumber
                                          .length > 0 &&
                                          values.documents[idx].casNumber.map(
                                            (cas, casIndex) => (
                                              <div
                                                key={casIndex}
                                                style={{
                                                  display: "flex",
                                                  marginBottom: 5,
                                                  marginTop: 10,
                                                }}
                                              >
                                                <StyledTextField
                                                  disabled={showSpinner}
                                                  name={`documents.${idx}.casNumber.${casIndex}`}
                                                  label="CAS #"
                                                  id="outlined-size-normal"
                                                  variant="outlined"
                                                  onChange={handleChange}
                                                  fullWidth
                                                  style={{
                                                    fontFamily:
                                                      "'Maven Pro', sans-serif",
                                                  }}
                                                />

                                                <div
                                                  style={{
                                                    margin: "auto",
                                                    marginLeft: 5,
                                                  }}
                                                >
                                                  <IconButton
                                                    disabled={showSpinner}
                                                    onClick={() =>
                                                      remove(casIndex)
                                                    }
                                                  >
                                                    <Delete />
                                                  </IconButton>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    )}
                                  </FieldArray>
                                </Form>
                                <Button
                                  disabled={showSpinner}
                                  fullWidth
                                  startIcon={<AddIcon />}
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    fontFamily: "'Maven Pro', sans-serif",
                                  }}
                                  onClick={() =>
                                    setFieldValue(
                                      `documents.${idx}.casNumber`,
                                      [...values.documents[idx].casNumber, ""]
                                    )
                                  }
                                >
                                  Add CAS # (optional)
                                </Button>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    value={values.documents[idx].archived}
                                    name={`documents.${idx}.archived`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        `documents.${idx}.archived`,
                                        event.currentTarget.value
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio />}
                                      label={
                                        <Typography
                                          style={{
                                            fontFamily:
                                              "'Maven Pro', sans-serif",
                                          }}
                                        >
                                          Active
                                        </Typography>
                                      }
                                    />
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label={
                                        <Typography
                                          style={{
                                            fontFamily:
                                              "'Maven Pro', sans-serif",
                                          }}
                                        >
                                          Archived
                                        </Typography>
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                                {values.documents.length - 1 !== idx && (
                                  <Divider />
                                )}
                              </>
                            ))}
                        </div>
                      )}
                    </FieldArray>

                    {values.documents.length > 0 && (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "auto",
                        }}
                      >
                        <Button
                          disabled={showSpinner}
                          variant="contained"
                          style={{
                            marginTop: 30,
                            width: "70%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontFamily: "'Maven Pro', sans-serif",
                            backgroundColor: "black",
                            color: "white",
                          }}
                          onClick={submitForm}
                        >
                          {showSpinner ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                            />
                          ) : (
                            <>Submit</>
                          )}
                        </Button>
                      </div>
                    )}
                    {error ? (
                      <Typography style={{ color: "red", textAlign: "center" }}>
                        *{errorText}
                      </Typography>
                    ) : null}
                  </StyledInner>
                </FadeIn>
              </StyledWrapper>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

const UploadComponent = (props: any) => {
  const context = useFormikContext<Values>();
  const { setFieldValue } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "text/html": [".html", ".htm"],
      "application/msword": [".doc"],
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      const documents = acceptedFiles.map((document) => {
        return {
          documentName: document.name,
          chemicalName: "",
          operatingCompany: "",
          casNumber: [],
          path: document,
          archived: false,
        };
      });
      setFieldValue("documents", documents);
    },
    maxFiles: 3,
  });

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      style={{
        border:
          context.errors["documents"] && context.touched["documents"]
            ? "1px solid red"
            : "0.5px dashed grey",
        borderRadius: 5,
        padding: "20px 10px",
        color: "grey",
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag files here or click to select</p>
      )}
    </div>
  );
};

const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

const StyledSelectField = styled(Select)`
  & .MuiOutlinedInput-input {
    border-color: black;
  }

  & .MuiInputBase-formControl {
    border-color: black;
  }

  &:before {
    border-color: black;
  }

  &:after {
    border-color: black;
  }
`;

export default UploadDialog;
