import { EditableProfileValues } from "../../pages/profile/EditProfileDialog";
import { supabase } from "../../supabase";

export const UpdateProfileFn = async (
  values: EditableProfileValues,
  email: string
) => {
  return new Promise(async function (resolve, reject) {
    let update = await supabase
      .from("users")
      .update({
        name: values.name,
        location: values.location,
        position: values.position,
        introduction: values.introduction,
      })
      .eq("email", email);

    return resolve(update);
  });
};
