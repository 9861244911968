import * as Yup from "yup";

const minTest = (value: any) => {
  if (value.length === 0) {
    return false;
  } else {
    return true;
  }
};

const maxTest = (value: any) => {
  if (value.length > 5) {
    return false;
  } else {
    return true;
  }
};

export const YupSchema = () => {
  return Yup.object().shape({
    documents: Yup.array()
      .of(
        Yup.object().shape({
          documentName: Yup.string(),
          chemicalName: Yup.string()
            .min(1)
            .max(255)
            .trim()
            .required("Required"),
          operatingCompany: Yup.string().required("Required"),
        })
      )
      .required("Required")
      .test("Length Min", "Minimum of 1 file", (value) => minTest(value))
      .test("Length Max", "Maximum of 5 files", (value) => maxTest(value)),
  });
};

export const sortChemicalName = (a: any, b: any) => {
  return a.chemicalName < b.chemicalName
    ? -1
    : a.chemicalName > b.chemicalName
    ? 1
    : 0;
};
