import styled from "@emotion/styled";
import { TextField, Typography, Button, Card } from "@mui/material";

export const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

export const StyledText = styled(Typography)({
  fontSize: 14,
  cursor: "pointer",
  marginTop: 10,
  width: "fitContent",
  marginLeft: "auto",
  marginRight: "auto",
  fontFamily: "'Maven Pro', sans-serif",
  "&:hover": {
    color: "#c4c4c4",
  },
});

export const StyledButton = styled(Button)({
  background: "black",
  border: 0,
  borderRadius: 3,
  color: "white",
  height: 48,
  padding: "0 30px",
  fontFamily: "'Maven Pro', sans-serif",
  "&.MuiButton-outlined": {
    borderColor: "white",
  },
  "&:hover": {
    background: "#000",
    color: "#5c5c5c",
  },
});

export const StyledCard = styled(Card)`
  width: 350px;
  max-width: 800px;
  padding: 20px !important;
  align-self: center;
  min-width: 250px;
  text-align: center;
  background-color: rgb(255, 255, 255);
`;

export const StyledNavWrapper = styled.div`
  height: 45px;
  background-color: #000;
  z-index: 5;
  width: 100%;
  position: fixed;
  top: 0;
`;

export const StyledInnerNav = styled.div`
  width: 100%;
  max-width: 2000px;
  display: flex;
  justify-content: space-between;
`;

export const StyledBackground = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  flex-grow: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
