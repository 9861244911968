import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import {
  Button,
  Card,
  CardContent,
  InputBase,
  Table,
  Typography,
  Divider,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import React from "react";
import { debounce } from "lodash";
import AlertToaster from "../../components/alert/Alert";
import { ViewRequests } from "./ViewRequests";
import { NewUserDialog } from "./NewUserDialog";
import { supabase } from "../../supabase";
import { useSites } from "../../hooks/useSites";
import { useUsers } from "../../hooks/useUsers";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { ButtonIconButton } from "../../components/button/ButtonIconButton";
import { Save, Undo } from "@mui/icons-material";
import { StyledAutoComplete, StyledGridWrapper } from "./Administrator.styles";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { UpdatePasswordDialog } from "./UpdatePasswordDialog";

export const Administrator = React.memo(() => {
  const mobile = useMediaQuery("(min-width: 975px)");
  const iconOnly = useMediaQuery("(min-width: 500px)");
  const [selectedUser, setSelectedUser] = useState<any>();
  const [searchString, setSearchString] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { sites, refreshSites } = useSites();
  const { users, refreshUsers } = useUsers();

  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      setSearchString(criteria);
      setShowSpinner(false);
    }, 1000)
  ).current;

  async function debounceString(e: any) {
    if (!selectedUser) {
      return;
    }
    setShowSpinner(true);
    debouncedSearch(e.target.value);
  }

  const onSaveNewUser = (resetUser?: boolean) => {
    if (resetUser) {
      setSelectedUser(undefined);
    }
    setShowSuccessDialog(true);

    setTimeout(function () {
      setShowSuccessDialog(false);
    }, 3000);
  };

  useEffect(() => {
    if (showSuccessDialog) {
      refreshSites();
      refreshUsers();
    }
  }, [refreshSites, refreshUsers, showSuccessDialog]);

  const onSubmit = async (values: any) => {
    let test = await supabase
      .from("users")
      .update({
        cantDelete: `{${values.cantDelete}}`,
        cantUpload: `{${values.cantUpload}}`,
        cantView: `{${values.cantView}}`,
        cantEdit: `{${values.cantEdit}}`,
      })
      .eq("email", selectedUser.email);

    if (test.error !== null) {
      setShowErrorDialog(true);
    } else {
      setSelectedUser({
        ...selectedUser,
        cantView: values.cantView,
        cantUpload: values.cantUpload,
        cantEdit: values.cantEdit,
        cantDelete: values.cantDelete,
      });
      setShowSuccessDialog(true);
    }

    setTimeout(function () {
      setShowSuccessDialog(false);
      setShowErrorDialog(false);
    }, 3000);
  };

  const userChanged = (_e: any, values: any) => {
    setSelectedUser(values);
  };

  const updateArray = (e: any, addArr: Array<any>) => {
    let updateArr = [...addArr];

    if (updateArr.includes(e)) {
      updateArr = updateArr.filter((siteNumber) => siteNumber !== e);
    } else {
      updateArr.push(e);
    }

    return updateArr;
  };

  const filterSites = () => {
    var filteredSites = sites;

    if (searchString) {
      filteredSites = filteredSites.filter((e: any) => {
        if (
          e.name.toLowerCase().includes(searchString.toLowerCase()) ||
          e.city.toLowerCase().includes(searchString.toLowerCase()) ||
          e.state.toLowerCase().includes(searchString.toLowerCase()) ||
          e.siteNumber.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return e;
        } else {
          return undefined;
        }
      });
    }

    return filteredSites ?? [];
  };

  return (
    <PageWrapper>
      <>
        {showSuccessDialog ? (
          <AlertToaster message="Success" severity="success" />
        ) : null}
      </>
      <>
        {showErrorDialog ? (
          <AlertToaster message="Error" severity="error" />
        ) : null}
      </>
      <div
        style={{
          display: "flex",
          gap: 5,
          width: "100%",
          marginBottom: 10,
          flexWrap: "wrap",
        }}
      >
        <ViewRequests onSave={onSaveNewUser} />
        <NewUserDialog onSave={onSaveNewUser} />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 5,
          marginBottom: 10,
        }}
      >
        <StyledAutoComplete
          value={selectedUser ?? null}
          disablePortal
          fullWidth={!iconOnly}
          options={users}
          onChange={userChanged}
          getOptionLabel={(user: any) => user.email}
          sx={{ minWidth: 300 }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DeleteUserDialog user={selectedUser} onSave={onSaveNewUser} />
        <UpdatePasswordDialog user={selectedUser} onSave={onSaveNewUser} />

        {mobile && (
          <>
            <Box style={{ flexGrow: 1 }} />
            <InputBase
              id="quickFilter"
              onInput={debounceString}
              style={{
                fontFamily: "'Maven Pro', sans-serif",
                border: "0.5px solid #cccccc",
                padding: 5,
                borderRadius: 5,
                height: 36.6,
                width: 300,
              }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
            />
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: mobile ? "row" : "column",
          paddingBottom: 10,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flexGrow: 1,
            maxWidth: mobile ? 600 : "100%",
            minWidth: 300,
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              name: selectedUser ? selectedUser.name : "",
              location: selectedUser ? selectedUser.location : "",
              position: selectedUser ? selectedUser.position : "",
              introduction: selectedUser ? selectedUser.introduction : "",
              yearsWithCompany: selectedUser
                ? selectedUser.yearsWithCompany
                : "",
            }}
            // validationSchema={YupSchema}
            onSubmit={onSubmit}
          >
            {({ errors, submitForm, handleChange, values, resetForm }) => (
              <Form
                style={{ display: "flex", gap: 10, flexDirection: "column" }}
              >
                <Card>
                  <CardContent>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      Name - {values.name}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 13,
                      }}
                    >
                      Position - {values.position}
                    </Typography>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      Introduction
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 13,
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {values.introduction}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      Years with Company
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      color="text.secondary"
                    >
                      {values.yearsWithCompany}
                    </Typography>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "'Maven Pro', sans-serif",
                        marginBottom: 2,
                      }}
                    >
                      Upload History
                    </Typography>
                    <div
                      style={{
                        width: "100%",
                        border: "1px solid #dddddd",
                        borderRadius: 5,
                        overflowY: "scroll",
                        padding: 5,
                        maxHeight: "calc(100vh - 489px)",
                        minHeight: 65,
                      }}
                    >
                      {selectedUser && selectedUser.documents.length > 0 ? (
                        selectedUser.documents.map(
                          (document: any, idx: number, arr: Array<any>) => (
                            <div key={idx}>
                              <span
                                style={{
                                  fontSize: 14,
                                  fontFamily: "'Maven Pro', sans-serif",
                                }}
                              >
                                {document.siteNumber} : {document.name}
                              </span>
                              <span
                                style={{
                                  fontSize: 13,
                                  fontFamily: "'Maven Pro', sans-serif",
                                  display: "inline-block",
                                  marginBottom: 5,
                                  width: "100%",
                                }}
                              >
                                {document.fileName} : {document.uploadDate}
                              </span>
                              {idx !== arr.length - 1 && <Divider />}
                            </div>
                          )
                        )
                      ) : (
                        <div>
                          <span
                            style={{
                              fontSize: 13,
                              fontFamily: "'Maven Pro', sans-serif",
                              display: "inline-block",
                            }}
                          >
                            No Upload History
                          </span>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Form>
            )}
          </Formik>
        </div>
        <div style={{ width: "100%", marginBottom: 10, paddingBottom: 10 }}>
          {!mobile && (
            <div style={{ display: "flex", marginBottom: 10 }}>
              <Box style={{ flexGrow: 1 }} />
              <InputBase
                id="quickFilter"
                onInput={debounceString}
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                  border: "0.5px solid #cccccc",
                  padding: 5,
                  borderRadius: 5,
                  height: 36.6,
                  width: mobile ? 300 : "100%",
                }}
                placeholder="Search..."
                inputProps={{ "aria-label": "search google maps" }}
              />
            </div>
          )}
          <Formik
            enableReinitialize
            initialValues={{
              cantView: selectedUser?.cantView ?? [],
              cantUpload: selectedUser?.cantUpload ?? [],
              cantEdit: selectedUser?.cantEdit ?? [],
              cantDelete: selectedUser?.cantDelete ?? [],
            }}
            onSubmit={onSubmit}
          >
            {({ submitForm, values, setFieldValue, resetForm, dirty }) => (
              <StyledGridWrapper className="ag-theme-alpine">
                <TableContainer
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "0.5px solid #cccccc",
                    overflowY: "auto",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                        >
                          Site Name
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                        >
                          View
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                        >
                          Upload
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                        >
                          Edit
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                        >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {showSpinner ? (
                      <CircularProgress
                        color="inherit"
                        style={{
                          position: "relative",
                          zIndex: 5,
                          marginTop: 10,
                          marginLeft: 10,
                        }}
                        size={30}
                      />
                    ) : (
                      <TableBody>
                        <>
                          {selectedUser && sites && filterSites().length > 0 ? (
                            <>
                              {filterSites().map((site: any, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "'Maven Pro', sans-serif",
                                      }}
                                    >
                                      {site.name}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "'Maven Pro', sans-serif",
                                        display: "inline-block",
                                        color: "#7a7a7a",
                                        marginBottom: 5,
                                        width: "100%",
                                      }}
                                    >
                                      {site.siteNumber}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <Switch
                                      onClick={() =>
                                        setFieldValue(
                                          "cantView",
                                          updateArray(
                                            site.siteNumber,
                                            values.cantView
                                          )
                                        )
                                      }
                                      checked={
                                        !values.cantView.includes(
                                          site.siteNumber
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Switch
                                      onClick={() =>
                                        setFieldValue(
                                          "cantUpload",
                                          updateArray(
                                            site.siteNumber,
                                            values.cantUpload
                                          )
                                        )
                                      }
                                      checked={
                                        !values.cantUpload.includes(
                                          site.siteNumber
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Switch
                                      onClick={() =>
                                        setFieldValue(
                                          "cantEdit",
                                          updateArray(
                                            site.siteNumber,
                                            values.cantEdit
                                          )
                                        )
                                      }
                                      checked={
                                        !values.cantEdit.includes(
                                          site.siteNumber
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Switch
                                      onClick={() =>
                                        setFieldValue(
                                          "cantDelete",
                                          updateArray(
                                            site.siteNumber,
                                            values.cantDelete
                                          )
                                        )
                                      }
                                      checked={
                                        !values.cantDelete.includes(
                                          site.siteNumber
                                        )
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          ) : null}
                        </>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div style={{ display: "flex", marginTop: 10, gap: 5 }}>
                  <Box style={{ flexGrow: 1 }} />
                  <ButtonIconButton
                    iconOnly={true}
                    label="Undo Changes"
                    onClick={() => resetForm()}
                    icon={<Undo />}
                    tooltip="Undo Changes"
                    color="info"
                    disabled={!selectedUser || !dirty}
                  />
                  <ButtonIconButton
                    iconOnly={true}
                    label="Save Changes"
                    onClick={submitForm}
                    icon={<Save />}
                    tooltip="Save Changes"
                    color="success"
                    disabled={!selectedUser || !dirty}
                  />
                </div>
              </StyledGridWrapper>
            )}
          </Formik>
        </div>
      </div>
    </PageWrapper>
  );
});

const testData = [
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
  {
    site: "TestSite - 123",
    documentName: "FakeDocumentName.pdf",
    reportType: "Hazardous Waste",
    date: "02/22/2022",
  },
];
