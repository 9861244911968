import PreviewIcon from "@mui/icons-material/Preview";
import { MenuItem, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertToaster from "../../components/alert/Alert";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import {
  StyledButtonWrapper,
  StyledGridWrapper,
  StyledInput,
  StyledTextField,
} from "../sdsLibrary/SdsLibrary.styles";
import { ButtonIconButton } from "../../components/button/ButtonIconButton";
import { CATEGORIES } from "./GlobalDocuments.constants";
import { GlobalDocumentUploadDialog } from "./GlobalDocumentUploadDialog";
import { StyledHeader } from "./GlobalDocuments.styles";
import { useGlobalDocuments } from "../../hooks/useGlobalDocuments";
import { DeleteGlobalDocumentFn } from "../../backends/commands/DeleteGlobalDocumentFn";
import { DownloadGlobalDocumentFn } from "../../backends/commands/DownloadGlobalDocumentFn";

export const GlobalDocuments = () => {
  const iconOnly = useMediaQuery("(min-width: 619px)");
  const [rowCountDoc, setRowCountDoc] = useState(0);
  const [columnDefs] = useState([{ field: "fileName" }, { field: "category" }]);
  const [selectedRowDoc, setSelectedRowDoc] = useState<any>();
  const [categoryFilter, setCategoryFilter] = useState("None");
  const [successAlert, setSuccessAlert] = useState(false);
  const { globalDocuments, loading, refreshGlobalDocuments } =
    useGlobalDocuments();
  const gridRef = useRef<AgGridReact<any>>(null);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
  }, []);

  const onQuickFilterChangedDocs = useCallback(() => {
    const input = document?.getElementById(
      "quickFilterDocs"
    ) as HTMLInputElement;
    gridRef?.current?.api.setQuickFilter(input.value);
  }, []);

  function onSelectionChangeDocument(event: any) {
    var rowCount = event.api.getSelectedNodes().length;
    setRowCountDoc(rowCount);
  }

  function onRowClickedDocument(event: any) {
    if (event) {
      if (event.node.data === selectedRowDoc) {
        event.api.deselectAll();
        setSelectedRowDoc(undefined);
      } else {
        setSelectedRowDoc(event.node.data);
      }
    } else {
      setSelectedRowDoc(undefined);
    }
  }

  const openGlobalDocumentLink = () => {
    if (selectedRowDoc) {
      const windowReference = window.open();

      DownloadGlobalDocumentFn(selectedRowDoc.path).then((res: any) => {
        if (windowReference) {
          windowReference.location = res.signedURL;
        }
      });
    }
  };

  const deleteGlobalDocument = async () => {
    const res: any = await DeleteGlobalDocumentFn(selectedRowDoc);

    if (res.error !== null) {
    } else {
      onSuccess();
      setRowCountDoc(0);
      setSelectedRowDoc(undefined);
    }
  };

  const onSuccess = () => {
    setSuccessAlert(true);
    refreshGlobalDocuments();
    setTimeout(function () {
      setSuccessAlert(false);
    }, 3000);
  };

  const getRowData = () => {
    return categoryFilter === "None"
      ? globalDocuments
      : globalDocuments.filter(
          (globalDocument) => globalDocument.category === categoryFilter
        );
  };

  return (
    <PageWrapper loading={loading}>
      <>
        {successAlert ? (
          <AlertToaster message="Success" severity="success" />
        ) : null}
      </>
      <StyledHeader>Global Documents</StyledHeader>

      <StyledButtonWrapper>
        <GlobalDocumentUploadDialog onSuccess={onSuccess} />
        <ButtonIconButton
          iconOnly={iconOnly}
          label={"Delete"}
          onClick={deleteGlobalDocument}
          icon={<DeleteIcon />}
          tooltip={"Delete Global Document"}
          color="error"
          disabled={rowCountDoc !== 1}
        />
        <ButtonIconButton
          iconOnly={iconOnly}
          label={"View"}
          onClick={openGlobalDocumentLink}
          icon={<PreviewIcon />}
          tooltip={"View Global Document"}
          color="primary"
          disabled={rowCountDoc !== 1}
        />

        <Box sx={{ flexGrow: 1 }} />
        <StyledTextField
          style={{
            width: 185,
          }}
          variant="outlined"
          select
          id="demo-simple-select"
          value={categoryFilter}
          label="Category"
          onChange={(category: any) => setCategoryFilter(category.target.value)}
        >
          {CATEGORIES.map((cat, idx) => (
            <MenuItem
              style={{ fontFamily: "'Maven Pro', sans-serif" }}
              key={idx}
              value={cat}
            >
              {cat}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledInput
          id="quickFilterDocs"
          onInput={onQuickFilterChangedDocs}
          placeholder="Search..."
          inputProps={{
            "aria-label": "search google maps",
          }}
        />
      </StyledButtonWrapper>
      <StyledGridWrapper className="ag-theme-alpine">
        <AgGridReact
          suppressDragLeaveHidesColumns
          ref={gridRef}
          rowData={getRowData()}
          columnDefs={columnDefs}
          rowSelection="single"
          defaultColDef={defaultColDef}
          onSelectionChanged={onSelectionChangeDocument}
          onRowClicked={onRowClickedDocument}
          rowDeselection
        />
      </StyledGridWrapper>
    </PageWrapper>
  );
};
