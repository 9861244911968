import { SetStateAction, useState } from "react";
import River from "../../images/River.jpg";
import {
  EmailFields,
  LoginFields,
  RequestLoginFields,
  StyledNavBar,
} from "./Login.components";
import { StyledBackground, StyledCard } from "./Login.styled";
import { LOGIN, FORGOT_PASSWORD, REQUEST_LOGIN } from "./Login.constants";

const Login = () => {
  const [showField, setShowField] = useState(LOGIN);

  const onSetShowField = (field: SetStateAction<number>) => {
    setShowField(field);
  };

  return (
    <StyledBackground
      style={{
        background: `url(${River})`,
      }}
    >
      <StyledNavBar />
      <StyledCard>
        {showField === LOGIN && <LoginFields setShowField={onSetShowField} />}
        {showField === FORGOT_PASSWORD && (
          <EmailFields setShowField={onSetShowField} />
        )}
        {showField === REQUEST_LOGIN && (
          <RequestLoginFields setShowField={onSetShowField} />
        )}
      </StyledCard>
    </StyledBackground>
  );
};

export default Login;
