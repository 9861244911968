import React from "react";
import { Facilities } from "./pages/facilities/Facilities";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Calendar } from "./pages/calendar/Calendar";
import { ComplianceOverview } from "./pages/complianceOverview/ComplianceOverview";
import { Profile } from "./pages/profile/Profile";
import { AuthProvider, useProvideAuth } from "./auth/AuthProvider";
import AuthRoute from "./auth/AuthRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { Library } from "./pages/library/Library";
import { SdsLibrary } from "./pages/sdsLibrary/SdsLibrary";
import { EmergencyResponse } from "./pages/emergencyResponse/EmergencyResponse";
import { GlobalDocuments } from "./pages/globalDocuments/GlobalDocuments";
import { Feedback } from "./pages/feedback/Feedback";
import { Administrator } from "./pages/administrator/Administrator";

const queryClient = new QueryClient();

function App() {
  const auth = useProvideAuth();
  const showAdminPanel = () => {
    const admins = process.env.REACT_APP_ADMIN.split(", ");
    return admins.includes(auth?.user?.email);
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Library />
                  </AuthRoute>
                }
              />
              <Route
                path="/facilities"
                element={
                  <AuthRoute>
                    <Facilities />
                  </AuthRoute>
                }
              />
              <Route
                path="/calendar"
                element={
                  <AuthRoute>
                    <Calendar />
                  </AuthRoute>
                }
              />
              <Route
                path="/compliance-overview"
                element={
                  <AuthRoute>
                    <ComplianceOverview />
                  </AuthRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <AuthRoute>
                    <Profile />
                  </AuthRoute>
                }
              />
              <Route
                path="/sds-library"
                element={
                  <AuthRoute>
                    <SdsLibrary />
                  </AuthRoute>
                }
              />
              <Route
                path="/global-documents"
                element={
                  <AuthRoute>
                    <GlobalDocuments />
                  </AuthRoute>
                }
              />
              <Route
                path="/emergency-response"
                element={
                  <AuthRoute>
                    <EmergencyResponse />
                  </AuthRoute>
                }
              />

              <Route
                path="/feedback"
                element={
                  <AuthRoute>
                    <Feedback />
                  </AuthRoute>
                }
              />

              <>
                {showAdminPanel() && (
                  <Route
                    path="/administrator"
                    element={
                      <AuthRoute>
                        <Administrator />
                      </AuthRoute>
                    }
                  />
                )}
              </>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </QueryClientProvider>
        </RecoilRoot>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
