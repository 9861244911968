import { useState, useEffect, useContext, createContext } from "react";
import { supabase } from "../supabase";

const authContext = createContext<any>(undefined);

export const AuthProvider = ({ children }: any) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

export function useProvideAuth() {
  const [user, setUser] = useState<any>(supabase.auth.user());

  const login = async (email: string, password: string) => {
    const { error, user } = await supabase.auth.signIn({ email, password });

    return { error, user };
  };

  const logout = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  useEffect(() => {
    const auth = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_OUT") setUser(null);
      if (event === "SIGNED_IN") {
        setUser(session?.user);
      }
    });

    return () => auth.data?.unsubscribe();
  }, []);

  return {
    user,
    login,
    logout,
  };
}
