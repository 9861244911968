import { Typography, InputAdornment, CircularProgress } from "@mui/material";
import { useState } from "react";
import { supabase } from "../../supabase";
import { FORGOT_PASSWORD, REQUEST_LOGIN, LOGIN } from "./Login.constants";
import {
  StyledTextField,
  StyledButton,
  StyledText,
  StyledNavWrapper,
  StyledInnerNav,
} from "./Login.styled";
import PapeLogo from "../../images/PapeVector.svg";
import {
  AdminPanelSettings,
  CheckCircleOutline,
  Email,
  Key,
  Person,
  PersonOutline,
} from "@mui/icons-material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  EmailValues,
  FieldProps,
  LoginValues,
  RequestLoginValues,
} from "./Login.types";
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

export const Submitted = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <CheckCircleOutline />
      <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
        Request Sent
      </Typography>
    </div>
  );
};

export const LoginFields = (props: FieldProps) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const onSubmit = async (values: LoginValues) => {
    setShowSpinner(true);
    setShowError(false);

    const signIn = await auth.login(values.email, values.password);

    if (signIn.error) {
      setShowSpinner(false);
      setShowError(true);
    } else {
      navigate("/");
    }
  };

  const YupSchema = () => {
    return Yup.object().shape({
      email: Yup.string().email("Not a valid email").required("Required"),
      password: Yup.string().required("Required"),
    });
  };

  return (
    <>
      <Typography
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          color: "rgb(0, 0, 0)",
        }}
      >
        Papé Environmental
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: "", password: "" }}
        validationSchema={YupSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          submitForm,
          handleChange,
          resetForm,
          values,
          setFieldValue,
          touched,
        }) => (
          <Form style={{ display: "flex", flexDirection: "column" }}>
            <StyledTextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              error={Boolean(errors.email) && touched.email}
              helperText={errors.email ?? ""}
              name="email"
              type="text"
              style={{ marginTop: 20 }}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              name="password"
              type="password"
              id="outlined-basic"
              error={Boolean(errors.password) && touched.password}
              helperText={errors.password ?? ""}
              label="Password"
              variant="outlined"
              style={{ marginTop: 20 }}
              onChange={handleChange}
              onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Key />
                  </InputAdornment>
                ),
              }}
            />
            {showError && (
              <Typography
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                  color: "red",
                  fontSize: 14,
                  margin: 5,
                }}
              >
                Invalid Email or Password
              </Typography>
            )}
            {showSpinner ? (
              <StyledButton
                variant="outlined"
                style={{ marginTop: 20 }}
                disabled
              >
                <CircularProgress style={{ color: "white" }} size={30} />
              </StyledButton>
            ) : (
              <StyledButton
                variant="outlined"
                style={{ marginTop: 20 }}
                onClick={submitForm}
              >
                Login
              </StyledButton>
            )}
            <StyledText onClick={() => props.setShowField(FORGOT_PASSWORD)}>
              Forgot Password?
            </StyledText>
            <StyledText onClick={() => props.setShowField(REQUEST_LOGIN)}>
              Request Login Credentials
            </StyledText>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const EmailFields = (props: FieldProps) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);

  const onSubmit = async (values: EmailValues) => {
    setShowSpinner(true);
    setShowError(false);

    const test = await supabase.functions.invoke("emailForgotPassword", {
      responseType: "text",
      body: JSON.stringify({ email: values.email }),
    });

    if (test.error === null) {
      setShowSubmitted(true);
    } else {
      setShowError(true);
    }

    setShowSpinner(false);
  };

  const YupSchema = () => {
    return Yup.object().shape({
      email: Yup.string().email("Not a valid email").required("Required"),
    });
  };

  return (
    <>
      <Typography
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          color: "rgb(0, 0, 0)",
        }}
      >
        Forgot Password
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: "" }}
        validationSchema={YupSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          submitForm,
          handleChange,
          resetForm,
          values,
          setFieldValue,
          touched,
        }) => (
          <Form style={{ display: "flex", flexDirection: "column" }}>
            {!showSubmitted ? (
              <>
                <StyledTextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  error={Boolean(errors.email) && touched.email}
                  helperText={errors.email ?? ""}
                  name="email"
                  type="text"
                  style={{ marginTop: 20 }}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />

                {showError && (
                  <Typography
                    style={{
                      fontFamily: "'Maven Pro', sans-serif",
                      color: "red",
                      fontSize: 14,
                      margin: 5,
                    }}
                  >
                    Error Sending Request
                  </Typography>
                )}

                {showSpinner ? (
                  <StyledButton
                    variant="outlined"
                    style={{ marginTop: 20 }}
                    disabled
                  >
                    <CircularProgress style={{ color: "white" }} size={30} />
                  </StyledButton>
                ) : (
                  <StyledButton
                    variant="outlined"
                    style={{ marginTop: 20 }}
                    onClick={submitForm}
                  >
                    Submit Request
                  </StyledButton>
                )}
              </>
            ) : (
              <Submitted />
            )}
            <StyledText onClick={() => props.setShowField(LOGIN)}>
              Login
            </StyledText>
            <StyledText onClick={() => props.setShowField(REQUEST_LOGIN)}>
              Request Login Credentials
            </StyledText>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const RequestLoginFields = (props: FieldProps) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);

  const onSubmit = async (values: RequestLoginValues) => {
    setShowSpinner(true);
    setShowError(false);

    const usersTableFound = await supabase
      .from("users")
      .select()
      .eq("email", values.email.toLowerCase());
    const requestsTableFound = await supabase
      .from("requests")
      .select()
      .eq("email", values.email.toLowerCase());

    if (
      (usersTableFound.body && usersTableFound.body.length > 0) ||
      (requestsTableFound.body && requestsTableFound.body.length > 0)
    ) {
      setShowError(true);
      setShowSpinner(false);
      return;
    }

    supabase
      .from("requests")
      .insert({
        name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        reasonForRequest: values.reasonForRequest,
      })
      .then(async (res) => {
        if (res.error === null) {
          setShowSubmitted(true);
        } else {
          setShowError(true);
        }
      });

    setShowSpinner(false);
  };

  const YupSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .min(2, "Minimum (2) Characters")
        .required("Required"),
      lastName: Yup.string()
        .min(2, "Minimum (2) Characters")
        .required("Required"),
      email: Yup.string().email("Not a valid email").required("Required"),
      reasonForRequest: Yup.string()
        .min(10, "Minimum (10) Characters")
        .required("Required"),
    });
  };

  return (
    <>
      <Typography
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          color: "rgb(0, 0, 0)",
        }}
      >
        Request Login Credentials
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          reasonForRequest: "",
        }}
        validationSchema={YupSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          submitForm,
          handleChange,
          resetForm,
          values,
          setFieldValue,
          touched,
        }) => (
          <Form style={{ display: "flex", flexDirection: "column" }}>
            {!showSubmitted ? (
              <>
                <StyledTextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  error={Boolean(errors.firstName) && touched.firstName}
                  helperText={errors.firstName ?? ""}
                  name="firstName"
                  type="text"
                  style={{ marginTop: 20 }}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  error={Boolean(errors.lastName) && touched.lastName}
                  helperText={errors.lastName ?? ""}
                  name="lastName"
                  type="text"
                  style={{ marginTop: 20 }}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutline />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  error={Boolean(errors.email) && touched.email}
                  helperText={errors.email ?? ""}
                  name="email"
                  type="text"
                  style={{ marginTop: 20 }}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  id="outlined-basic"
                  label="Reason for Request"
                  variant="outlined"
                  error={
                    Boolean(errors.reasonForRequest) && touched.reasonForRequest
                  }
                  helperText={errors.reasonForRequest ?? ""}
                  name="reasonForRequest"
                  multiline
                  rows={3}
                  type="text"
                  style={{ marginTop: 20 }}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AdminPanelSettings />
                      </InputAdornment>
                    ),
                  }}
                />
                {showError && (
                  <Typography
                    style={{
                      fontFamily: "'Maven Pro', sans-serif",
                      color: "red",
                      fontSize: 14,
                      margin: 5,
                    }}
                  >
                    Error Sending Request
                  </Typography>
                )}
                {showSpinner ? (
                  <StyledButton
                    variant="outlined"
                    style={{ marginTop: 20 }}
                    disabled
                  >
                    <CircularProgress style={{ color: "white" }} size={30} />
                  </StyledButton>
                ) : (
                  <StyledButton
                    variant="outlined"
                    style={{ marginTop: 20 }}
                    onClick={submitForm}
                  >
                    Submit Request
                  </StyledButton>
                )}
              </>
            ) : (
              <Submitted />
            )}
            <StyledText onClick={() => props.setShowField(LOGIN)}>
              Login
            </StyledText>
            <StyledText onClick={() => props.setShowField(FORGOT_PASSWORD)}>
              Forgot Password?
            </StyledText>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const StyledNavBar = () => {
  return (
    <StyledNavWrapper>
      <StyledInnerNav>
        <img
          src={PapeLogo}
          alt="pape"
          style={{
            width: 45,
            marginLeft: 10,
            marginTop: 0,
            height: 45,
          }}
        />
      </StyledInnerNav>
    </StyledNavWrapper>
  );
};
