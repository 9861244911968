import { useDropzone } from "react-dropzone";

export const UploadDragger = (props: any) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "text/html": [".html", ".htm"],
      "application/msword": [".doc"],
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      const documents = acceptedFiles.map((document) => {
        return {
          documentName: document.name,
          path: document,
        };
      });
      props.returnDocument(documents[0]);
    },
    multiple: false,
  });

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      style={{
        border: "0.5px dashed grey",
        borderRadius: 5,
        padding: "20px 10px",
        color: "grey",
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag files here or click to select</p>
      )}
    </div>
  );
};
