import { GetUserFn } from "./../backends/queries/GetUserFn";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { user } from "../store/Store";
import { useEffect } from "react";

export const useUser = (email: string) => {
  const { data, isSuccess, refetch, isLoading } = useQuery("user", () =>
    GetUserFn(email)
  );

  const [userData, setUserData] = useRecoilState(user);

  useEffect(() => {
    isSuccess && data.data && setUserData(data.data);
  }, [data?.data, isSuccess, setUserData]);

  return { user: userData, refresh: refetch, loading: isLoading };
};
