import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Avatar,
  CardMedia,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Formik, Form } from "formik";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import * as Yup from "yup";
import ProfileAvatar from "../../images/ProfileAvatar.svg";
import { DialogWrapper } from "../../components/dialogWrapper/DialogWrapper";
import { useDialog } from "../../hooks/useDialog";
import { EditProfileDialog } from "./EditProfileDialog";
import { useProvideAuth } from "../../auth/AuthProvider";
import { useUser } from "../../hooks/useUser";
import AlertToaster from "../../components/alert/Alert";
import { useState } from "react";

export interface ProfileValues {
  email: string;
  name: string;
  location: string;
  position: string;
  introduction: string;
  yearsWithCompany: string;
}

export const Profile = () => {
  const auth = useProvideAuth();
  const { user, loading, refresh } = useUser(auth.user.email);
  const [successAlert, setSuccessAlert] = useState(false);
  const mobile = useMediaQuery("(min-width:463px)");

  const YupSchema = () => {
    return Yup.object().shape({
      name: Yup.string().max(40, "40 characters max").required("Required"),
      location: Yup.string().max(40, "40 characters max").required("Required"),
      position: Yup.string().max(40, "40 characters max").required("Required"),
      introduction: Yup.string()
        .max(1500, "1500 characters max")
        .required("Required"),
      yearsWithCompany: Yup.string()
        .max(100, "40 characters max")
        .required("Required"),
    });
  };

  const onSuccess = () => {
    setSuccessAlert(true);
    refresh();
    setTimeout(function () {
      setSuccessAlert(false);
    }, 3000);
  };

  return (
    <PageWrapper loading={loading}>
      <>
        {successAlert ? (
          <AlertToaster message="Success" severity="success" />
        ) : null}
      </>
      {user && ( //user
        <Formik
          enableReinitialize
          initialValues={{
            name: user[0]?.name ?? "Name",
            location: user[0]?.location ?? "Location",
            position: user[0]?.position ?? "Position",
            introduction: user[0]?.introduction ?? "Introduction",
            yearsWithCompany: user[0]?.yearsWithCompany ?? "Years With Company",
            email: user[0]?.email,
          }}
          validationSchema={YupSchema}
          onSubmit={() => {}}
        >
          {({ errors, submitForm, handleChange, values, resetForm }) => (
            <Form>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                <img
                  src={ProfileAvatar}
                  alt="profile"
                  style={{
                    width: 210,
                    height: 210,
                    borderRadius: 10,
                    margin: mobile ? 0 : "auto",
                  }}
                />
                <div style={{ minWidth: 200 }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: "'Maven Pro', sans-serif",
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    color="text.secondary"
                    style={{
                      fontSize: 14,
                      fontFamily: "'Maven Pro', sans-serif",
                      marginBottom: 10,
                    }}
                  >
                    {values.name}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: "'Maven Pro', sans-serif",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    color="text.secondary"
                    style={{
                      fontSize: 14,
                      fontFamily: "'Maven Pro', sans-serif",
                      marginBottom: 10,
                    }}
                  >
                    {values.email}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: "'Maven Pro', sans-serif",
                    }}
                  >
                    Position
                  </Typography>
                  <Typography
                    color="text.secondary"
                    style={{
                      fontFamily: "'Maven Pro', sans-serif",
                      fontSize: 14,
                      marginBottom: 10,
                    }}
                  >
                    {values.position}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontFamily: "'Maven Pro', sans-serif",
                    }}
                  >
                    Location
                  </Typography>
                  <Typography
                    color="text.secondary"
                    style={{
                      fontFamily: "'Maven Pro', sans-serif",
                      fontSize: 14,
                    }}
                  >
                    {values.location}
                  </Typography>
                </div>

                {/* <EditProfileDialog
                        values={values}
                        onSave={submitForm}
                        handleChange={handleChange}
                        resetForm={resetForm}
                        errors={errors}
                      /> */}
              </div>
              <div style={{ position: "absolute", top: 55, right: 5 }}>
                <EditProfileDialog user={values} onSuccess={onSuccess} />
              </div>
              <div
                style={{
                  padding: 10,
                  border: "1px solid grey",
                  marginTop: 10,
                  height: 150,
                  overflowY: "auto",
                  borderRadius: 15,
                }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "'Maven Pro', sans-serif",
                  }}
                >
                  Introduction
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    fontSize: 14,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {values.introduction}
                </Typography>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </PageWrapper>
  );
};
