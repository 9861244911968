import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { Field, Form, Formik } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonIconButton } from "../../components/button/ButtonIconButton";
import {
  StyledInner,
  StyledWrapper,
} from "../emergencyResponse/EmergencyResponse.styles";
import { CATEGORIES } from "./GlobalDocuments.constants";
import { UploadDragger } from "../../components/uploadDragger/UploadDragger";
import { YupSchemaGlobalDocuments } from "./GlobalDocuments.utils";
import { UploadGlobalDocumentFn } from "../../backends/commands/UploadGlobalDocumentFn";

interface Contact {
  company: string;
  clientName: string;
  number?: string;
  email?: string;
  address?: string;
  tags?: Array<string>;
  id?: number;
  alwaysAvailable?: boolean;
}

interface GlobalDocumentUploadDialogProps {
  onSuccess: () => void;
}

export const GlobalDocumentUploadDialog = ({
  onSuccess,
}: GlobalDocumentUploadDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 619px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showFileName, setShowFileName] = useState();

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async (values: any) => {
    setErrorText("");
    setError(false);
    setShowSpinner(true);

    const response = await UploadGlobalDocumentFn(values);

    if (!response) {
      setErrorText("Error creating global document");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  return (
    <div>
      <ButtonIconButton
        iconOnly={iconOnly}
        label={"Add"}
        onClick={handleClickOpen}
        icon={<AddIcon />}
        tooltip={"Add Global Document"}
        color="success"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          enableReinitialize
          initialValues={{
            file: undefined,
            category: "None",
          }}
          validationSchema={YupSchemaGlobalDocuments}
          onSubmit={handleSave}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <StyledWrapper>
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={showSpinner}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <FadeIn>
                  <StyledInner>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      <b>Global Document</b>
                    </Typography>

                    <FormControl>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Category
                      </InputLabel>
                      <Select
                        disabled={showSpinner}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        fullWidth
                        label="Category"
                        value={values.category}
                        name="category"
                        onChange={(e) =>
                          setFieldValue("category", e.target.value)
                        }
                        MenuProps={{
                          PaperProps: { style: { borderColor: "black" } },
                        }}
                      >
                        {CATEGORIES.map((category) => (
                          <MenuItem key={category} value={category}>
                            <ListItemText primary={category} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {showFileName !== undefined ? (
                      <p
                        style={{
                          fontFamily: "'Maven Pro', sans-serif",
                          textAlign: "center",
                          margin: 10,
                        }}
                      >
                        {showFileName}
                      </p>
                    ) : (
                      <div
                        style={{
                          marginTop: 10,
                          textAlign: "center",
                          border: errors.file ? "1px solid red" : "none",
                          width: "auto",
                          padding: 10,
                        }}
                      >
                        <UploadDragger
                          returnDocument={(file: any) => {
                            setShowFileName(file.documentName);
                            setFieldValue("file", file);
                          }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "auto",
                      }}
                    >
                      <Button
                        disabled={showSpinner}
                        variant="contained"
                        style={{
                          marginTop: 30,
                          width: "70%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          fontFamily: "'Maven Pro', sans-serif",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onClick={submitForm}
                      >
                        {showSpinner ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <>Submit</>
                        )}
                      </Button>
                    </div>
                    {error ? (
                      <Typography style={{ color: "red", textAlign: "center" }}>
                        *{errorText}
                      </Typography>
                    ) : null}
                  </StyledInner>
                </FadeIn>
              </StyledWrapper>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

const StyledSelectField = styled(Select)`
  & .MuiOutlinedInput-input {
    border-color: black;
  }

  & .MuiInputBase-formControl {
    border-color: black;
  }

  &:before {
    border-color: black;
  }

  &:after {
    border-color: black;
  }
`;
