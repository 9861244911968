/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";

import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import {
  Badge,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";

import styled from "@emotion/styled";
import { ApprovedPassword } from "./ApprovedPassword";
import { supabase } from "../../supabase";
import AlertToaster from "../../components/alert/Alert";
import { useRequests } from "../../hooks/useRequests";
import { Delete, NotificationsActive } from "@mui/icons-material";
import { DeleteUserFn } from "../../backends/commands/DeleteUserFn";
import { ButtonIconButton } from "../../components/button/ButtonIconButton";

interface NewUserDialogProps {
  onSave: (resetUser: boolean) => void;
  user?: any;
}

export const DeleteUserDialog = ({ onSave, user }: NewUserDialogProps) => {
  const mobile = useMediaQuery("(min-width: 500px)");
  const [open, setOpen] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [showApproved, setShowApproved] = useState<boolean>(false);
  const [approvedUser, setApprovedUser] = useState<any>();
  const { requests } = useRequests();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (user.auth_id) {
      const response = await DeleteUserFn(user.auth_id);

      if (response) {
        handleClose();
        onSave(true);
      } else {
        //error
      }
    }

    //error
  };

  return (
    <div style={{ width: mobile ? "initial" : "100%" }}>
      {successAlert ? (
        <AlertToaster message="Success" severity="success" />
      ) : null}
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth={!mobile}
        disabled={!user}
        startIcon={<Delete />}
        color="error"
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          flexGrow: 1,
        }}
      >
        Delete User
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              position: "fixed",
              height: "auto",
              left: "50%",
              top: "50%",
              overflow: "hidden",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: 10,
              maxWidth: 1800,
              textAlign: "center",
            }}
          >
            <IconButton
              style={{ position: "absolute", top: 5, right: 5 }}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            {/* BOUNDARY INSIDE */}
            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontSize: 17,
                  textAlign: "center",
                  fontFamily: "'Maven Pro', sans-serif",
                  marginBottom: 15,
                  color: "#000",
                }}
              >
                Delete User
              </Typography>
              <p>
                Are you sure you want to delete <b>{user?.email}</b>?
              </p>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  color="error"
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    color: "#000",
                    flexGrow: 1,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleDelete}
                  color="success"
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    color: "#000",
                    flexGrow: 1,
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
