import * as Yup from "yup";
import {
  MonthyType,
  QuarterlyType,
  YearlyType,
} from "./components/ReportingStatuses";

export const YupSchemaSite = (type: "ADD" | "EDIT") => {
  return Yup.object().shape({
    name: Yup.string().required("Required"),
    siteNumber: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zip: Yup.number().required("Required"),
    oCompany: Yup.string().required("Required"),
    nics: Yup.number().required("Required"),
    sic: Yup.number().required("Required"),
    image:
      type === "ADD"
        ? Yup.mixed().required("Required")
        : Yup.mixed().notRequired(),
  });
};

export const getStatus = (
  reportType: any,
  field: any,
  site: any,
  onSuccess: any
) => {
  switch (reportType) {
    case "Tier II":
    case "Waste Management":
    case "Air":
    case "Fire/Backflow":
      return YearlyType(field, site, onSuccess);
    case "Stormwater":
      return (
        <>
          {QuarterlyType(field, site, onSuccess)}
          {MonthyType(field, site, onSuccess)}
        </>
      );
    case "Wastewater":
      return (
        <>
          {YearlyType(field, site, onSuccess)}
          {QuarterlyType(field, site, onSuccess)}
        </>
      );
    case "SPCC":
    case "Hazardous Waste":
      return MonthyType(field, site, onSuccess);
    default:
  }
};

export const getKey = (type: string) => {
  switch (type) {
    case "Tier II":
      return 0;
    case "Waste Management":
      return 1;
    case "Stormwater":
      return 2;
    case "Wastewater":
      return 3;
    case "Air":
      return 4;
    case "Fire/Backflow":
      return 5;
    case "SPCC":
      return 6;
    case "No Report Type":
      return 7;
    default:
      return 8; //Training -> Means dont use this in dataFields array
  }
};
