import _ from "lodash";
import { supabase } from "../../supabase";

export async function UploadRecordFn(data: any) {
  let url = await uploadPhoto(data);
  data.image = url;

  delete data.picture;

  // ARRAY VALUES BELOW FOR newDataFields
  // Tier II = 0
  // Waste Management = 1
  // Stormwater = 2
  // Wastewater = 3
  // Air = 4
  // Fire/Backflow = 5
  // SPCC = 6
  // No Report Type = 7

  let arrayIndex = 0;

  switch (data.name) {
    case "Tier II":
      arrayIndex = 0;
      break;
    case "Waste Management":
      arrayIndex = 1;
      break;
    case "Stormwater":
      arrayIndex = 2;
      break;
    case "Wastewater":
      arrayIndex = 3;
      break;
    case "Air":
      arrayIndex = 4;
      break;
    case "Fire/Backflow":
      arrayIndex = 5;
      break;
    case "SPCC":
      arrayIndex = 6;
      break;
    case "No Report Type":
      arrayIndex = 7;
      break;
    default:
  }

  const thisMonth = data.jsDate.split("/");

  let quarterIndex = 0;

  switch (thisMonth[2]) {
    // july, august, september
    case 7 || 8 || 9:
      quarterIndex = 0; //q1
      break;
    // october, november, december
    case 10 || 11 || 12:
      quarterIndex = 1; //q2
      break;
    // january, february, march
    case 1 || 2 || 3:
      quarterIndex = 2; //q3
      break;
    // may, june, july
    case 4 || 5 || 6:
      quarterIndex = 3; //q4
      break;
    default:
  }

  //   sites/document/dataFields[0]/status->Done, lastDone->newDate
  return new Promise(function (resolve, reject) {
    const siteNumber = data.site.siteNumber;
    const dataFields = data.site.dataFields;
    delete data.site;

    delete data.site;
    supabase
      .from("records")
      .insert(data)
      .then(async (res) => {
        if (res.error === null) {
          //Update done state

          let newDate = data.uploadDate;
          let newStatus = "Done";

          let newDataFields = _.cloneDeep(dataFields);

          //check if Stormwater or SPCC and
          //check if monthly or Quarterly
          if (
            data.reportT === "monthly" &&
            (data.name === "Stormwater" || data.name === "SPCC")
          ) {
            newDataFields[arrayIndex].months[thisMonth].status = newStatus;
          } else if (
            data.reportT === "quarterly" &&
            data.name === "Stormwater"
          ) {
            newDataFields[arrayIndex].quarters[quarterIndex].status = newStatus;
          } else {
            newDataFields[arrayIndex].status = newStatus;
            newDataFields[arrayIndex].lastDone = newDate;
          }

          let test = await supabase
            .from("sites")
            .update({
              dataFields: newDataFields,
            })
            .eq("siteNumber", siteNumber);

          if (test.error === null) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        } else {
          return resolve(false);
        }
      });
  });
}

export async function UploadHazardousWasteRecordFn(data: any) {
  let url = await uploadPhoto(data);
  data.image = url;
  delete data.picture;

  return new Promise(function (resolve, reject) {
    delete data.site;

    supabase
      .from("records")
      .insert(data)
      .then(async (res) => {
        if (res.error === null) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      });
  });
}

async function uploadPhoto(info: any) {
  let UUID = info.site.uuid + 1;

  await supabase
    .from("sites")
    .update({
      uuid: UUID,
    })
    .eq("siteNumber", info.siteNumber);

  await supabase.storage
    .from("records")
    .upload(`${info.siteNumber}/${UUID}-doc`, info.picture);

  return `${info.siteNumber}/${UUID}-doc`;
}
