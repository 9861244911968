import {
  Avatar,
  Badge,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { LibraryItems } from "./Library.constants";
import {
  StyledCardItem,
  StyledCardTitle,
  StyledCardWrapper,
  StyledCardFlex,
  StyledImage,
  StyledNav,
} from "./Library.styles";
import { LibraryItemDefinition } from "./Library.types";
import { Link } from "react-router-dom";
import { ConditionalWrapper } from "../../components/conditionalWrapper/ConditionalWrapper";
import { Pageview } from "@mui/icons-material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { supabase } from "../../supabase";
import { useRequests } from "../../hooks/useRequests";
import { useProvideAuth } from "../../auth/AuthProvider";
import { Item } from "semantic-ui-react";

export const Library = () => {
  const { requests } = useRequests();

  const auth = useProvideAuth();
  const showAdminPanel = () => {
    const admins = process.env.REACT_APP_ADMIN.split(", ");
    return admins.includes(auth?.user?.email);
  };

  const filterItems = () => {
    if (showAdminPanel()) {
      return LibraryItems;
    } else {
      return LibraryItems.filter((item) => item.label !== "Administrator");
    }
  };

  return (
    <PageWrapper
      style={{
        backgroundImage: "linear-gradient(to right, #2c3e50, rgb(78,73,46))",
      }}
    >
      <div style={{ padding: "0 20px" }}>
        <h2 style={{ fontFamily: "'Maven Pro', sans-serif", color: "white" }}>
          Library
        </h2>
        <StyledCardWrapper container spacing={2}>
          {filterItems().map((item: LibraryItemDefinition, index) => {
            const ItemComponent = item.component;
            return (
              <Grid
                key={`libraryItem-${index}`}
                item
                xl={2}
                lg={3}
                md={4}
                sm={6}
                xs={12}
              >
                <ConditionalWrapper
                  wrap={!item.disabled}
                  wrapperComponent={(children) => <>{children}</>}
                >
                  <StyledCardItem>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent style={{ display: "flex", padding: 8 }}>
                        <Avatar sx={{ bgcolor: item.svgColor }}>
                          <item.icon />
                        </Avatar>

                        <div style={{ marginLeft: 10, marginTop: 5 }}>
                          <StyledCardFlex>
                            <StyledCardTitle>{item.label}</StyledCardTitle>
                            {item.label === "Administrator" && (
                              <Badge
                                badgeContent={requests.length}
                                color="primary"
                                style={{ marginRight: 10 }}
                              >
                                <NotificationsActiveIcon color="action" />
                              </Badge>
                            )}
                          </StyledCardFlex>
                          <Typography
                            color="text.secondary"
                            style={{ fontSize: 13 }}
                          >
                            {item.intro}
                          </Typography>
                        </div>
                      </CardContent>
                      {!item.disabled && (
                        <CardActions
                          style={{
                            marginTop: "auto",
                            display: "flex",
                            justifyContent: "space-between",
                            zIndex: 10,
                            padding: 3,
                          }}
                        >
                          <Link
                            to={item.link}
                            style={{ color: "#000000", textDecoration: "none" }}
                          >
                            <StyledNav>Navigate</StyledNav>
                          </Link>
                          {item.component && <ItemComponent />}
                        </CardActions>
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ position: "absolute", bottom: 0 }}
                      >
                        <path
                          fill={item.svgColor}
                          fillOpacity="1"
                          d="M0,64L48,53.3C96,43,192,21,288,53.3C384,85,480,171,576,176C672,181,768,107,864,90.7C960,75,1056,117,1152,133.3C1248,149,1344,139,1392,133.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                        ></path>
                      </svg>
                    </div>
                    {item.disabled && (
                      <div
                        style={{
                          backgroundColor: "rgb(140,140,140,0.8)",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          display: "flex",
                        }}
                      >
                        {/* <Typography
                          style={{
                            color: "white",
                            transform: "rotate(-45deg)",
                            textAlign: "center",
                            margin: "auto",
                            fontWeight: "bold",
                            fontFamily: "'Maven Pro', sans-serif",
                            fontSize: 22,
                          }}
                        >
                          Coming Soon...
                        </Typography> */}
                      </div>
                    )}
                  </StyledCardItem>
                </ConditionalWrapper>
              </Grid>
            );
          })}
        </StyledCardWrapper>
      </div>
    </PageWrapper>
  );
};
