export const STATES = [
  "None",
  "Alaska",
  "Arizona",
  "California",
  "Hawaii",
  "Idaho",
  "Montana",
  "Nevada",
  "Oregon",
  "Washington",
];

export const OPERATING_COMPANIES = [
  "None",
  "Material Handling",
  "Machinery Ag & T",
  "Machinery C & F",
  "Kenworth",
  "Engineered Products",
  "Ditch Witch West",
];

export const REPORTING_TYPES = [
  "Tier II",
  "Waste Management",
  "Stormwater",
  "Wastewater",
  "Air",
  "Fire/Backflow",
  "SPCC",
];
