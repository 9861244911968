import styled from "@emotion/styled/macro";
import { TextField } from "@mui/material";

export const StyledHeader = styled.h3`
  font-family: "Maven Pro", sans-serif;
  font-size: 24px;
`;

export const StyledField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Maven Pro", sans-serif;

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgb(0, 0, 0, 1);
    font-family: "Maven Pro", sans-serif;
    font-size: 14px;
  }
`;
