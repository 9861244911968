import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { InputAdornment, useMediaQuery } from "@mui/material";

import AlertToaster from "../../components/alert/Alert";
import { useRequests } from "../../hooks/useRequests";
import { DeleteUserFn } from "../../backends/commands/DeleteUserFn";
import { Key, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { StyledTextField } from "./NewUserDialog";
import { UpdatePasswordFn } from "../../backends/commands/UpdatePasswordFn";

interface UpdatePasswordDialogProps {
  onSave: () => void;
  user?: any;
}

export const UpdatePasswordDialog = ({
  onSave,
  user,
}: UpdatePasswordDialogProps) => {
  const mobile = useMediaQuery("(min-width: 500px)");
  const [open, setOpen] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickOpen = () => {
    setShowPassword(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (values: {
    password: string;
    confirmPassword: string;
  }) => {
    if (user.auth_id) {
      const response = await UpdatePasswordFn(user.auth_id, values.password);

      if (response) {
        handleClose();
        onSave();
      } else {
        //error
      }
    }

    //error
  };

  const YupSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .max(100, "Maximum of 100 characters")
        .trim()
        .min(8, "Minimum of 8 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .max(100, "Maximum of 100 characters")
        .trim()
        .min(8, "Minimum of 8 characters")
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Required"),
    });
  };

  return (
    <div style={{ width: mobile ? "initial" : "100%" }}>
      {successAlert ? (
        <AlertToaster message="Success" severity="success" />
      ) : null}

      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth={!mobile}
        disabled={!user}
        startIcon={<Key />}
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          flexGrow: 1,
        }}
      >
        Update Password
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          enableReinitialize
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={YupSchema}
          onSubmit={handleUpdate}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            resetForm,
            isSubmitting,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  position: "fixed",
                  width: "50%",
                  height: "auto",
                  left: "50%",
                  top: "50%",
                  overflow: "hidden",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  maxWidth: 1800,
                  minWidth: 300,
                }}
              >
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={isSubmitting}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <div
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 20,
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 17,
                      textAlign: "center",
                      fontFamily: "'Maven Pro', sans-serif",
                      marginBottom: 15,
                      color: "#000",
                    }}
                  >
                    Update Password
                  </Typography>

                  <StyledTextField
                    name="password"
                    id="outlined-basic"
                    error={Boolean(errors.password)}
                    helperText={errors.password ?? ""}
                    required
                    label="Password"
                    variant="outlined"
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? "text" : "password"}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />

                  <StyledTextField
                    name="confirmPassword"
                    id="outlined-basic"
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword ?? ""}
                    type={showPassword ? "text" : "password"}
                    required
                    label="Confirm Password"
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={isSubmitting}
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      gap: 10,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="error"
                      disabled={isSubmitting}
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        color: "#000",
                        flexGrow: 1,
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={submitForm}
                      disabled={isSubmitting}
                      color="success"
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        color: "#000",
                        flexGrow: 1,
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
