import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { Form, Formik } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import { OPERATING_COMPANIES, STATES } from "../../../SharedConstants";
import EditIcon from "@mui/icons-material/Edit";
import { StyledWrapper } from "../../emergencyResponse/EmergencyResponse.styles";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import ImageUploader from "react-images-upload";
import AdapterMoment from "@date-io/moment";
import { EditSiteDialogProps, Site } from "../Facilities.types";
import {
  DATAFIELD_INDEX,
  DefaultDataFields,
  DefaultHazardousWaste,
} from "../Facilities.constants";
import { YupSchemaSite } from "../Facilities.utils";
import { NewSiteFn } from "../../../backends/commands/NewSiteFn";
import { EditSiteFn } from "../../../backends/commands/EditSiteFn";
import moment from "moment";
import _ from "lodash";

export const AddEditSiteDialog = ({
  type,
  site,
  onSuccess,
}: EditSiteDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(false);
  const Mobile = useMediaQuery("(min-width: 717px)");

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const updateDates = (values: Site): Site => {
    values.dataFields[DATAFIELD_INDEX.TIER_II].jsDate = moment(
      values.dataFields[DATAFIELD_INDEX.TIER_II].jsDate
    ).format("MM/DD/YYYY");

    values.dataFields[DATAFIELD_INDEX.WASTE_MANAGEMENT].jsDate = moment(
      values.dataFields[DATAFIELD_INDEX.WASTE_MANAGEMENT].jsDate
    ).format("MM/DD/YYYY");

    values.dataFields[DATAFIELD_INDEX.WASTEWATER].jsDate = moment(
      values.dataFields[DATAFIELD_INDEX.WASTEWATER].jsDate
    ).format("MM/DD/YYYY");

    values.dataFields[DATAFIELD_INDEX.AIR].jsDate = moment(
      values.dataFields[DATAFIELD_INDEX.AIR].jsDate
    ).format("MM/DD/YYYY");

    values.dataFields[DATAFIELD_INDEX.FIRE_BACKFLOW].jsDate = moment(
      values.dataFields[DATAFIELD_INDEX.FIRE_BACKFLOW].jsDate
    ).format("MM/DD/YYYY");

    values.dataFields[DATAFIELD_INDEX.SPCC].jsDate = moment(
      values.dataFields[DATAFIELD_INDEX.SPCC].jsDate
    ).format("MM/DD/YYYY");

    return values;
  };

  const handleSave = async (values: Site) => {
    const valuesCopy = updateDates(_.cloneDeep(values));

    setError(false);
    setShowSpinner(true);

    let response = undefined;
    if (type === "ADD") {
      response = await NewSiteFn(valuesCopy);
    } else {
      response = await EditSiteFn(
        valuesCopy,
        site?.contactFormLink,
        site?.wasteFormLink
      );
    }

    if (!response) {
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  return (
    <div>
      {type === "EDIT" ? (
        <Tooltip title="Edit Site">
          <Fab style={{ width: 40, height: 40 }} onClick={handleClickOpen}>
            <EditIcon style={{ fontSize: 17 }} />
          </Fab>
        </Tooltip>
      ) : (
        <Tooltip title="Add New Site">
          <Fab
            style={{
              height: 40,
              width: 40,
              fontFamily: "'Maven Pro', sans-serif",
              textTransform: "none",
              position: "fixed",
              bottom: 20,
              left: 20,
              backgroundColor: "rgb(242, 210, 5)",
              zIndex: 1000,
            }}
            onClick={handleClickOpen}
          >
            <AddIcon
              style={{
                fontSize: 16,
              }}
            />
          </Fab>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik<Site>
          enableReinitialize
          initialValues={{
            id: site?.id ?? undefined,
            siteNumber: site?.siteNumber ?? "",
            name: site?.name ?? "",
            address: site?.address ?? "",
            city: site?.city ?? "",
            state: site?.state ?? "",
            zip: site?.zip ?? undefined,
            image: undefined,
            oCompany: site?.oCompany ?? "",
            contactFormLink: undefined,
            nics: site?.nics ?? undefined,
            sic: site?.sic ?? undefined,
            wasteFormLink: undefined,
            uuid: site?.uuid ?? 0,
            dataFields: site?.dataFields ?? DefaultDataFields,
            trainingNote: site?.trainingNote ?? "",
            trainingDocuments: site?.trainingDocuments ?? undefined,
            hazardousWaste: site?.hazardousWaste ?? DefaultHazardousWaste,
          }}
          onSubmit={handleSave}
          validationSchema={() => YupSchemaSite(type)}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <StyledWrapper>
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={showSpinner}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <FadeIn>
                  <div
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 20,
                      marginBottom: 20,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 15,
                      }}
                    >
                      <b>{type === "ADD" ? "Add Site" : "Edit Site"}</b>
                    </Typography>
                    <div style={{ marginTop: 10 }}>
                      <StyledTextField
                        id="outlined-basic"
                        label="Site Name"
                        variant="outlined"
                        autoComplete="off"
                        style={{
                          marginTop: 4,
                          width: "100%",
                          fontFamily: "'Maven Pro', sans-serif",
                        }}
                        name="name"
                        value={values.name}
                        error={!!errors.name && touched.name}
                        onChange={handleChange}
                      />
                    </div>
                    {values.state === "California" && (
                      <Typography
                        style={{
                          marginTop: 4,
                          fontFamily: "'Maven Pro', sans-serif",
                          fontSize: 13,
                          color: "red",
                        }}
                      >
                        *Please include '(NoCal)' or '(SoCal)' in the Site Name.
                        Example: PM Chico (NoCal)
                      </Typography>
                    )}
                    {type === "ADD" && (
                      <div style={{ marginTop: 10 }}>
                        <StyledTextField
                          id="outlined-basic"
                          label="Store Number"
                          variant="outlined"
                          autoComplete="off"
                          style={{
                            marginTop: 4,
                            width: "100%",
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                          name="siteNumber"
                          value={values.siteNumber}
                          error={!!errors.siteNumber && touched.siteNumber}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                    <div style={{ marginTop: 10 }}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: 4 }}
                      >
                        <InputLabel
                          id="outlined-age-native-simple"
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                            fontSize: 15,
                          }}
                        >
                          Operating Company
                        </InputLabel>
                        <Select
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          labelId="outlined-age-native-simple"
                          label="Operating Company"
                          id="outlined-age-native-simple"
                          value={values.oCompany}
                          name="oCompany"
                          error={!!errors.oCompany && touched.oCompany}
                          onChange={handleChange}
                        >
                          {OPERATING_COMPANIES.map((company) => (
                            <MenuItem
                              value={company}
                              style={{
                                fontFamily: "'Maven Pro', sans-serif",
                                fontSize: 15,
                              }}
                            >
                              {company}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <StyledTextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        autoComplete="off"
                        style={{
                          marginTop: 4,
                          width: "100%",
                          fontFamily: "'Maven Pro', sans-serif",
                        }}
                        value={values.address}
                        name="address"
                        error={!!errors.address && touched.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        display: Mobile ? "flex " : "inline",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 10,
                          width: Mobile ? "32%" : "100%",
                        }}
                      >
                        <StyledTextField
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          autoComplete="off"
                          style={{
                            marginTop: 4,
                            width: "100%",
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                          value={values.city}
                          name="city"
                          error={!!errors.city && touched.city}
                          onChange={handleChange}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          width: Mobile ? "32%" : "100%",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%", marginTop: 4 }}
                        >
                          <InputLabel
                            id="outlined-age-native-simple"
                            style={{
                              fontFamily: "'Maven Pro', sans-serif",
                              fontSize: 15,
                            }}
                          >
                            State
                          </InputLabel>
                          <Select
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            labelId="outlined-age-native-simple"
                            label="State"
                            id="outlined-age-native-simple"
                            value={values.state}
                            name="state"
                            error={!!errors.state && touched.state}
                            onChange={handleChange}
                          >
                            {STATES.map((state) => (
                              <MenuItem
                                key={state}
                                value={state}
                                style={{
                                  fontFamily: "'Maven Pro', sans-serif",
                                  fontSize: 15,
                                }}
                              >
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          width: Mobile ? "32%" : "100%",
                        }}
                      >
                        <StyledTextField
                          id="outlined-basic"
                          label="Zipcode"
                          variant="outlined"
                          autoComplete="off"
                          style={{
                            marginTop: 4,
                            width: "100%",
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                          error={!!errors.zip && touched.zip}
                          value={values.zip}
                          name="zip"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <StyledTextField
                      label="SIC"
                      id="outlined-size-normal"
                      variant="outlined"
                      value={values.sic}
                      name="sic"
                      autoComplete="off"
                      style={{
                        marginTop: 15,
                        width: "100%",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={!!errors.sic && touched.sic}
                      onChange={handleChange}
                    />
                    <StyledTextField
                      label="NAICS"
                      id="outlined-size-normal"
                      variant="outlined"
                      value={values.nics}
                      name="nics"
                      autoComplete="off"
                      style={{
                        marginTop: 15,
                        marginBottom: 20,
                        width: "100%",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={!!errors.nics && touched.nics}
                      onChange={handleChange}
                    />

                    <div style={{ marginBottom: 30 }}>
                      {values.dataFields
                        .filter((e: any) => e.name !== "No Report Type")
                        .map((e: any, idx) => (
                          <div
                            key={idx}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              style={{ width: 20 }}
                              checked={values.dataFields[idx].applicable}
                              onChange={() =>
                                setFieldValue(
                                  `dataFields[${idx}].applicable`,
                                  !values.dataFields[idx].applicable
                                )
                              }
                            />

                            <Typography
                              style={{
                                marginLeft: 5,
                                fontFamily: "'Maven Pro', sans-serif",
                                fontSize: 15,
                              }}
                            >
                              Applicable: {e.name}
                            </Typography>
                          </div>
                        ))}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        InputProps={{ sx: { marginBottom: 2 } }}
                        label="Tier II Reporting (Yearly)"
                        inputFormat="MM/DD/YYYY"
                        value={
                          values.dataFields[DATAFIELD_INDEX.TIER_II].jsDate
                        }
                        onChange={(newDate) =>
                          setFieldValue(
                            `dataFields[${DATAFIELD_INDEX.TIER_II}].jsDate`,
                            newDate
                          )
                        }
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        InputProps={{ sx: { marginBottom: 2 } }}
                        label="Waste Management Reporting (Yearly)"
                        inputFormat="MM/DD/YYYY"
                        value={
                          values.dataFields[DATAFIELD_INDEX.WASTE_MANAGEMENT]
                            .jsDate
                        }
                        onChange={(newDate) =>
                          setFieldValue(
                            `dataFields[${DATAFIELD_INDEX.WASTE_MANAGEMENT}].jsDate`,
                            newDate
                          )
                        }
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        InputProps={{ sx: { marginBottom: 2 } }}
                        label="Wastewater Reporting (Yearly)"
                        inputFormat="MM/DD/YYYY"
                        value={
                          values.dataFields[DATAFIELD_INDEX.WASTEWATER].jsDate
                        }
                        onChange={(newDate) =>
                          setFieldValue(
                            `dataFields[${DATAFIELD_INDEX.WASTEWATER}].jsDate`,
                            newDate
                          )
                        }
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        InputProps={{ sx: { marginBottom: 2 } }}
                        label="Air Reporting (Yearly)"
                        inputFormat="MM/DD/YYYY"
                        value={values.dataFields[DATAFIELD_INDEX.AIR].jsDate}
                        onChange={(newDate) =>
                          setFieldValue(
                            `dataFields[${DATAFIELD_INDEX.AIR}].jsDate`,
                            newDate
                          )
                        }
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        InputProps={{ sx: { marginBottom: 2 } }}
                        label="Fire/Backflow Reporting (Yearly)"
                        inputFormat="MM/DD/YYYY"
                        value={
                          values.dataFields[DATAFIELD_INDEX.FIRE_BACKFLOW]
                            .jsDate
                        }
                        onChange={(newDate) =>
                          setFieldValue(
                            `dataFields[${DATAFIELD_INDEX.FIRE_BACKFLOW}].jsDate`,
                            newDate
                          )
                        }
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        InputProps={{ sx: { marginBottom: 2 } }}
                        label="SPCC Reporting (Yearly)"
                        inputFormat="MM/DD/YYYY"
                        value={values.dataFields[DATAFIELD_INDEX.SPCC].jsDate}
                        onChange={(newDate) =>
                          setFieldValue(
                            `dataFields[${DATAFIELD_INDEX.SPCC}].jsDate`,
                            newDate
                          )
                        }
                        renderInput={(params) => (
                          <StyledTextField {...params} />
                        )}
                      />
                    </LocalizationProvider>

                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <Typography
                        style={{
                          marginTop: 5,
                          fontFamily: "'Maven Pro', sans-serif",
                          fontSize: 15,
                        }}
                      >
                        Change Emergency Contact Form
                      </Typography>

                      <form action="/action_page.php">
                        <input
                          type="file"
                          id="contactFormLink"
                          accept="application/pdf"
                          name="contactFormLink"
                          onChange={(event) =>
                            event.target.files?.length &&
                            setFieldValue(
                              "contactFormLink",
                              event.target.files[0]
                            )
                          }
                        />
                      </form>
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      <Typography
                        style={{
                          marginTop: 20,
                          fontFamily: "'Maven Pro', sans-serif",
                          fontSize: 15,
                        }}
                      >
                        Change Site Map
                      </Typography>

                      <form action="/action_page.php">
                        <input
                          type="file"
                          id="image"
                          name="wasteFormLink"
                          onChange={(event) =>
                            event.target.files?.length &&
                            setFieldValue(
                              "wasteFormLink",
                              event.target.files[0]
                            )
                          }
                        />
                      </form>
                    </div>

                    <Typography
                      style={{
                        marginTop: 20,
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 15,
                        color: errors.image ? "red" : "black",
                      }}
                    >
                      Change Site Image
                    </Typography>

                    <ImageUploader
                      style={{
                        border: errors.image ? "1px solid red" : "none",
                        marginBottom: 5,
                        marginTop: 5,
                      }}
                      withIcon={true}
                      withPreview={true}
                      singleImage={true}
                      buttonText="Choose Site Image"
                      onChange={(photo) => setFieldValue("image", photo)}
                      name="image"
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={5242880}
                      withLabel={true}
                      label="Max file size: 5mb, accepted: jpg | jpeg | png"
                    />

                    {showSpinner ? (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "auto",
                        }}
                      >
                        <CircularProgress
                          color="inherit"
                          style={{
                            color: "white",
                            position: "absolute",
                            zIndex: 5,
                            left: 0,
                            right: 0,
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: 2,
                          }}
                          size={30}
                        />
                        <Button
                          variant="contained"
                          disabled
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 100,
                            backgroundColor: "#545454",
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "auto",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 100,
                            backgroundColor: "black",
                            fontFamily: "'Maven Pro', sans-serif",
                          }}
                          onClick={submitForm}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                    {error ? (
                      <Typography
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontFamily: "'Maven Pro', sans-serif",
                        }}
                      >
                        {type === "ADD"
                          ? "*Error adding site"
                          : "*Error updating site"}
                      </Typography>
                    ) : null}
                  </div>
                </FadeIn>
              </StyledWrapper>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

const StyledSelectField = styled(Select)`
  & .MuiOutlinedInput-input {
    border-color: black;
  }

  & .MuiInputBase-formControl {
    border-color: black;
  }

  &:before {
    border-color: black;
  }

  &:after {
    border-color: black;
  }
`;
