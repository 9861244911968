import { supabase } from "../../supabase";

export const UploadGlobalDocumentFn = async (values: any) => {
  return new Promise(async function (resolve, reject) {
    const existing = await supabase
      .from("globalDocuments")
      .select()
      .eq("path", `${values.category}/${values.file.documentName}`);

    //file already exists
    if (!!existing.body?.length) {
      return resolve(false);
    }

    let urlUpload = await uploadGlobalDocument(values);

    if (urlUpload.error) {
      return resolve(false);
    }

    values.path = `${values.category}/${values.file.documentName}`;
    values.fileName = values.file.documentName;
    delete values.file;
    const res = await supabase.from("globalDocuments").insert(values);

    if (res.error !== null) {
      return resolve(false);
    }

    return resolve(true);
  });
};

async function uploadGlobalDocument(values: any) {
  return await supabase.storage
    .from("global-documents")
    .upload(`${values.category}/${values.file.documentName}`, values.file.path);
}
