import styled from "@emotion/styled";
import { InputBase, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    height: "36px",
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

export const StyledWrapper = styled.div`
  position: fixed;
  width: 80%;
  height: auto;
  max-height: 90%;
  left: 50%;
  top: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const StyledInner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  gap: 10px;
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const StyledHeader = styled.h3`
  font-family: "Maven Pro", sans-serif;
  font-size: 20px;
  margin-right: 15px;
  margin-bottom: 0px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const StyledInput = styled(InputBase)`
  color: #000;
  flex-grow: 1;
  height: 36.6px;
  font-family: "Maven Pro", sans-serif;
  border: 0.5px solid #cccccc;
  padding: 5px;
  min-width: 175px;
  border-radius: 5px;
`;

export const StyledGridWrapper = styled.div`
  min-height: 300px;
  width: 100%;
  margin-top: 5px;
  height: calc(100vh - 165px);
`;
