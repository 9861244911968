import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { globalDocuments } from "../store/Store";
import { useEffect } from "react";
import { GetGlobalDocumentsFn } from "../backends/queries/GetGlobalDocumentsFn";

export const useGlobalDocuments = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery(
    "globalDocuments",
    GetGlobalDocumentsFn
  );
  const [globalDocumentsList, setGlobalDocumentsList] =
    useRecoilState(globalDocuments);

  useEffect(() => {
    isSuccess && data.data && setGlobalDocumentsList(data.data);
  }, [data?.data, isSuccess, setGlobalDocumentsList]);

  return {
    globalDocuments: globalDocumentsList,
    refreshGlobalDocuments: refetch,
    loading: isLoading,
  };
};
