import { supabase } from "../../supabase";

export const GetUsersFn = async () => {
  const records = await (await supabase.from("records").select()).body;
  const users = await (await supabase.from("users").select()).body;

  if (users) {
    users.forEach((user) => {
      if (records) {
        const recordsToPush = records.filter(
          (record) => record.user === user.email
        );

        user.documents = [...recordsToPush];
      } else {
        user.documents = [];
      }
    });
  }
  return users ?? [];
};
