import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Add, Delete, Settings } from "@mui/icons-material";
import styled from "@emotion/styled";
import { OPERATING_COMPANIES, REPORTING_TYPES } from "../../../SharedConstants";
import { UploadSdsSheetFn } from "../../../backends/commands/UploadSdsSheetFn";
import { UploadDragger } from "../../../components/uploadDragger/UploadDragger";
import { UploadContactPdfFn } from "../../../backends/commands/UploadContactPdfFn";
import { ButtonIconButton } from "../../../components/button/ButtonIconButton";
import {
  StyledInner,
  StyledWrapper,
} from "../../emergencyResponse/EmergencyResponse.styles";
import { EditLinkAndNotesFn } from "../../../backends/commands/EditLinkAndNotesFn";

interface EditLinkAndNotesDialogProps {
  onSuccess: () => void;
  type: string;
  activeSite: any;
  showExternalLink: boolean;
  dbLink?: string;
  note: string;
}

export const EditLinkAndNotesDialog = ({
  onSuccess,
  type,
  activeSite,
  showExternalLink,
  note,
  dbLink,
}: EditLinkAndNotesDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 619px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [fileError, setFileError] = useState(false);
  const [files, setFiles] = useState<Array<any>>([]);

  const [showFileName, setShowFileName] = useState();

  const handleReset = () => {
    setFileError(false);
    setFiles([]);
    setErrorText("");
    setError(false);
    setShowFileName(undefined);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
    handleReset();
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async (values: any) => {
    setErrorText("");
    setError(false);
    setShowSpinner(true);

    const updateType = showExternalLink ? "BOTH" : "NOTE";
    values.reportType = type;
    values.site = activeSite;

    const response = await EditLinkAndNotesFn(values, updateType); //TODO

    if (!response) {
      setErrorText("Error updating note");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleFileSelected = (e: any) => {
    setShowFileName(e.documentName);
    setFiles([e]);
  };

  return (
    <div>
      <Tooltip title="Edit Link & Notes">
        <IconButton onClick={handleClickOpen} size="large">
          <Settings style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          enableReinitialize
          initialValues={{
            dbLink: dbLink,
            note: note,
          }}
          onSubmit={handleSave}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <StyledWrapper>
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={showSpinner}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <FadeIn>
                  <StyledInner>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      {showExternalLink ? (
                        <b>Edit Link & Notes</b>
                      ) : (
                        <b>Edit Notes</b>
                      )}
                    </Typography>

                    {showExternalLink && (
                      <TextField
                        label="External Database URL"
                        id="outlined-size-normal"
                        value={values.dbLink}
                        name="dbLink"
                        onChange={handleChange}
                        variant="outlined"
                        style={{ marginBottom: 10 }}
                      />
                    )}
                    <TextField
                      id="outlined-multiline-static"
                      label="Notes"
                      multiline
                      rows={4}
                      value={values.note}
                      name="note"
                      onChange={handleChange}
                      variant="outlined"
                      style={{
                        marginTop: 10,
                        marginBottom: 30,
                        width: "100%",
                      }}
                    />

                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "auto",
                      }}
                    >
                      <Button
                        disabled={showSpinner}
                        variant="contained"
                        style={{
                          marginTop: 30,
                          width: "70%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          fontFamily: "'Maven Pro', sans-serif",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onClick={submitForm}
                      >
                        {showSpinner ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <>Submit</>
                        )}
                      </Button>
                    </div>
                    {error ? (
                      <Typography style={{ color: "red", textAlign: "center" }}>
                        *{errorText}
                      </Typography>
                    ) : null}
                  </StyledInner>
                </FadeIn>
              </StyledWrapper>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
