import { Alert } from "@mui/material";
import { StyledAlertTitle, StyledAlertWrapper } from "./Alert.styled";
import { AlertProps } from "./Alert.types";

export default function AlertToaster({ message, severity }: AlertProps) {
  return (
    <StyledAlertWrapper>
      <Alert severity={severity}>
        <StyledAlertTitle>{message}</StyledAlertTitle>
      </Alert>
    </StyledAlertWrapper>
  );
}
