import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { Field, Form, Formik } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";
import { REPORTING_TYPES } from "../../../SharedConstants";
import { StyledInner, StyledWrapper } from "../EmergencyResponse.styles";
import { YupSchemaContacts } from "../EmergencyResponse.utils";
import { UploadContactFn } from "../../../backends/commands/UploadContactFn";
import { ButtonIconButton } from "../../../components/button/ButtonIconButton";
import EditIcon from "@mui/icons-material/Edit";

interface Contact {
  company: string;
  clientName: string;
  number?: string;
  email?: string;
  address?: string;
  tags?: Array<string>;
  id?: number;
  alwaysAvailable?: boolean;
  website?: string;
}

interface UploadContactDialogProps {
  contact?: any;
  onSuccess: () => void;
  state: string;
  isEdit?: boolean;
  disabled?: boolean;
}

export const UploadContactDialog = ({
  contact,
  onSuccess,
  state,
  isEdit,
  disabled,
}: UploadContactDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 619px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async (values: any) => {
    values.state = state;
    setErrorText("");
    setError(false);
    setShowSpinner(true);

    const response = await UploadContactFn(values);

    if (!response) {
      setErrorText("Error creating contact");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  return (
    <div>
      {!isEdit ? (
        <ButtonIconButton
          iconOnly={iconOnly}
          label={"Add"}
          onClick={handleClickOpen}
          icon={<AddIcon />}
          tooltip={"Add Contact"}
          color="success"
        />
      ) : (
        <ButtonIconButton
          iconOnly={iconOnly}
          label={"Edit"}
          onClick={handleClickOpen}
          icon={<EditIcon />}
          tooltip={"Edit Contact"}
          color="primary"
          disabled={disabled}
          activeItem={contact}
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik<Contact>
          enableReinitialize
          initialValues={{
            company: contact?.company ?? "",
            clientName: contact?.clientName ?? "",
            number: contact?.number ?? "",
            email: contact?.email ?? "",
            address: contact?.address ?? "",
            tags: contact?.tags ?? [],
            id: contact?.id,
            alwaysAvailable: contact?.alwaysAvailable ?? false,
            website: contact?.website,
          }}
          validationSchema={YupSchemaContacts}
          onSubmit={handleSave}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <StyledWrapper>
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={showSpinner}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <FadeIn>
                  <StyledInner>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      <b>Emergency Response Contact</b>
                    </Typography>
                    <StyledTextField
                      disabled={showSpinner}
                      name="company"
                      value={values.company}
                      label="Company Name"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.company) && touched.company}
                      helperText={errors.company ?? ""}
                    />

                    <StyledTextField
                      disabled={showSpinner}
                      name="clientName"
                      value={values.clientName}
                      label="Client Name"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.clientName) && touched.clientName}
                      helperText={errors.clientName ?? ""}
                    />
                    <StyledTextField
                      disabled={showSpinner}
                      name="website"
                      value={values.website}
                      label="Company Website"
                      id="outlined-size-normal"
                      placeholder="https://www.google.com/"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <StyledTextField
                        disabled={showSpinner}
                        name="number"
                        value={values.number}
                        label="Number"
                        id="outlined-size-normal"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                        style={{
                          fontFamily: "'Maven Pro', sans-serif",
                        }}
                        error={Boolean(errors.number) && touched.number}
                        helperText={errors.number ?? ""}
                      />
                      <div
                        style={{
                          minWidth: 150,
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 10,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "'Maven Pro', sans-serif",
                            marginRight: 5,
                          }}
                        >
                          Available 24/7
                        </Typography>
                        <Field
                          as={Checkbox}
                          type="checkbox"
                          name="alwaysAvailable"
                          control={
                            <Checkbox
                              style={{
                                color: "black",
                                backgroundColor: "black",
                              }}
                            />
                          }
                        />
                      </div>
                    </div>

                    <StyledTextField
                      disabled={showSpinner}
                      name="email"
                      value={values.email}
                      label="Email"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      type="email"
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.email) && touched.email}
                      helperText={errors.email ?? ""}
                    />

                    <StyledTextField
                      disabled={showSpinner}
                      name="address"
                      value={values.address}
                      label="Address"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      type="address"
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.address) && touched.address}
                      helperText={errors.address ?? ""}
                    />

                    <FormControl>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Tag
                      </InputLabel>
                      <Select
                        disabled={showSpinner}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        fullWidth
                        value={values.tags}
                        name="tags"
                        onChange={(e) => setFieldValue("tags", e.target.value)}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={() => values?.tags?.join(", ")}
                        MenuProps={{
                          PaperProps: { style: { borderColor: "black" } },
                        }}
                      >
                        {REPORTING_TYPES.map((report) => (
                          <MenuItem key={report} value={report}>
                            <ListItemText primary={report} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "auto",
                      }}
                    >
                      <Button
                        disabled={showSpinner}
                        variant="contained"
                        style={{
                          marginTop: 30,
                          width: "70%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          fontFamily: "'Maven Pro', sans-serif",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onClick={submitForm}
                      >
                        {showSpinner ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <>Submit</>
                        )}
                      </Button>
                    </div>
                    {error ? (
                      <Typography style={{ color: "red", textAlign: "center" }}>
                        *{errorText}
                      </Typography>
                    ) : null}
                  </StyledInner>
                </FadeIn>
              </StyledWrapper>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

const StyledSelectField = styled(Select)`
  & .MuiOutlinedInput-input {
    border-color: black;
  }

  & .MuiInputBase-formControl {
    border-color: black;
  }

  &:before {
    border-color: black;
  }

  &:after {
    border-color: black;
  }
`;
