import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { DialogWrapper } from "../../components/dialogWrapper/DialogWrapper";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { StyledInner, StyledWrapper } from "../sdsLibrary/SdsLibrary.styles";
import CloseIcon from "@mui/icons-material/Close";
import FadeIn from "react-fade-in";
import { ProfileValues } from "./Profile";
import { StyledTextField } from "../login/Login.styled";
import { UpdateProfileFn } from "../../backends/commands/UpdateProfileFn";

export const YupSchema = () => {
  return Yup.object().shape({
    documents: Yup.array()
      .of(
        Yup.object().shape({
          documentName: Yup.string(),
          chemicalName: Yup.string()
            .min(1)
            .max(255)
            .trim()
            .required("Required"),
          operatingCompany: Yup.string().required("Required"),
        })
      )
      .required("Required"),
  });
};

export interface EditableProfileValues {
  name: string;
  location: string;
  position: string;
  introduction: string;
}

export interface EditProfileDialogProps {
  user: ProfileValues;
  onSuccess: () => void;
}

export const EditProfileDialog = ({
  user,
  onSuccess,
}: EditProfileDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (values: EditableProfileValues) => {
    setErrorText("");
    setError(false);
    setShowSpinner(true);

    const response = await UpdateProfileFn(values, user.email);

    if (!response) {
      setErrorText("Error updating profile");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik<EditableProfileValues>
          enableReinitialize
          initialValues={{
            name: user.name,
            location: user.location,
            position: user.position,
            introduction: user.introduction,
          }}
          onSubmit={handleSave}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <StyledWrapper>
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                  disabled={showSpinner}
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <FadeIn>
                  <StyledInner>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                    >
                      <b>Edit Profile</b>
                    </Typography>
                    <StyledTextField
                      disabled={showSpinner}
                      name="name"
                      value={values.name}
                      label="Name"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.name) && touched.name}
                      helperText={errors.name ?? ""}
                    />

                    <StyledTextField
                      disabled={showSpinner}
                      name="position"
                      value={values.position}
                      label="Position"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.position) && touched.position}
                      helperText={errors.position ?? ""}
                    />

                    <StyledTextField
                      disabled={showSpinner}
                      name="location"
                      value={values.location}
                      label="Location"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={Boolean(errors.location) && touched.location}
                      helperText={errors.location ?? ""}
                    />

                    <StyledTextField
                      disabled={showSpinner}
                      name="introduction"
                      value={values.introduction}
                      label="Introduction"
                      id="outlined-size-normal"
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      rows={4}
                      multiline
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                      }}
                      error={
                        Boolean(errors.introduction) && touched.introduction
                      }
                      helperText={errors.introduction ?? ""}
                    />

                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "auto",
                      }}
                    >
                      <Button
                        disabled={showSpinner}
                        variant="contained"
                        style={{
                          marginTop: 30,
                          width: "70%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          fontFamily: "'Maven Pro', sans-serif",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onClick={submitForm}
                      >
                        {showSpinner ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                          />
                        ) : (
                          <>Submit</>
                        )}
                      </Button>
                    </div>
                    {error ? (
                      <Typography style={{ color: "red", textAlign: "center" }}>
                        *{errorText}
                      </Typography>
                    ) : null}
                  </StyledInner>
                </FadeIn>
              </StyledWrapper>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
