import { useState } from "react";
import FadeIn from "react-fade-in";
import CloseIcon from "@mui/icons-material/Close";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import {
  IconButton,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { useSites } from "../../hooks/useSites";
import {
  StyledCalendarWrapper,
  StyledDate,
  StyledDialog,
  StyledFlexGrow,
  StyledInnerDialog,
  StyledSecondary,
  StyledTitle,
} from "./Calendar.styles";
import { getEvents } from "./Calendar.utils";
import "./Calendar.css";
import { Link } from "react-router-dom";

export const Calendar = () => {
  const [open, setOpen] = useState(false);
  const [currentSite, setCurrentSite] = useState<any>();
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const { sites, loading } = useSites();

  const handleClose = () => {
    setOpen(false);
  };

  const getSiteInfo = (siteNumber: string) => {
    let thisSite = sites.find((x: any) => x.siteNumber === siteNumber);

    setCurrentSite(thisSite);

    if (thisSite) {
      setOpen(true);
    }
  };

  return (
    <PageWrapper hidePadding loading={loading}>
      <StyledFlexGrow>
        <StyledCalendarWrapper>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            eventClick={function (arg) {
              setTitle(arg.event.title);
              setDate(arg.event.groupId);
              getSiteInfo(arg.event.id);
            }}
            initialView="dayGridMonth"
            height="calc(100vh - 85px)"
            events={getEvents(sites)}
          />
        </StyledCalendarWrapper>
      </StyledFlexGrow>
      {currentSite && (
        <StyledDialog open={open}>
          <StyledInnerDialog>
            <IconButton
              style={{ position: "absolute", top: 5, right: 5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Card style={{ minWidth: 275 }}>
              <CardContent style={{ padding: 40 }}>
                <FadeIn>
                  <StyledTitle color="textSecondary" gutterBottom>
                    {currentSite.siteNumber} - {currentSite.name}
                  </StyledTitle>
                  <StyledSecondary variant="h5">{title}</StyledSecondary>
                  <StyledDate color="textSecondary">{date}</StyledDate>
                  <StyledSecondary variant="body2">
                    {currentSite.address}
                    <br />
                    {currentSite.city}, {currentSite.state} {currentSite.zip}
                  </StyledSecondary>
                </FadeIn>
              </CardContent>
              <CardActions
                style={{
                  marginTop: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to={`/facilities?site=${currentSite.siteNumber}`}
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  <Button size="small">Navigate To Site</Button>
                </Link>
              </CardActions>
            </Card>
          </StyledInnerDialog>
        </StyledDialog>
      )}
    </PageWrapper>
  );
};
