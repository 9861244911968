import _ from "lodash";
import { supabase } from "../../supabase";

export async function UploadHelpfulDocumentFn(
  site: any,
  data: any,
  docName: string,
  field: any
) {
  let url = await uploadPhoto(site, data, docName, field);

  return new Promise(async function (resolve, reject) {
    let fieldData = _.cloneDeep(site.dataFields);
    let index = fieldData.findIndex((e: any) => e.name === field);

    let addMe = {
      name: docName,
      documentLink: url,
    };

    //check if
    if ("documents" in fieldData[index]) {
      fieldData[index].documents.push(addMe);
    } else {
      fieldData[index].documents = [addMe];
    }

    const test = await supabase
      .from("sites")
      .update({
        dataFields: fieldData,
      })
      .eq("siteNumber", site.siteNumber);

    return resolve(test);
  });
}

export async function UploadTrainingHelpfulDocumentFn(
  site: any,
  data: any,
  docName: string
) {
  let url = await uploadTrainingPhoto(site, data, docName);

  return new Promise(async function (resolve, reject) {
    let addMe = {
      name: docName,
      documentLink: url,
    };

    let arrCopy = undefined;
    if (Array.isArray(site.trainingDocuments)) {
      arrCopy = _.cloneDeep(site.trainingDocuments);
      arrCopy.push(addMe);
    } else {
      arrCopy = [addMe];
    }

    const test = await supabase
      .from("sites")
      .update({
        trainingDocuments: arrCopy,
      })
      .eq("siteNumber", site.siteNumber);

    return resolve(test);
  });
}

export async function UploadHazardousWasteHelpfulDocumentFn(
  site: any,
  data: any,
  docName: string
) {
  let url = await uploadHazardousWastePhoto(site, data, docName);

  return new Promise(async function (resolve, reject) {
    let addMe = {
      name: docName,
      documentLink: url,
    };

    let arrCopy = undefined;
    if (Array.isArray(site.hazardousWasteDocuments)) {
      arrCopy = _.cloneDeep(site.hazardousWasteDocuments);
      arrCopy.push(addMe);
    } else {
      arrCopy = [addMe];
    }

    const test = await supabase
      .from("sites")
      .update({
        hazardousWasteDocuments: arrCopy,
      })
      .eq("siteNumber", site.siteNumber);

    return resolve(test);
  });
}

async function uploadPhoto(site: any, info: any, docName: string, field: any) {
  let UUID = site.uuid + 1;

  await supabase
    .from("sites")
    .update({
      uuid: UUID,
    })
    .eq("siteNumber", site.siteNumber);

  await supabase.storage
    .from("sites")
    .upload(`${site.siteNumber}/HelpfulDocuments/${field}-${UUID}`, info);

  return `${site.siteNumber}/HelpfulDocuments/${field}-${UUID}`;
}

async function uploadTrainingPhoto(site: any, info: any, docName: string) {
  let UUID = site.trainingUUID + 1;

  await supabase
    .from("sites")
    .update({
      trainingUUID: UUID,
    })
    .eq("siteNumber", site.siteNumber);

  await supabase.storage
    .from("training-helpful-documents")
    .upload(`${site.siteNumber}/HelpfulDocuments/${docName}-${UUID}`, info);

  return `${site.siteNumber}/HelpfulDocuments/${docName}-${UUID}`;
}

async function uploadHazardousWastePhoto(
  site: any,
  info: any,
  docName: string
) {
  let UUID = site.hazardousWasteUUID + 1;

  await supabase
    .from("sites")
    .update({
      hazardousWasteUUID: UUID,
    })
    .eq("siteNumber", site.siteNumber);

  await supabase.storage
    .from("hazardouswaste-helpful-documents")
    .upload(`${site.siteNumber}/HelpfulDocuments/${docName}-${UUID}`, info);

  return `${site.siteNumber}/HelpfulDocuments/${docName}-${UUID}`;
}
