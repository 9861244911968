import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Add, Delete } from "@mui/icons-material";
import styled from "@emotion/styled";
import { OPERATING_COMPANIES, REPORTING_TYPES } from "../../../SharedConstants";
import { UploadSdsSheetFn } from "../../../backends/commands/UploadSdsSheetFn";
import { UploadDragger } from "../../../components/uploadDragger/UploadDragger";
import { UploadContactPdfFn } from "../../../backends/commands/UploadContactPdfFn";
import { ButtonIconButton } from "../../../components/button/ButtonIconButton";
import {
  StyledInner,
  StyledWrapper,
} from "../../emergencyResponse/EmergencyResponse.styles";
import {
  UploadHazardousWasteHelpfulDocumentFn,
  UploadHelpfulDocumentFn,
  UploadTrainingHelpfulDocumentFn,
} from "../../../backends/commands/UploadHelpfulDocumentFn";

interface AddHelpfulDocumentDialogProps {
  onSuccess: () => void;
  type: string;
  site: any;
  key?: number;
}

export const AddHelpfulDocumentDialog = ({
  onSuccess,
  type,
  site,
  key,
}: AddHelpfulDocumentDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 1082px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [fileError, setFileError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [files, setFiles] = useState<Array<any>>([]);

  const [showFileName, setShowFileName] = useState();

  const handleReset = () => {
    setFileError(false);
    setFiles([]);
    setErrorText("");
    setError(false);
    setShowFileName(undefined);
  };

  useEffect(() => {
    if (!files.length) {
      setErrorText("");
      setError(false);
      return;
    }

    if (type === "Training") {
      const existingNames =
        site?.trainingDocuments?.map((doc: any) => doc.name) ?? [];

      if (existingNames.includes(files[0].documentName)) {
        setError(true);
        setDuplicate(true);
        setErrorText("Document already exists");
      } else {
        setError(false);
        setDuplicate(false);
        setErrorText("");
      }
    } else if (type === "Hazardous Waste") {
      const existingNames =
        site?.hazardousWasteDocuments?.map((doc: any) => doc.name) ?? [];

      if (existingNames.includes(files[0].documentName)) {
        setError(true);
        setDuplicate(true);
        setErrorText("Document already exists");
      } else {
        setError(false);
        setDuplicate(false);
        setErrorText("");
      }
    } else {
      if (key) {
        const existingNames = site?.dataFields[key].documents?.map(
          (doc: any) => doc.name
        );
        if (existingNames.includes(files[0].documentName)) {
          setError(true);
          setDuplicate(true);
          setErrorText("Document already exists");
        } else {
          setError(false);
          setDuplicate(false);
          setErrorText("");
        }
      }
    }
  }, [
    files,
    key,
    site?.dataFields,
    site.files,
    site?.hazardousWasteDocuments,
    site?.trainingDocuments,
    type,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
    handleReset();
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async () => {
    if (!files.length) {
      return;
    }

    setErrorText("");
    setError(false);
    setShowSpinner(true);

    let response: any = false;
    if (type === "Training") {
      response = await UploadTrainingHelpfulDocumentFn(
        site,
        files[0].path,
        files[0].documentName
      );
    } else if (type === "Hazardous Waste") {
      response = await UploadHazardousWasteHelpfulDocumentFn(
        site,
        files[0].path,
        files[0].documentName
      );
    } else {
      response = await UploadHelpfulDocumentFn(
        site,
        files[0].path,
        files[0].documentName,
        type
      );
    }

    if (!response) {
      setErrorText("Error adding helpful document");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleFileSelected = (e: any) => {
    setShowFileName(e.documentName);
    setFiles([e]);
  };

  return (
    <div>
      <ButtonIconButton
        iconOnly={iconOnly}
        label={"Add"}
        onClick={handleClickOpen}
        icon={<Add />}
        tooltip={"Add Document"}
        color="success"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledWrapper>
          <IconButton
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
            size="large"
            disabled={showSpinner}
          >
            <CloseIcon />
          </IconButton>
          {/* BOUNDARY INSIDE */}
          <FadeIn>
            <StyledInner>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                <b>Add Helpful Document</b>
              </Typography>

              {showFileName !== undefined ? (
                <p
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    textAlign: "center",
                    margin: 10,
                  }}
                >
                  {showFileName}
                </p>
              ) : (
                <div
                  style={{
                    marginTop: 10,
                    textAlign: "center",
                    border: fileError ? "1px solid red" : "none",
                    width: "auto",
                    padding: 10,
                  }}
                >
                  <UploadDragger returnDocument={handleFileSelected} />
                </div>
              )}

              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "auto",
                }}
              >
                <Button
                  disabled={showSpinner || duplicate}
                  variant="contained"
                  style={{
                    marginTop: 30,
                    width: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontFamily: "'Maven Pro', sans-serif",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={handleSave}
                >
                  {showSpinner ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    <>Submit</>
                  )}
                </Button>
              </div>
              {error ? (
                <Typography style={{ color: "red", textAlign: "center" }}>
                  *{errorText}
                </Typography>
              ) : null}
            </StyledInner>
          </FadeIn>
        </StyledWrapper>
      </Dialog>
    </div>
  );
};
