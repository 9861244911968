import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { contacts } from "../store/Store";
import { useEffect } from "react";
import { GetContactsFn } from "../backends/queries/GetContactsFn";

export const useContacts = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery(
    "contacts",
    GetContactsFn
  );
  const [contactsList, setContactsList] = useRecoilState(contacts);

  useEffect(() => {
    isSuccess && data.data && setContactsList(data.data);
  }, [data?.data, isSuccess, setContactsList]);

  return {
    contacts: contactsList,
    refreshContacts: refetch,
    contactsLoading: isLoading,
  };
};
