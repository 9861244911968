import styled from "@emotion/styled";
import { AlertTitle } from "@mui/material";

export const StyledAlertWrapper = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 10;
`;

export const StyledAlertTitle = styled(AlertTitle)`
  margin: 0;
`;
