export const LEADERSHIP = [
  {
    position: "Environmental Manager",
    name: "Doug Pennington",
    email: "dpennington@pape.com",
  },
  {
    position: "WA Environmental Manager",
    name: "Brendan Goodnough",
    email: "bgoodnough@pape.com",
  },
  {
    position: "Northern CA Environmental Manager",
    name: "Ethan Valburg",
    email: "evalburg@pape.com",
  },
  {
    position: "Southern CA Environmental Manager",
    name: "Cara Costamagna",
    email: "ccostamagna@pape.com",
  },
  {
    position: "Papé Environmental Consultant",
    name: "Janet Bianchin",
    email: "janet.bianchin@bajadageo.com",
  },
  {
    position: "Department Coordinator",
    name: "Julie Mickey",
    email: "jmickey@pape.com",
  },
];

export enum TAB {
  CONTACTS,
  PDFS,
}
