import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReplayIcon from "@mui/icons-material/Replay";
import styled from "@emotion/styled";

import { StyledCard, StyledCardRightDiv } from "./Facilities.styles";
import { ConditionalWrapper } from "../../components/conditionalWrapper/ConditionalWrapper";
import { SitesDrawer } from "./components/SitesDrawer";
import { OPERATING_COMPANIES } from "../../SharedConstants";

export const StyledSelect = styled(Select)`
  .MuiPaper-root {
    min-width: 290px !important;
  }
`;

export const States = [
  "None",
  "Alaska",
  "Arizona",
  "California",
  "Hawaii",
  "Idaho",
  "Montana",
  "Nevada",
  "Oregon",
  "Washington",
];

interface SiteListProps {
  sites: any[] | null;
  setActiveSite: (site: any) => void;
  setSearchParams: (site: any) => void;
  scrollActiveSite?: any;
  activeSite: any;
}

export const SiteList = ({
  sites,
  setActiveSite,
  setSearchParams,
  scrollActiveSite,
  activeSite,
}: SiteListProps) => {
  const [filterString, setFilterString] = useState<string>("");
  const [stateFilter, setStateFilter] = useState<string>("None");
  const [ocFilter, setOcFilter] = useState<string>("None");
  const showDrawer = useMediaQuery("(min-width: 968px)");

  useEffect(() => {
    if (scrollActiveSite) {
      document.getElementById(scrollActiveSite.name)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [scrollActiveSite]);

  const onSiteClick = (site: any) => {
    document.getElementById(site.name)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    setActiveSite(site);
    setSearchParams({ site: site.siteNumber });
    !showDrawer && resetFilters();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const resetFilters = () => {
    setFilterString("");
    setStateFilter("None");
    setOcFilter("None");
  };

  const getSites = () => {
    let filteredSites = sites
      ? sites?.filter(
          (site) =>
            site?.siteNumber
              ?.toLowerCase()
              .includes(filterString.toLowerCase()) ||
            site?.name?.toLowerCase().includes(filterString.toLowerCase()) ||
            site?.city?.toLowerCase().includes(filterString.toLowerCase()) ||
            site?.state?.toLowerCase().includes(filterString.toLowerCase())
        )
      : [];

    if (!filteredSites.length) {
      return [];
    }

    if (stateFilter !== "None") {
      filteredSites = filteredSites.filter((e) =>
        e.state.includes(stateFilter)
      );
    }

    if (ocFilter !== "None") {
      filteredSites = filteredSites.filter((e) =>
        e.oCompany.includes(ocFilter)
      );
    }

    return filteredSites;
  };

  const showBadge = () => {
    return stateFilter === "None" && ocFilter === "None";
  };

  return (
    <ConditionalWrapper
      wrap={!showDrawer}
      wrapperComponent={(children) => (
        <SitesDrawer children={children} scrollActiveSite={scrollActiveSite} />
      )}
    >
      <div style={{ borderRight: "1px solid #e8e8e8" }}>
        <div
          style={{
            width: 350,
            borderBottom: "1px solid #e8e8e8",
            color: "#000",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            backgroundColor: "white",
          }}
        >
          <Tooltip title="Filters">
            <IconButton
              sx={{ p: "10px" }}
              aria-label="menu"
              onClick={handleClick}
              size="large"
            >
              <Badge color="warning" variant="dot" invisible={showBadge()}>
                <FilterListIcon style={{ color: "#000" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            disablePortal
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 1500 }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: 0,
            }}
          >
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <Box
                style={{
                  backgroundColor: "#fff",
                  width: 300,
                  padding: 10,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  height: 150,
                  boxShadow: "0 10px 8px 0 rgba(0,0,0,0.2)",
                }}
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ fontFamily: "'Maven Pro', sans-serif" }}
                  >
                    State
                  </InputLabel>

                  <StyledSelect
                    MenuProps={{
                      style: { width: "290px", minWidth: "0px" },
                      disablePortal: true,
                      anchorReference: "anchorPosition",
                      anchorPosition: { top: 63, left: -10 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stateFilter}
                    label="State"
                    onChange={(e: any) => setStateFilter(e.target.value)}
                  >
                    {States.map((state) => (
                      <MenuItem
                        key={state}
                        value={state}
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        {state}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: 20 }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ fontFamily: "'Maven Pro', sans-serif" }}
                  >
                    Operating Company
                  </InputLabel>
                  <StyledSelect
                    MenuProps={{
                      style: { width: "290px", minWidth: "0px" },
                      disablePortal: true,
                      anchorReference: "anchorPosition",
                      anchorPosition: { top: 142, left: 0 },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={ocFilter}
                    label="Operating Company"
                    onChange={(e: any) => setOcFilter(e.target.value)}
                  >
                    {OPERATING_COMPANIES.map((oc) => (
                      <MenuItem
                        key={oc}
                        value={oc}
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        {oc}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Box>
            </ClickAwayListener>
          </Popover>
          <InputBase
            id="filterSiteText"
            onChange={(e) => setFilterString(e.target.value)}
            style={{
              color: "#000",
              flexGrow: 1,
              fontFamily: "'Maven Pro', sans-serif",
            }}
            value={filterString}
            placeholder="Search..."
            inputProps={{ "aria-label": "search google maps" }}
          />
          <Divider
            orientation="vertical"
            style={{
              backgroundColor: "#000",
              color: "#000",
              width: 1,
              height: 31,
              marginTop: 8,
            }}
          />
          <Tooltip title="Reset Filters">
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => resetFilters()}
              size="large"
            >
              <ReplayIcon style={{ color: "#000" }} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          style={{
            width: 350,
            height: `calc(100vh - ${showDrawer ? "86" : "41"}px)`,
            overflowY: "scroll",
            padding: 10,
            gap: 10,
            marginTop: 41,
          }}
        >
          {getSites().map((site, idx) => (
            <StyledCard
              key={site.siteNumber}
              id={site.name}
              onClick={() => onSiteClick(site)}
              lastItem={idx + 1 === getSites().length}
              activeSite={site.siteNumber === activeSite?.siteNumber}
            >
              <div
                style={{
                  backgroundColor: "#2a265f",
                  height: 90,
                  width: 100,
                  minWidth: 100,
                  padding: 10,
                }}
              >
                <Typography
                  style={{
                    color: "#8989a5",
                    fontFamily: "'Maven Pro', sans-serif",
                    fontSize: 12,
                  }}
                >
                  Store Number
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    fontFamily: "'Maven Pro', sans-serif",
                  }}
                >
                  {site.siteNumber}
                </Typography>
              </div>
              <StyledCardRightDiv
                activeSite={site.siteNumber === activeSite?.siteNumber}
              >
                <Typography
                  style={{
                    color:
                      site.siteNumber === activeSite?.siteNumber
                        ? "white"
                        : "black",
                    fontFamily: "'Maven Pro', sans-serif",
                  }}
                >
                  {site.name}
                </Typography>
                <Typography
                  style={{
                    color: "#727288",
                    fontSize: 13,
                    fontFamily: "'Maven Pro', sans-serif",
                  }}
                >
                  {site.city}, {site.state}
                </Typography>
              </StyledCardRightDiv>
            </StyledCard>
          ))}
        </div>
      </div>
    </ConditionalWrapper>
  );
};
