import _ from "lodash";
import { supabase } from "../../supabase";

export async function DeleteHelpfulDocumentFn(
  site: any,
  row: any,
  reportType: string
) {
  return new Promise(async function (resolve, reject) {
    // DELETE DOC FROM STORAGE
    const deleted = await supabase.storage
      .from("sites")
      .remove([row.documentLink]);

    if (deleted.error !== null) {
      return resolve(false);
    }

    // DELETE DOC FROM FIRESTORE
    let fieldData = _.cloneDeep(site.dataFields);
    let index = fieldData.findIndex((e: any) => e.name === reportType);

    let innerIndex = fieldData[index].documents.findIndex(
      (e: any) => e.name === row.name
    );

    fieldData[index].documents.splice(innerIndex, 1);

    let updated = await supabase
      .from("sites")
      .update({
        dataFields: fieldData,
      })
      .eq("siteNumber", site.siteNumber);

    return resolve(updated);
  });
}

export async function DeleteTrainingHelpfulDocumentFn(site: any, row: any) {
  return new Promise(async function (resolve, reject) {
    // DELETE DOC FROM STORAGE
    const deleted = await supabase.storage
      .from("training-helpful-documents")
      .remove([row.documentLink]);

    if (deleted.error !== null) {
      return resolve(false);
    }

    // DELETE DOC FROM Tables
    let fieldData = _.cloneDeep(site.trainingDocuments);
    let index = fieldData.findIndex((e: any) => e.name === row.name);

    fieldData.splice(index, 1);

    let updated = await supabase
      .from("sites")
      .update({
        trainingDocuments: fieldData,
      })
      .eq("siteNumber", site.siteNumber);

    return resolve(updated);
  });
}

export async function DeleteHazardousWasteHelpfulDocumentFn(
  site: any,
  row: any
) {
  return new Promise(async function (resolve, reject) {
    // DELETE DOC FROM STORAGE
    const deleted = await supabase.storage
      .from("hazardouswaste-helpful-documents")
      .remove([row.documentLink]);

    if (deleted.error !== null) {
      return resolve(false);
    }

    // DELETE DOC FROM Tables
    let fieldData = _.cloneDeep(site.hazardousWasteDocuments);
    let index = fieldData.findIndex((e: any) => e.name === row.name);

    fieldData.splice(index, 1);

    let updated = await supabase
      .from("sites")
      .update({
        hazardousWasteDocuments: fieldData,
      })
      .eq("siteNumber", site.siteNumber);

    return resolve(updated);
  });
}
