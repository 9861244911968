import { supabase } from "../../supabase";

export async function DeleteGlobalDocumentFn(values: any) {
  return new Promise(async function (resolve, reject) {
    // DELETE DOC FROM STORAGE
    const deleted = await supabase.storage
      .from("global-documents")
      .remove([values.path]);

    if (deleted.error !== null) {
      return resolve(false);
    }

    const deleteDb = await supabase
      .from("globalDocuments")
      .delete()
      .match({ id: values.id });

    return resolve(deleteDb);
  });
}
