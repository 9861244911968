/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";

import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";

import styled from "@emotion/styled";
import { supabase } from "../../supabase";
import AlertToaster from "../../components/alert/Alert";
import { useUsers } from "../../hooks/useUsers";
import { NewUserFn } from "../../backends/commands/NewUserFn";
import { Add } from "@mui/icons-material";

export const StyledTextField = styled(TextField)({
  marginBottom: 10,
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

interface NewUserDialogProps {
  onSave: () => void;
}

export const NewUserDialog = ({ onSave }: NewUserDialogProps) => {
  const mobile = useMediaQuery("(min-width: 500px)");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { users, refreshUsers } = useUsers();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (values: any) => {
    const data = {
      email: values.email.toLowerCase(),
      admin: values.administrator,
      name: values.name,
      introduction: "Resume Introduction...",
      location: "TBD",
      position: values.position,
      yearsWithCompany: values.yearsWithCompany
        ? parseInt(values.yearsWithCompany)
        : 0,
      cantView: "{}",
      cantUpload: "{}",
      cantEdit: "{}",
      cantDelete: "{}",
    };

    setLoading(true);

    let test = await NewUserFn(data, values.password);

    if (!test) {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      setLoading(false);
      setOpen(false);
      onSave();
    }
  };

  const YupSchema = () => {
    return Yup.object().shape({
      name: Yup.string().max(100, "Maximum of 100 characters").trim(),
      position: Yup.string().max(100, "Maximum of 100 characters").trim(),
      yearsWithCompany: Yup.string()
        .max(100, "Maximum of 100 characters")
        .trim(),
      email: Yup.string()
        .max(100, "Maximum of 100 characters")
        .trim()
        .email("Not a Valid Email")
        .min(5, "Minimum of 5 characters")
        .test({
          message: "Email already exists",
          test: (value) => {
            const arr = users.map((user) => user.email.toLowerCase());
            return !arr.includes(value?.toLowerCase());
          },
        })
        .required("Required"),
      password: Yup.string()
        .max(100, "Maximum of 100 characters")
        .trim()
        .min(8, "Minimum of 8 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .max(100, "Maximum of 100 characters")
        .trim()
        .min(8, "Minimum of 8 characters")
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Required"),
      administrator: Yup.boolean(),
    });
  };

  return (
    <div style={{ width: mobile ? "initial" : "100%" }}>
      {successAlert ? <AlertToaster message="Error" severity="error" /> : null}

      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        startIcon={<Add />}
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          flexGrow: 1,
          color: "#000",
        }}
      >
        Create New User
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            position: "",
            yearsWithCompany: "",
            email: "",
            password: "",
            confirmPassword: "",
            administrator: false,
          }}
          validationSchema={YupSchema}
          onSubmit={handleSave}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            dirty,
            setFieldValue,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  position: "fixed",
                  width: "50%",
                  height: "auto",
                  minHeight: 450,
                  left: "50%",
                  top: "50%",
                  overflow: "hidden",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  maxWidth: 1800,
                  minWidth: 300,
                }}
              >
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <div
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 20,
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 17,
                      textAlign: "center",
                      fontFamily: "'Maven Pro', sans-serif",
                      marginBottom: 15,
                      color: "#000",
                    }}
                  >
                    New User
                  </Typography>
                  <StyledTextField
                    name="email"
                    id="outlined-basic"
                    error={Boolean(errors.email)}
                    helperText={errors.email ?? ""}
                    required
                    label="Email"
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />
                  <StyledTextField
                    name="password"
                    type="password"
                    id="outlined-basic"
                    error={Boolean(errors.password)}
                    helperText={errors.password ?? ""}
                    label="Password"
                    variant="outlined"
                    required
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />
                  <StyledTextField
                    name="confirmPassword"
                    type="password"
                    id="outlined-basic"
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword ?? ""}
                    label="Confirm Password"
                    required
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />
                  <StyledTextField
                    name="name"
                    id="outlined-basic"
                    error={Boolean(errors.name)}
                    helperText={errors.name ?? ""}
                    label="Name"
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />

                  <StyledTextField
                    name="position"
                    id="outlined-basic"
                    error={Boolean(errors.position)}
                    helperText={errors.position ?? ""}
                    label="Position"
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />
                  <StyledTextField
                    name="yearsWithCompany"
                    id="outlined-basic"
                    error={Boolean(errors.yearsWithCompany)}
                    helperText={errors.yearsWithCompany ?? ""}
                    label="Years With Company"
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={(e) => (e.key === "Enter" ? submitForm() : null)}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{
                            color: "#000",
                          }}
                          checked={values.administrator}
                          name="administrator"
                          onClick={() =>
                            setFieldValue(
                              "administrator",
                              !values.administrator
                            )
                          }
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: "'Maven Pro', sans-serif",
                            color: "#000",
                          }}
                        >
                          Administrator (All Privilege Access)
                        </Typography>
                      }
                    />
                  </FormGroup>

                  <Button
                    variant="contained"
                    disabled={loading || !!!errors || !dirty}
                    style={{
                      marginTop: 29,
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontFamily: "'Maven Pro', sans-serif",
                      backgroundColor: "black",
                      color: "#fff",
                    }}
                    onClick={submitForm}
                  >
                    {!loading ? (
                      "Create"
                    ) : (
                      <CircularProgress style={{ color: "white" }} size={30} />
                    )}
                  </Button>
                </div>
                {error && (
                  <Typography
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginBottom: 10,
                      fontFamily: "'Maven Pro', sans-serif",
                    }}
                  >
                    *Error Creating User or User already exists
                  </Typography>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
