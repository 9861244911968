import { GetSdsFn } from "./../backends/queries/GetSdsFn";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { sds } from "../store/Store";
import { useEffect } from "react";

export const useSds = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery("sds", GetSdsFn);
  const [sdsList, setSdsList] = useRecoilState(sds);

  useEffect(() => {
    isSuccess && data.data && setSdsList(data.data);
  }, [data?.data, isSuccess, setSdsList]);

  return { sds: sdsList, refreshSds: refetch, loading: isLoading };
};
