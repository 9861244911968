import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { StyledGridWrapper } from "../EmergencyResponse.styles";
import { GridProps } from "../EmergencyResponse.types";

export const PdfGrid = ({
  state,
  activeRow,
  activeRowCount,
  filter,
  contactsPdfs,
}: GridProps) => {
  const [selectedRowDoc, setSelectedRowDoc] = useState<any>();
  const gridRef = useRef<AgGridReact<any>>(null);
  const [columnDefs] = useState([
    {
      field: "documentName",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      resizable: true,
      flex: 1,
    };
  }, []);

  useEffect(() => {
    gridRef?.current?.api?.setQuickFilter(filter ?? "");
  }, [filter]);

  function onSelectionChangeDocument(event: any) {
    var rowCount = event.api.getSelectedNodes().length;
    activeRowCount(rowCount);
  }

  function onRowClickedDocument(event: any) {
    if (event) {
      if (event.node.data === selectedRowDoc) {
        event.api.deselectAll();
        setSelectedRowDoc(undefined);
        activeRow(undefined);
      } else {
        setSelectedRowDoc(event.node.data);
        activeRow(event.node.data);
      }
    } else {
      setSelectedRowDoc(undefined);
      activeRow(undefined);
    }
  }

  return (
    <StyledGridWrapper className="ag-theme-alpine">
      <AgGridReact
        suppressDragLeaveHidesColumns
        ref={gridRef}
        rowData={contactsPdfs?.filter(
          (contactPdf) => contactPdf.state === state
        )}
        columnDefs={columnDefs}
        rowSelection="single"
        defaultColDef={defaultColDef}
        onSelectionChanged={onSelectionChangeDocument}
        onRowClicked={onRowClickedDocument}
        rowDeselection={true}
      />
    </StyledGridWrapper>
  );
};
