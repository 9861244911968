import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FadeIn from "react-fade-in";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import { Add } from "@mui/icons-material";
import { UploadDragger } from "../../../components/uploadDragger/UploadDragger";
import { ButtonIconButton } from "../../../components/button/ButtonIconButton";
import {
  StyledInner,
  StyledWrapper,
} from "../../emergencyResponse/EmergencyResponse.styles";
import {
  UploadHazardousWasteRecordFn,
  UploadRecordFn,
} from "../../../backends/commands/UploadRecordFn";
import { useProvideAuth } from "../../../auth/AuthProvider";

interface AddRecordDialogProps {
  onSuccess: () => void;
  type: string;
  site: any;
}

function getFormattedDate(date: any) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export const AddRecordDialog = ({
  onSuccess,
  type,
  site,
}: AddRecordDialogProps) => {
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const iconOnly = useMediaQuery("(min-width: 1082px)");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [fileError, setFileError] = useState(false);
  const [files, setFiles] = useState<Array<any>>([]);
  const auth = useProvideAuth();

  const [showFileName, setShowFileName] = useState();

  const handleReset = () => {
    setFileError(false);
    setFiles([]);
    setErrorText("");
    setError(false);
    setShowFileName(undefined);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
    handleReset();
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSave = async () => {
    if (!files.length) {
      return;
    }

    setErrorText("");
    setError(false);
    setShowSpinner(true);

    const data = {
      name: type,
      reportType: "monthly",
      siteNumber: site.siteNumber,
      uploadDate: getFormattedDate(new Date()),
      jsDate: getFormattedDate(new Date()),
      user: auth.user.email,
      picture: files[0].path,
      fileName: files[0].documentName,
      site: site,
    };

    let response = false;
    if (data.name === "Hazardous Waste") {
      response = (await UploadHazardousWasteRecordFn(data)) as boolean;
    } else {
      response = (await UploadRecordFn(data)) as boolean;
    }

    if (!response) {
      setErrorText("Error adding record");
      setError(true);
      setShowSpinner(false);
    } else {
      onSuccess();
      setShowSpinner(false);
      handleClose();
    }
  };

  const handleFileSelected = (e: any) => {
    setShowFileName(e.documentName);
    setFiles([e]);
  };

  return (
    <div>
      <ButtonIconButton
        iconOnly={iconOnly}
        label={"Add"}
        onClick={handleClickOpen}
        icon={<Add />}
        tooltip={"Add Record"}
        color="success"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledWrapper>
          <IconButton
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
            size="large"
            disabled={showSpinner}
          >
            <CloseIcon />
          </IconButton>
          {/* BOUNDARY INSIDE */}
          <FadeIn>
            <StyledInner>
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                <b>Add</b>
              </Typography>

              {showFileName !== undefined ? (
                <p
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    textAlign: "center",
                    margin: 10,
                  }}
                >
                  {showFileName}
                </p>
              ) : (
                <div
                  style={{
                    marginTop: 10,
                    textAlign: "center",
                    border: fileError ? "1px solid red" : "none",
                    width: "auto",
                    padding: 10,
                  }}
                >
                  <UploadDragger returnDocument={handleFileSelected} />
                </div>
              )}

              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "auto",
                }}
              >
                <Button
                  disabled={showSpinner}
                  variant="contained"
                  style={{
                    marginTop: 30,
                    width: "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontFamily: "'Maven Pro', sans-serif",
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={handleSave}
                >
                  {showSpinner ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    <>Submit</>
                  )}
                </Button>
              </div>
              {error ? (
                <Typography style={{ color: "red", textAlign: "center" }}>
                  *{errorText}
                </Typography>
              ) : null}
            </StyledInner>
          </FadeIn>
        </StyledWrapper>
      </Dialog>
    </div>
  );
};
