import { Button, IconButton, Tooltip } from "@mui/material";

interface ButtonIconButtonProps<T> {
  iconOnly: boolean;
  label: string;
  onClick: (item?: T) => void;
  icon: any;
  tooltip: string;
  disabled?: boolean;
  activeItem?: T;
  style?: any;
  color:
    | "inherit"
    | "success"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning";
}

export const ButtonIconButton = <T,>({
  iconOnly,
  label,
  onClick,
  icon,
  tooltip,
  color,
  disabled,
  activeItem,
  style,
}: ButtonIconButtonProps<T>) => {
  return iconOnly ? (
    <Button
      onClick={() => onClick(activeItem)}
      style={{ fontFamily: "'Maven Pro', sans-serif", ...style }}
      color={color}
      variant="outlined"
      startIcon={icon}
      disabled={disabled}
    >
      {label}
    </Button>
  ) : (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={() => onClick(activeItem)}
        style={{ fontFamily: "'Maven Pro', sans-serif", ...style }}
        color={color}
        disabled={disabled}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};
