import { records } from "./../store/Store";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { GetRecordsFn } from "../backends/queries/GetRecordsFn";

export const useRecords = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery(
    "records",
    GetRecordsFn
  );

  const [recordsList, setRecords] = useRecoilState(records);

  useEffect(() => {
    isSuccess && data.data && setRecords(data.data);
  }, [data?.data, isSuccess, setRecords]);

  return { records: recordsList, refreshRecords: refetch, loading: isLoading };
};
