import _ from "lodash";
import { supabase } from "../../supabase";

const updateStatus = (field: any, dataFields: any, fieldName: any) => {
  let fieldIndex = 0;
  let monthIndex = undefined;
  let quarterIndex = undefined;

  //first Index
  switch (fieldName) {
    case "Tier II":
      fieldIndex = 0;
      break;
    case "Waste Management":
      fieldIndex = 1;
      break;
    case "Stormwater":
      fieldIndex = 2;
      break;
    case "Wastewater":
      fieldIndex = 3;
      break;
    case "Air":
      fieldIndex = 4;
      break;
    case "Fire/Backflow":
      fieldIndex = 5;
      break;
    case "SPCC":
      fieldIndex = 6;
      break;
    default:
  }

  //quarter Index
  if (Object.keys(field).includes("name") && Object.keys(field).length === 2) {
    switch (field.name) {
      case "Q1":
        quarterIndex = 0;
        break;
      case "Q2":
        quarterIndex = 1;
        break;
      case "Q3":
        quarterIndex = 2;
        break;
      case "Q4":
        quarterIndex = 3;
        break;
      default:
    }
  }

  //month Index
  if (Object.keys(field).includes("month") && Object.keys(field).length === 2) {
    switch (field.month) {
      case "January":
        monthIndex = 0;
        break;
      case "February":
        monthIndex = 1;
        break;
      case "March":
        monthIndex = 2;
        break;
      case "April":
        monthIndex = 3;
        break;
      case "May":
        monthIndex = 4;
        break;
      case "June":
        monthIndex = 5;
        break;
      case "July":
        monthIndex = 6;
        break;
      case "August":
        monthIndex = 7;
        break;
      case "September":
        monthIndex = 8;
        break;
      case "October":
        monthIndex = 9;
        break;
      case "November":
        monthIndex = 10;
        break;
      case "December":
        monthIndex = 11;
        break;
      default:
    }
  }

  const dataFieldsCopy = _.cloneDeep(dataFields);

  if (quarterIndex !== undefined) {
    if (dataFieldsCopy[fieldIndex].quarters[quarterIndex].status === "Done") {
      dataFieldsCopy[fieldIndex].quarters[quarterIndex].status = "Not Done";
    } else {
      dataFieldsCopy[fieldIndex].quarters[quarterIndex].status = "Done";
    }
  } else if (monthIndex !== undefined) {
    if (dataFieldsCopy[fieldIndex].months[monthIndex].status === "Done") {
      dataFieldsCopy[fieldIndex].months[monthIndex].status = "Not Done";
    } else {
      dataFieldsCopy[fieldIndex].months[monthIndex].status = "Done";
    }
  } else {
    if (dataFieldsCopy[fieldIndex].status === "Done") {
      dataFieldsCopy[fieldIndex].status = "Not Done";
    } else {
      dataFieldsCopy[fieldIndex].status = "Done";
    }
  }

  return dataFieldsCopy;
};

const updateNonStandardStatus = (
  field: any,
  dataFields: any,
  fieldName: any
) => {
  const fieldsCopy = _.cloneDeep(dataFields);

  const monthIndex = fieldsCopy.months.findIndex(
    (monthObj: any) => monthObj.month === field.month
  );

  if (fieldsCopy.months[monthIndex].status === "Done") {
    fieldsCopy.months[monthIndex].status = "Not Done";
  } else {
    fieldsCopy.months[monthIndex].status = "Done";
  }

  return fieldsCopy;
};

export async function ChangeFieldStatusFn(data: any) {
  return new Promise(async function (resolve, reject) {
    if (data.fieldName === "Hazardous Waste") {
      const hazardousWasteFields = updateNonStandardStatus(
        data.field,
        data.site.hazardousWaste,
        data.fieldName
      );

      let test = await supabase
        .from("sites")
        .update({
          hazardousWaste: hazardousWasteFields,
        })
        .eq("siteNumber", data.site.siteNumber);

      return resolve(test);
    } else {
      const dataFields = updateStatus(
        data.field,
        data.site.dataFields,
        data.fieldName
      );

      let test = await supabase
        .from("sites")
        .update({
          dataFields: dataFields,
        })
        .eq("siteNumber", data.site.siteNumber);

      return resolve(test);
    }
  });
}
