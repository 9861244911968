export const HEADERS = [
  "Site Link",
  "Name",
  "State",
  "Store #",
  "Tier II",
  "Waste Management",
  "Stormwater Quarterly",
  "Stormwater Monthly",
  "Wastewater",
  "Wastewater Quarterly",
  "Air",
  "Fire/Backflow",
  "SPCC Monthly",
  "Hazardous Waste Monthly (California)",
];
