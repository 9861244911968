import _ from "lodash";
import { supabase } from "../../supabase";

export type EditLinkType = "BOTH" | "NOTE";

export async function EditLinkAndNotesFn(data: any, updateType: EditLinkType) {
  return new Promise(async function (resolve) {
    if (updateType === "BOTH") {
      let newLink = data.dbLink;
      let newNote = data.note;

      let fieldData = _.cloneDeep(data.site.dataFields);
      let index = fieldData.findIndex((e: any) => e.name === data.reportType);
      fieldData[index].externalDB = newLink;
      fieldData[index].note = newNote;

      let test = await supabase
        .from("sites")
        .update({
          dataFields: fieldData,
        })
        .eq("siteNumber", data.site.siteNumber);

      return resolve(test);
    } else {
      let newNote = data.note;

      //Training does not exist in dataFields array
      if (data.reportType === "Training") {
        let test = await supabase
          .from("sites")
          .update({
            trainingNote: newNote,
          })
          .eq("siteNumber", data.site.siteNumber);
        return resolve(test);
      } else if (data.reportType === "Hazardous Waste") {
        let test = await supabase
          .from("sites")
          .update({
            hazardousWasteNote: newNote,
          })
          .eq("siteNumber", data.site.siteNumber);
        return resolve(test);
      } else {
        //update Air, Fire/Backflow, SPCC, or No Report Type
        // in dataFields Array
        let fieldData = _.cloneDeep(data.site.dataFields);
        let index = fieldData.findIndex((e: any) => e.name === data.reportType);

        fieldData[index].note = newNote;

        let test = await supabase
          .from("sites")
          .update({
            dataFields: fieldData,
          })
          .eq("siteNumber", data.site.siteNumber);

        return resolve(test);
      }
    }
  });
}
