import { supabase } from "../../supabase";

export const DownloadHelpfulDocumentFn = async (path: string, type: string) => {
  if (type === "Training") {
    return supabase.storage
      .from("training-helpful-documents")
      .createSignedUrl(path, 14400);
  } else if (type === "Hazardous Waste") {
    return supabase.storage
      .from("hazardouswaste-helpful-documents")
      .createSignedUrl(path, 14400);
  } else {
    return supabase.storage.from("sites").createSignedUrl(path, 14400);
  }
};
