import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useMemo, useEffect } from "react";
import { StyledGridWrapper } from "../EmergencyResponse.styles";
import { GridProps } from "../EmergencyResponse.types";
import {
  CellRenderer,
  EmailRenderer,
  AddressRenderer,
  TagRenderer,
  AlwaysAvailableRenderer,
  WebsiteRenderer,
} from "./Renderers";

export const ContactsGrid = ({
  state,
  activeRow,
  activeRowCount,
  filter,
  contacts,
}: GridProps) => {
  const [rowCountDoc, setRowCountDoc] = useState(0);
  const [selectedRowDoc, setSelectedRowDoc] = useState<any>();
  const gridRef = useRef<AgGridReact<any>>(null);
  const [columnDefs] = useState([
    {
      field: "company",
      width: 200,
      maxWidth: 300,
      cellStyle: { fontFamily: "'Maven Pro', sans-serif" },
    },
    {
      field: "clientName",
      width: 250,
      maxWidth: 300,
      cellStyle: { fontFamily: "'Maven Pro', sans-serif" },
    },
    {
      field: "website",
      width: 250,
      maxWidth: 400,
      cellRenderer: WebsiteRenderer,
    },
    {
      field: "number",
      maxWidth: 180,
      cellRenderer: CellRenderer,
    },
    {
      headerName: "24/7",
      field: "alwaysAvailable",
      maxWidth: 70,
      cellRenderer: AlwaysAvailableRenderer,
    },
    {
      field: "email",
      width: 250,
      maxWidth: 350,
      cellRenderer: EmailRenderer,
    },
    {
      field: "address",
      width: 400,
      maxWidth: 550,
      cellRenderer: AddressRenderer,
    },
    {
      field: "tags",
      cellRenderer: TagRenderer,
      width: 120,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
    };
  }, []);

  useEffect(() => {
    gridRef?.current?.api?.setQuickFilter(filter ?? "");
  }, [filter]);

  function onSelectionChangeDocument(event: any) {
    var rowCount = event.api.getSelectedNodes().length;
    setRowCountDoc(rowCount);
    activeRowCount(rowCount);
  }

  function onRowClickedDocument(event: any) {
    if (event) {
      if (event.node.data === selectedRowDoc) {
        event.api.deselectAll();
        setSelectedRowDoc(undefined);
        activeRow(undefined);
      } else {
        setSelectedRowDoc(event.node.data);
        activeRow(event.node.data);
      }
    } else {
      setSelectedRowDoc(undefined);
      activeRow(undefined);
    }
  }

  return (
    <StyledGridWrapper className="ag-theme-alpine">
      <AgGridReact
        suppressDragLeaveHidesColumns
        ref={gridRef}
        rowData={contacts?.filter((contact) => contact.state === state)}
        columnDefs={columnDefs}
        rowSelection="single"
        defaultColDef={defaultColDef}
        onSelectionChanged={onSelectionChangeDocument}
        onRowClicked={onRowClickedDocument}
        rowDeselection
      />
    </StyledGridWrapper>
  );
};
