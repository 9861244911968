import PreviewIcon from "@mui/icons-material/Preview";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSds } from "../../hooks/useSds";
import { OPERATING_COMPANIES } from "../../SharedConstants";
import AlertToaster from "../../components/alert/Alert";
import UploadDialog from "./uploadDialog/UploadDialog";
import {
  StyledButtonWrapper,
  StyledFlex,
  StyledGridWrapper,
  StyledHeader,
  StyledInput,
  StyledTextField,
} from "./SdsLibrary.styles";
import { DownloadSdsFn } from "../../backends/commands/DownloadSdsFn";
import { DeleteSdsFn } from "../../backends/commands/DeleteSdsFn";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { sortChemicalName } from "./SdsLibrary.utils";
import { UpdateSdsArchiveFn } from "../../backends/commands/UpdateSdsArchiveFn";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

export const SdsLibrary = () => {
  const iconOnly = useMediaQuery("(min-width: 1082px)");
  const [rowCountDoc, setRowCountDoc] = useState(0);
  const [columnDefs] = useState([
    { field: "documentName", minWidth: 350 },
    { field: "chemicalName", minWidth: 350 },
    { field: "operatingCompany", minWidth: 180 },
    { field: "casNumber", headerName: "CAS #", minWidth: 350 },
  ]);
  const [selectedRowDoc, setSelectedRowDoc] = useState<any>();
  const [operatingCompanyFilter, setOperatingCompanyFilter] = useState("None");
  const [showActive, setShowActive] = useState(true);
  const [successAlert, setSuccessAlert] = useState(false);
  const { sds, loading, refreshSds } = useSds();
  const gridRef = useRef<AgGridReact<any>>(null);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
  }, []);

  const onQuickFilterChangedDocs = useCallback(() => {
    const input = document?.getElementById(
      "quickFilterDocs"
    ) as HTMLInputElement;
    gridRef?.current?.api.setQuickFilter(input.value);
  }, []);

  function onSelectionChangeDocument(event: any) {
    var rowCount = event.api.getSelectedNodes().length;
    setRowCountDoc(rowCount);
  }

  function onRowClickedDocument(event: any) {
    if (event) {
      if (event.node.data === selectedRowDoc) {
        event.api.deselectAll();
        setSelectedRowDoc(undefined);
      } else {
        setSelectedRowDoc(event.node.data);
      }
    } else {
      setSelectedRowDoc(undefined);
    }
  }

  const openDocLink = (link: any) => {
    const windowReference = window.open();

    DownloadSdsFn(link).then((res: any) => {
      if (windowReference) {
        windowReference.location = res.signedURL;
      }
    });
  };

  const deleteSds = async () => {
    const data = {
      record: selectedRowDoc,
    };

    const res: any = await DeleteSdsFn(data);

    if (res.error !== null) {
    } else {
      onSuccess();
      setRowCountDoc(0);
      setSelectedRowDoc(undefined);
    }
  };

  const onSuccess = () => {
    setSuccessAlert(true);
    refreshSds();
    setTimeout(function () {
      setSuccessAlert(false);
    }, 3000);
  };

  const getRowData = () => {
    const ocFilter =
      operatingCompanyFilter !== "None"
        ? sds.filter((sds) => sds.operatingCompany === operatingCompanyFilter)
        : sds;
    if (showActive) {
      return ocFilter.filter((sds) => !sds.archived).sort(sortChemicalName);
    } else {
      return ocFilter.filter((sds) => sds.archived).sort(sortChemicalName);
    }
  };

  const updateArchived = async (value: boolean, docId: number) => {
    const res: any = await UpdateSdsArchiveFn(
      !selectedRowDoc.archived,
      selectedRowDoc.id
    );

    if (res.error === null) {
      onSuccess();
      setRowCountDoc(0);
      setSelectedRowDoc(undefined);
    }
  };

  const reset = () => {
    setRowCountDoc(0);
    setSelectedRowDoc(undefined);
  };

  return (
    <PageWrapper loading={loading}>
      <>
        {successAlert ? (
          <AlertToaster message="Success" severity="success" />
        ) : null}
      </>
      <StyledFlex>
        <StyledHeader>Safety Data Sheets</StyledHeader>
        <FormControl>
          <RadioGroup row name="active-archive-radio" value={showActive}>
            <FormControlLabel
              value={true}
              onClick={() => {
                reset();
                setShowActive(true);
              }}
              control={<Radio />}
              label={
                <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                  Active
                </Typography>
              }
            />
            <FormControlLabel
              onClick={() => {
                reset();
                setShowActive(false);
              }}
              value={false}
              control={<Radio />}
              label={
                <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                  Archived
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </StyledFlex>
      <StyledButtonWrapper>
        <UploadDialog onSuccess={onSuccess} sds={sds} />
        {/* DELETE BUTTON */}
        {iconOnly ? (
          <Button
            disabled={rowCountDoc !== 1}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            style={{ marginLeft: 5 }}
            onClick={deleteSds}
          >
            Delete
          </Button>
        ) : (
          <Tooltip title="Delete Safety Data Sheet">
            <IconButton
              disabled={rowCountDoc !== 1}
              color="error"
              style={{ marginLeft: 5 }}
              onClick={deleteSds}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {/* VIEW BUTTON */}
        {iconOnly ? (
          <Button
            onClick={() => openDocLink(selectedRowDoc?.path)}
            disabled={rowCountDoc !== 1}
            variant="outlined"
            color="primary"
            startIcon={<PreviewIcon />}
            style={{ marginLeft: 5 }}
          >
            View SDS
          </Button>
        ) : (
          <Tooltip title="View Safety Data Sheet">
            <IconButton
              color="primary"
              onClick={() => openDocLink(selectedRowDoc?.path)}
              disabled={rowCountDoc !== 1}
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <PreviewIcon />
            </IconButton>
          </Tooltip>
        )}
        {/* CHANGED ARCHIVED BUTTON */}
        {iconOnly ? (
          <Button
            onClick={() =>
              updateArchived(!selectedRowDoc.archived, selectedRowDoc.id)
            }
            disabled={rowCountDoc !== 1}
            variant="outlined"
            color="warning"
            startIcon={showActive ? <ArchiveIcon /> : <CheckBoxIcon />}
            style={{ marginLeft: 5 }}
          >
            {showActive ? "Make Archive" : "Make Active"}
          </Button>
        ) : (
          <Tooltip title={showActive ? "Make Archive" : "Make Active"}>
            <IconButton
              color="warning"
              onClick={() =>
                updateArchived(!selectedRowDoc.archived, selectedRowDoc.id)
              }
              disabled={rowCountDoc !== 1}
            >
              {showActive ? <ArchiveIcon /> : <CheckBoxIcon />}
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <StyledTextField
          style={{
            width: 185,
          }}
          inputProps={{ sx: { height: 36 } }}
          variant="outlined"
          select
          id="demo-simple-select"
          value={operatingCompanyFilter}
          label="Operating Company"
          onChange={(operatingCompany: any) =>
            setOperatingCompanyFilter(operatingCompany.target.value)
          }
        >
          {OPERATING_COMPANIES.map((oc, idx) => (
            <MenuItem
              style={{ fontFamily: "'Maven Pro', sans-serif" }}
              key={idx}
              value={oc}
            >
              {oc}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledInput
          id="quickFilterDocs"
          onInput={onQuickFilterChangedDocs}
          placeholder="Search..."
          inputProps={{
            "aria-label": "search google maps",
          }}
        />
      </StyledButtonWrapper>
      <StyledGridWrapper className="ag-theme-alpine">
        <AgGridReact
          suppressDragLeaveHidesColumns
          ref={gridRef}
          rowData={getRowData()}
          columnDefs={columnDefs}
          rowSelection="single"
          defaultColDef={defaultColDef}
          onSelectionChanged={onSelectionChangeDocument}
          onRowClicked={onRowClickedDocument}
          rowDeselection={true}
        />
      </StyledGridWrapper>
    </PageWrapper>
  );
};
