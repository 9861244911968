import { Chip, Dialog, Stack } from "@mui/material";
import { SimpleDialogProps } from "../EmergencyResponse.types";

export function TagDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Stack direction="row" spacing={1} style={{ padding: 10 }}>
        {props.value.length ? (
          <>
            {props.value.map((tag: string) => (
              <Chip size="small" label={tag} variant="outlined" />
            ))}
          </>
        ) : (
          <p style={{ fontFamily: "'Maven Pro', sans-serif" }}>No Tags</p>
        )}
      </Stack>
    </Dialog>
  );
}
