import {
  Box,
  Divider,
  Fab,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StyledNotes, StyledSiteLoader } from "./Facilities.styles";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "semantic-ui-react";
import { AddEditSiteDialog } from "./components/AddEditSiteDialog";
import { REPORTING_TYPES } from "../../SharedConstants";
import {
  StyledInput,
  StyledTextField,
} from "../emergencyResponse/EmergencyResponse.styles";
import { getKey, getStatus } from "./Facilities.utils";
import { getExternalDbLink } from "./components/ReportingStatuses";
import { AgGridReact } from "ag-grid-react";
import { ButtonIconButton } from "../../components/button/ButtonIconButton";
import { Delete, Download, Preview } from "@mui/icons-material";
import { AddHelpfulDocumentDialog } from "./components/AddHelpfulDocument";
import { useProvideAuth } from "../../auth/AuthProvider";
import { useUser } from "../../hooks/useUser";
import { AddRecordDialog } from "./components/AddRecordDialog";
import { EditLinkAndNotesDialog } from "./components/EditLinkAndNotesDialog";
import { DownloadSiteImageFn } from "../../backends/commands/DownloadSiteImageFn";
import DeleteSite from "./components/DeleteSite";
import {
  DeleteHazardousWasteHelpfulDocumentFn,
  DeleteHelpfulDocumentFn,
  DeleteTrainingHelpfulDocumentFn,
} from "../../backends/commands/DeleteHelpfulDocumentFn";
import { DownloadHelpfulDocumentFn } from "../../backends/commands/DownloadHelpfulDocumentFn";
import { DeleteRecordFn } from "../../backends/commands/DeleteRecordFn";
import {
  DownloadRecordFn,
  DownloadRecordZipFn,
} from "../../backends/commands/DownloadRecordFn";
import JSZip from "jszip";
import saveAs from "file-saver";

export const ActiveSite = ({ activeSite, refresh, records }: any) => {
  const [selectedRowDoc, setSelectedRowDoc] = useState<any>();
  const [selectedRecordDoc, setSelectedRecordDoc] = useState<any[]>();
  const [helpfulDocumentsFilter, setHelpfulDocumentsFilter] =
    useState<string>();
  const [recordsFilter, setRecordsFilter] = useState<string>();
  const [helpfulDocumentsCount, setHelpfulDocumentsCount] = useState<number>();
  const [recordsCount, setRecordsCount] = useState<number>(0);
  const [fullScreen, toggleFullScreen] = useState(false);
  const [currentSiteAerial, setCurrentSiteAerial] = useState<string>("");
  const [type, setType] = useState<string>("No Report Type");
  const [helpfulColumnDefs] = useState([{ field: "name" }]);
  const [recordsColumnDefs] = useState([
    { field: "fileName" },
    { field: "uploadDate" },
    { field: "user" },
  ]);

  useEffect(() => {
    DownloadSiteImageFn(activeSite?.image).then((res) => {
      setCurrentSiteAerial(res.signedURL ?? "");
    });
  }, [activeSite?.image]);

  const auth = useProvideAuth();
  const { user } = useUser(auth.user.email);

  const mobile = useMediaQuery("(min-width: 680px)");
  const iconOnly = useMediaQuery("(min-width: 1082px)");

  const helpfulDocumentsGridRef = useRef<AgGridReact<any>>(null);
  const recordsGridRef = useRef<AgGridReact<any>>(null);

  useEffect(() => {
    helpfulDocumentsGridRef?.current?.api?.setQuickFilter(
      helpfulDocumentsFilter ?? ""
    );
  }, [helpfulDocumentsFilter]);

  useEffect(() => {
    recordsGridRef?.current?.api?.setQuickFilter(recordsFilter ?? "");
  }, [recordsFilter]);

  function onRowClickedDocument(event: any) {
    if (event) {
      if (event.node.data === selectedRowDoc) {
        event.api.deselectAll();
        setSelectedRowDoc(undefined);
      } else {
        setSelectedRowDoc(event.node.data);
      }
    } else {
      setSelectedRowDoc(undefined);
    }
  }

  function onRowClickedRecord(rows: any[], eventApi: any) {
    if (rows) {
      setSelectedRecordDoc(rows);
    } else {
      setSelectedRecordDoc(undefined);
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
  }, []);

  const openLink = async (type: string) => {
    const file =
      type === "siteMap"
        ? activeSite.wasteFormLink
        : activeSite.contactFormLink;
    const windowReference = window.open();

    return await DownloadSiteImageFn(file).then((res: any) => {
      if (windowReference) {
        windowReference.location = res.signedURL;
      }
    });
  };

  const openHelpfulDocumentLink = () => {
    if (selectedRowDoc) {
      const windowReference = window.open();

      DownloadHelpfulDocumentFn(
        selectedRowDoc.documentLink.replace("undefined", activeSite.siteNumber), //.replace fix 12/05/2023. Upload was adding undefined in path instead of siteNumber
        type
      ).then((res: any) => {
        if (windowReference) {
          windowReference.location = res.signedURL;
        }
      });
    }
  };

  const openRecordLink = () => {
    if (selectedRecordDoc) {
      const windowReference = window.open();

      DownloadRecordFn(selectedRecordDoc[0].image).then((res: any) => {
        if (windowReference) {
          windowReference.location = res.signedURL;
        }
      });
    }
  };

  const onSuccess = () => {
    refresh();
  };

  const shouldDisableReportType = (reportType: string, idx: number) => {
    switch (reportType) {
      case "No Report Type":
      case "Training":
      case "Hazardous Waste":
        return false;
      default:
        return !activeSite.dataFields[idx].applicable;
    }
  };

  const getNotes = () => {
    switch (type) {
      case "Training":
        return activeSite.trainingNote;
      case "Hazardous Waste":
        return activeSite.hazardousWasteNote;
      default:
        return activeSite.dataFields[getKey(type)].note;
    }
  };

  const getDbLink = () => {
    switch (type) {
      case "Tier II":
      case "Waste Management":
      case "Stormwater":
      case "Wastewater":
        return activeSite.dataFields[getKey(type)].externalDB;
    }
  };

  const helpfulDocumentsGrid = () => {
    switch (type) {
      case "Training":
        return activeSite.trainingDocuments;
      case "Hazardous Waste":
        return activeSite.hazardousWasteDocuments;
      default:
        return activeSite.dataFields[getKey(type)].documents ?? [];
    }
  };

  const recordsGrid = () => {
    const filtered = records.filter(
      (record: any) =>
        record.name === type && record.siteNumber === activeSite.siteNumber
    );

    return filtered;
  };

  const canUpload = () => {
    return (
      user &&
      (user[0].admin || !user[0].cantUpload.includes(activeSite.siteNumber))
    );
  };

  const canDelete = () => {
    return (
      user &&
      (user[0].admin || !user[0].cantDelete.includes(activeSite.siteNumber))
    );
  };

  const canEdit = () => {
    return (
      user &&
      (user[0].admin || !user[0].cantEdit.includes(activeSite.siteNumber))
    );
  };

  const shouldShowExternalLink = () => {
    switch (type) {
      case "Tier II":
      case "Waste Management":
      case "Stormwater":
      case "Wastewater":
        return true;
      default:
        return false;
    }
  };

  const deleteHelpfulDocument = async () => {
    if (helpfulDocumentsCount !== 1 || !selectedRowDoc) {
      return;
    }

    setSelectedRowDoc(undefined);
    setHelpfulDocumentsCount(0);

    if (type === "Training") {
      await DeleteTrainingHelpfulDocumentFn(activeSite, selectedRowDoc).then(
        () => onSuccess()
      );
    } else if (type === "Hazardous Waste") {
      await DeleteHazardousWasteHelpfulDocumentFn(
        activeSite,
        selectedRowDoc
      ).then(() => onSuccess());
    } else {
      await DeleteHelpfulDocumentFn(activeSite, selectedRowDoc, type).then(() =>
        onSuccess()
      );
    }
  };

  const reset = () => {
    setRecordsCount(0);
    setHelpfulDocumentsCount(0);
    setSelectedRecordDoc(undefined);
    setSelectedRowDoc(undefined);
  };

  const deleteRecord = async () => {
    if (recordsCount !== 1 || !selectedRecordDoc || !selectedRecordDoc.length) {
      return;
    }

    setSelectedRecordDoc(undefined);
    setRecordsCount(0);

    const data = {
      record: selectedRecordDoc[0],
      site: activeSite,
    };

    await DeleteRecordFn(data).then(() => onSuccess());
  };

  const downloadRecords = () => {
    if (!selectedRecordDoc?.length) {
      return;
    }

    var zip = new JSZip();
    const promises = selectedRecordDoc.map((record) =>
      DownloadRecordZipFn(record.image).then((res) => {
        if (res.data) {
          zip.file(record.fileName, res.data.arrayBuffer(), {
            base64: true,
          });
        }
      })
    );

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "Records.zip");
      });
    });
  };

  const getReportingTypes = () => {
    if (activeSite.state === "California") {
      return [
        ...REPORTING_TYPES,
        "No Report Type",
        "Training",
        "Hazardous Waste",
      ];
    } else {
      return [...REPORTING_TYPES, "No Report Type", "Training"];
    }
  };

  const getField = () => {
    if (type === "Hazardous Waste") {
      return activeSite.hazardousWaste;
    } else {
      return activeSite.dataFields[getKey(type)];
    }
  };

  return (
    <div
      style={{
        flexGrow: 1,
        height: "calc(100vh - 45px)",
        overflowY: "auto",
      }}
    >
      {activeSite ? (
        <div style={{ padding: 15, width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: mobile ? "row" : "column",
            }}
          >
            <div
              style={{
                width: mobile ? "50%" : "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontFamily: "'Maven Pro', sans-serif" }}
                    >
                      {activeSite.name}
                    </Typography>
                    <AddEditSiteDialog
                      type="EDIT"
                      onSuccess={() => refresh()}
                      site={activeSite}
                    />
                  </div>
                  <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                    {activeSite.address}
                  </Typography>
                  <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                    {activeSite.city}, {activeSite.state}
                  </Typography>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                    SIC Code: {activeSite.sic}
                  </Typography>
                  <Typography style={{ fontFamily: "'Maven Pro', sans-serif" }}>
                    NAICS Code: {activeSite.nics}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  gap: 10,
                  marginBottom: mobile ? 0 : 20,
                }}
              >
                <Fab
                  onClick={() => openLink("siteMap")}
                  variant="extended"
                  disabled={!activeSite.wasteFormLink}
                  style={{
                    maxWidth: 300,
                    maxHeight: 36,
                    textTransform: "none",
                    fontFamily: "'Maven Pro', sans-serif",
                    marginLeft: mobile ? 0 : "auto",
                    marginRight: mobile ? 0 : "auto",
                  }}
                >
                  Site Map
                  <MyLocationIcon
                    style={{
                      fontSize: 16,
                      marginLeft: 5,
                    }}
                  />
                </Fab>
                <Fab
                  disabled={!activeSite.contactFormLink}
                  variant="extended"
                  style={{
                    maxWidth: 300,
                    maxHeight: 36,
                    textTransform: "none",
                    fontFamily: "'Maven Pro', sans-serif",
                    marginLeft: mobile ? 0 : "auto",
                    marginRight: mobile ? 0 : "auto",
                  }}
                  onClick={() => openLink("contactForm")}
                >
                  Emergency Contact Form
                  <AddIcCallIcon
                    style={{
                      fontSize: 16,
                      marginLeft: 5,
                      color: "#e85348",
                    }}
                  />
                </Fab>
              </div>
            </div>
            <div
              onClick={() => toggleFullScreen(true)}
              style={{
                backgroundImage: `url(${currentSiteAerial})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: 300,
                width: mobile ? "49%" : "100%",
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: 5,
                backgroundColor: "#000000",
                // cursor: !mobile ? "initial" : "pointer",
              }}
            ></div>
          </div>
          <Divider style={{ width: "100%", marginTop: 10, marginBottom: 20 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <StyledTextField
              style={{
                width: 200,
              }}
              inputProps={{ sx: { height: 45 } }}
              variant="outlined"
              select
              value={type}
              label="Type"
              onChange={(event) => {
                setType(event.target.value);
                reset();
              }}
            >
              {getReportingTypes().map((reportType, idx) => (
                <MenuItem
                  style={{ fontFamily: "'Maven Pro', sans-serif" }}
                  key={reportType}
                  value={reportType}
                  disabled={shouldDisableReportType(reportType, idx)}
                >
                  {reportType}
                </MenuItem>
              ))}
            </StyledTextField>
            {type !== "No Report Type" &&
              type !== "Training" &&
              type !== "Hazardous Waste" && (
                <Typography
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    marginLeft: 10,
                  }}
                >
                  Last Done: {activeSite.dataFields[getKey(type)].lastDone}
                </Typography>
              )}
            <Box sx={{ flexGrow: 1 }} />
            {canEdit() && (
              <EditLinkAndNotesDialog
                type={type}
                onSuccess={onSuccess}
                activeSite={activeSite}
                showExternalLink={shouldShowExternalLink()}
                note={getNotes()}
                dbLink={getDbLink()}
              />
            )}
          </div>
          <>
            <Typography
              style={{
                fontFamily: "'Maven Pro', sans-serif",
                fontSize: 17,
                marginTop: 20,
              }}
            >
              Notes
            </Typography>
            <StyledNotes
              style={{
                marginBottom: 10,
                color: "red",
              }}
              fullWidth
              id="outlined-multiline-static"
              multiline
              value={getNotes()}
              disabled
              minRows={4}
              maxRows={8}
            />
          </>
          <div
            style={{
              width: 160,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {getExternalDbLink(type, activeSite.dataFields[getKey(type)])}
          </div>
          <div style={{ marginTop: 10 }}>
            {getStatus(type, getField(), activeSite, onSuccess)}
          </div>

          <Typography
            style={{
              fontFamily: "'Maven Pro', sans-serif",
              fontSize: 17,
              marginTop: 15,
              marginBottom: 5,
            }}
          >
            Helpful Documents
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {canUpload() && (
              <AddHelpfulDocumentDialog
                type={type}
                onSuccess={onSuccess}
                site={activeSite}
                key={getKey(type)}
              />
            )}
            {canDelete() && (
              <ButtonIconButton
                iconOnly={iconOnly}
                label={"Delete"}
                onClick={deleteHelpfulDocument}
                icon={<Delete />}
                tooltip={"Delete Document"}
                color="error"
                disabled={helpfulDocumentsCount !== 1}
                style={{ marginLeft: 5 }}
              />
            )}
            <ButtonIconButton
              iconOnly={iconOnly}
              label={"View"}
              onClick={openHelpfulDocumentLink}
              icon={<Preview />}
              tooltip={"View Document"}
              color="primary"
              disabled={helpfulDocumentsCount !== 1}
              style={{ marginLeft: 5 }}
            />

            <Box sx={{ flexGrow: 1 }} />
            <StyledInput
              mobile={iconOnly} //TODO: has to do with flex/margin
              id="helpfulDocumentsFilter"
              value={helpfulDocumentsFilter}
              onChange={(e) => setHelpfulDocumentsFilter(e.target.value)}
              placeholder="Search..."
            />
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: 200,
              width: "100%",
              marginTop: 5,
            }}
          >
            <AgGridReact
              suppressDragLeaveHidesColumns
              ref={helpfulDocumentsGridRef}
              rowData={helpfulDocumentsGrid()}
              columnDefs={helpfulColumnDefs}
              rowSelection="single"
              defaultColDef={defaultColDef}
              onSelectionChanged={(event) =>
                setHelpfulDocumentsCount(event.api.getSelectedNodes().length)
              }
              onRowClicked={onRowClickedDocument}
              rowDeselection
            />
          </div>

          {/* RECORDS */}
          {type !== "No Report Type" && type !== "Training" && (
            <>
              <Typography
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                  fontSize: 17,
                  marginTop: 15,
                  marginBottom: 5,
                }}
              >
                Records
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {canUpload() && (
                  <AddRecordDialog
                    type={type}
                    onSuccess={onSuccess}
                    site={activeSite}
                  />
                )}
                {canDelete() && (
                  <ButtonIconButton
                    iconOnly={iconOnly}
                    label={"Delete"}
                    onClick={deleteRecord}
                    icon={<Delete />}
                    tooltip={"Delete Record"}
                    color="error"
                    disabled={recordsCount !== 1}
                    style={{ marginLeft: 5 }}
                  />
                )}
                <ButtonIconButton
                  iconOnly={iconOnly}
                  label={"View"}
                  onClick={openRecordLink}
                  icon={<Preview />}
                  tooltip={"View Record"}
                  color="primary"
                  disabled={recordsCount !== 1}
                  style={{ marginLeft: 5 }}
                />
                <ButtonIconButton
                  iconOnly={iconOnly}
                  label={"Download"}
                  onClick={downloadRecords}
                  icon={<Download />}
                  tooltip={"Download Record(s)"}
                  color="warning"
                  disabled={recordsCount <= 0}
                  style={{ marginLeft: 5 }}
                />

                <Box sx={{ flexGrow: 1 }} />
                <StyledInput
                  mobile={iconOnly}
                  id="recordsFilter"
                  value={recordsFilter}
                  onChange={(e) => setRecordsFilter(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              <div
                className="ag-theme-alpine"
                style={{
                  height: 200,
                  width: "100%",
                  marginTop: 5,
                }}
              >
                <AgGridReact
                  suppressDragLeaveHidesColumns
                  ref={recordsGridRef}
                  rowData={recordsGrid()}
                  columnDefs={recordsColumnDefs}
                  rowSelection="multiple"
                  defaultColDef={defaultColDef}
                  onSelectionChanged={(event) => {
                    setRecordsCount(event.api.getSelectedNodes().length);
                    onRowClickedRecord(
                      event.api.getSelectedNodes().map((row) => row.data),
                      event.api
                    );
                  }}
                  rowMultiSelectWithClick
                />
              </div>
            </>
          )}
          {canDelete() && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 70,
              }}
            >
              <DeleteSite site={activeSite} />
            </div>
          )}
        </div>
      ) : (
        <StyledSiteLoader>
          <Loader
            active
            style={{ position: "relative", top: "initial", left: "initial" }}
          >
            Loading
          </Loader>
        </StyledSiteLoader>
      )}
    </div>
  );
};
