import { atom } from "recoil";

export const sites = atom<any[]>({
  key: "sites",
  default: [],
});

export const users = atom<any[]>({
  key: "users",
  default: [],
});

export const sds = atom<any[]>({
  key: "sds",
  default: [],
});

export const requests = atom<any[]>({
  key: "requests",
  default: [],
});

export const globalDocuments = atom<any[]>({
  key: "globalDocuments",
  default: [],
});

export const contacts = atom<any[]>({
  key: "contacts",
  default: [],
});

export const contactsPdfs = atom<any[]>({
  key: "contactsPdfs",
  default: [],
});

export const records = atom<any[]>({
  key: "records",
  default: [],
});

export const activeSite = atom<any>({
  key: "activeSite",
  default: null,
});

export const user = atom<any>({
  key: "user",
  default: null,
});
