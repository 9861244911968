import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "react-datepicker/dist/react-datepicker.css";

// import { DeleteSiteFn } from "../backends/DeleteSiteFn";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FadeIn from "react-fade-in";
import { DeleteSiteFn } from "../../../backends/commands/DeleteSiteFn";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const DeleteSite = (props: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);

  const handleSave = async () => {
    setLoading(true);

    let test = await DeleteSiteFn(props.site);

    if (test === false) {
      setError(true);
      setLoading(false);
    } else {
      navigate("/");
      setOpen(false);
      setError(false);
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#ffffff",
          color: "red",
          marginTop: -25,
          marginBottom: 25,
          width: 175,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        onClick={handleClickOpen}
      >
        Delete Site
        <RemoveCircleOutlineIcon style={{ fontSize: 15, marginLeft: 10 }} />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            position: "fixed",
            width: "70%",
            height: "auto",
            maxHeight: "80%",
            left: "50%",
            top: "50%",
            overflow: "hidden",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderTopLeftRadius: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            maxWidth: 1800,
          }}
        >
          <IconButton
            disabled={loading}
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          {/* BOUNDARY INSIDE */}
          <FadeIn>
            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              <Typography style={{ textAlign: "center", marginTop: 25 }}>
                <b>Are you sure you want to delete this site?</b>
              </Typography>

              {loading ? (
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 20,
                  }}
                >
                  <CircularProgress style={{ color: "black" }} size={20} />
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    disabled={loading}
                    variant="contained"
                    style={{
                      marginTop: 30,
                      backgroundColor: "#ffffff",
                      color: "#000",
                    }}
                    onClick={handleClose}
                  >
                    cancel
                  </Button>
                  <Button
                    disabled={loading}
                    variant="contained"
                    style={{
                      marginTop: 30,
                      backgroundColor: "#ffffff",
                      color: "#000",
                    }}
                    onClick={() => handleSave()}
                  >
                    yes
                  </Button>
                </div>
              )}
              {error && (
                <Typography
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  *Please contact the Developer. Error when deleting site.
                </Typography>
              )}
            </div>
          </FadeIn>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteSite;
