import styled from "@emotion/styled";
import { StyledPageWrapperProps } from "./PageWrapper.types";

export const StyledPageWrapper = styled.div<StyledPageWrapperProps>`
  width: ${(props) => (props.hidePadding ? "100%" : "calc(100% - 20px)")};
  height: calc(100vh - 45px);
  padding: ${(props) => (props.hidePadding ? "0px" : "10px")};
  margin: auto;
  overflow-y: ${(props) => (props.hideScrolling ? "hidden" : "auto")}; ;
`;
