import { supabase } from "../../supabase";

export const UpdateSdsArchiveFn = async (value: boolean, docId: number) => {
  return new Promise(async function (resolve, reject) {
    let update = await supabase
      .from("sds")
      .update({
        archived: value,
      })
      .eq("id", docId);

    return resolve(update);
  });
};
