import { useState } from "react";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import AlertToaster from "../../components/alert/Alert";
import {
  StyledButtonWrapper,
  StyledFlex,
  StyledFullScreenImageWrapper,
  StyledHeader,
  StyledImage,
  StyledImageMain,
  StyledImageWrapper,
  StyledInput,
  StyledParagraph,
  StyledPinWrapper,
  StyledSecondary,
  StyledSecondaryFlex,
  StyledSmallHeader,
  StyledTabs,
  StyledTextField,
} from "./EmergencyResponse.styles";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Tab,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { LEADERSHIP, TAB } from "./EmergencyResponse.constants";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import { STATES } from "../../SharedConstants";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { UploadContactDialog } from "./uploadDialogs/UploadContactDialog";
import FadeIn from "react-fade-in";
import { getStateImage } from "./EmergencyResponse.utils";
import { TabPanel } from "./components/TabPanel";
import { ContactsGrid } from "./components/ContactsGrid";
import { PdfGrid } from "./components/PdfGrid";
import { useContacts } from "../../hooks/useContacts";
import { useContactsPdfs } from "../../hooks/useContactsPdfs";
import { LoadingIndicator } from "../../components/loadingIndicator/LoadingIndicator";
import { UploadPdfDialog } from "./uploadDialogs/UploadPdfDialog";
import { ButtonIconButton } from "../../components/button/ButtonIconButton";
import { DeleteContactFn } from "../../backends/commands/DeleteContactFn";
import { DownloadContactPdfFn } from "../../backends/commands/DownloadContactPdfFn";
import { DeleteContactPdfFn } from "../../backends/commands/DeleteContactPdfFn";
import { PinDrop } from "@mui/icons-material";

export const EmergencyResponse = () => {
  const { contacts, contactsLoading, refreshContacts } = useContacts();
  const { contactsPdfs, contactsPdfsloading, refreshContactsPdfs } =
    useContactsPdfs();

  const [state, setState] = useState<string>("Alaska");
  const mobile = useMediaQuery("(min-width: 991px)");
  const iconOnly = useMediaQuery("(min-width: 619px)");
  const flexSearch = useMediaQuery("(min-width: 489px)");
  const [successAlert, setSuccessAlert] = useState(false);
  const [showFullScreenImage, setFullScreenImage] = useState(false);
  const [value, setValue] = useState(TAB.CONTACTS);
  const [filter, setFilter] = useState<string>();
  const [activeRowCount, setActiveRowCount] = useState(0);
  const [activeRow, setActiveRow] = useState<any>();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    resetActive();
    setFilter("");
  };

  const resetActive = () => {
    setActiveRowCount(0);
    setActiveRow(undefined);
  };

  const onSuccess = () => {
    setSuccessAlert(true);
    refreshContacts();
    refreshContactsPdfs();
    setTimeout(function () {
      setSuccessAlert(false);
    }, 3000);
  };

  const deleteContact = async () => {
    if (!activeRow) {
      return;
    }

    const response: any = await DeleteContactFn(activeRow);

    if (response.error === null) {
      onSuccess();
      resetActive();
    }
  };

  const deleteContactPdf = async () => {
    if (!activeRow) {
      return;
    }

    const response: any = await DeleteContactPdfFn(activeRow);

    if (response.error === null) {
      onSuccess();
      resetActive();
    }
  };

  const viewPdf = () => {
    if (!activeRow) {
      return;
    }
    const windowReference = window.open();

    DownloadContactPdfFn(activeRow.path).then((res: any) => {
      if (windowReference) {
        windowReference.location = res.signedURL;
      }
    });
  };

  return (
    <PageWrapper>
      <>
        {successAlert ? (
          <AlertToaster message="Success" severity="success" />
        ) : null}
      </>
      <StyledFlex mobile={mobile}>
        <StyledParagraph>
          <StyledHeader>Emergency Response</StyledHeader>
          <StyledSmallHeader>Corporate Office</StyledSmallHeader>
          <StyledSecondaryFlex>
            <ApartmentIcon />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://maps.google.com/?q=355 Goodpasture Island Road, Eugene, OR"
            >
              <StyledSecondary>
                355 Goodpasture Island Rd STE 300 Eugene, OR 97401
              </StyledSecondary>
            </a>
          </StyledSecondaryFlex>
          <StyledSecondaryFlex>
            <LocalPhoneIcon />
            <a href="tel:541-683-5073" target="_blank" rel="noreferrer">
              <StyledSecondary>541-683-5073</StyledSecondary>
            </a>
          </StyledSecondaryFlex>
          <StyledSecondaryFlex lastItem>
            <MailIcon />
            <StyledSecondary>P.O. Box 10527 Eugene, OR 97440</StyledSecondary>
          </StyledSecondaryFlex>

          {LEADERSHIP.map((leader) => (
            <StyledSecondary showMargin>
              <b>{leader.position}</b> - {leader.name} -{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${leader.email}`}
              >
                {leader.email}
              </a>
            </StyledSecondary>
          ))}
        </StyledParagraph>
        <StyledImage
          onClick={() => mobile && setFullScreenImage(true)}
          mobile={mobile}
          src={getStateImage(state)}
          alt="state_image"
        />
      </StyledFlex>
      <>
        {showFullScreenImage ? (
          <StyledFullScreenImageWrapper
            onClick={() => setFullScreenImage(false)}
          >
            <StyledImageWrapper>
              <FadeIn>
                <StyledImageMain alt="state_image" src={getStateImage(state)} />
              </FadeIn>
            </StyledImageWrapper>
          </StyledFullScreenImageWrapper>
        ) : null}
      </>
      <StyledPinWrapper>
        <Box style={{ flexGrow: 1 }} />
        <Button
          startIcon={<PinDrop />}
          variant="outlined"
          style={{
            color: "black",
            borderColor: "black",
            fontFamily: "'Maven Pro', sans-serif",
            marginLeft: 5,
            marginRight: 5,
            width: mobile ? "auto" : "100%",
          }}
          target="_blank"
          href="https://www.google.com/maps/d/edit?mid=12R-1l6bSA0ZaJnTyDYW9ZV5R_NfE9sw&usp=sharing"
          rel="noreferrer"
        >
          Open Google Map Pins
        </Button>
      </StyledPinWrapper>

      <Divider style={{ marginBottom: 30 }} />
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <StyledTextField
          style={{
            width: 185,
          }}
          inputProps={{ sx: { height: 36 } }}
          variant="outlined"
          select
          value={state}
          label="State"
          onChange={(event: any) => {
            setState(event.target.value);
            resetActive();
          }}
        >
          {STATES.filter((state) => state !== "None").map((state) => (
            <MenuItem
              style={{ fontFamily: "'Maven Pro', sans-serif" }}
              key={state}
              value={state}
            >
              {state}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledSmallHeader
          style={{
            marginLeft: 15,
            marginTop: 0,
            marginBottom: 0,
            marginRight: 10,
          }}
        >
          {state}
        </StyledSmallHeader>
        <Box sx={{ flexGrow: 1 }} />
        <StyledInput
          mobile={flexSearch}
          id="quickFilter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Search..."
          inputProps={{
            "aria-label": "search google maps",
          }}
        />
      </div>

      {contactsLoading || contactsPdfsloading ? (
        <Box
          sx={{
            width: "100%",
            marginTop: "120px",
            position: "relative",
            marginBottom: "110px",
            padding: "29px",
          }}
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: 2 }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              width: "100%",
            }}
          >
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "black",
                  overflow: "unset !important",
                },
              }}
            >
              <Tab label="Contacts" style={{ color: "black" }} />
              <Tab label="PDFs" style={{ color: "black" }} />
            </StyledTabs>
            <StyledButtonWrapper>
              {value === TAB.CONTACTS ? (
                <UploadContactDialog
                  onSuccess={() => refreshContacts()}
                  state={state}
                />
              ) : (
                <UploadPdfDialog
                  onSuccess={() => refreshContactsPdfs()}
                  state={state}
                />
              )}

              {/* EDIT BUTTON */}
              {value === TAB.CONTACTS ? (
                <UploadContactDialog
                  isEdit
                  onSuccess={() => refreshContacts()}
                  state={state}
                  contact={activeRow}
                  disabled={activeRowCount !== 1}
                />
              ) : (
                <ButtonIconButton
                  iconOnly={iconOnly}
                  label={"View"}
                  onClick={viewPdf}
                  icon={<PreviewIcon />}
                  tooltip={"View PDF"}
                  color="primary"
                  disabled={activeRowCount !== 1}
                />
              )}

              {/* DELETE BUTTON */}
              {value === TAB.CONTACTS ? (
                <ButtonIconButton
                  iconOnly={iconOnly}
                  label={"Delete"}
                  onClick={deleteContact}
                  icon={<DeleteIcon />}
                  tooltip={"Delete Contact"}
                  color="error"
                  disabled={activeRowCount !== 1}
                />
              ) : (
                <ButtonIconButton
                  iconOnly={iconOnly}
                  label={"Delete"}
                  onClick={deleteContactPdf}
                  icon={<DeleteIcon />}
                  tooltip={"Delete PDF"}
                  color="error"
                  disabled={activeRowCount !== 1}
                />
              )}
            </StyledButtonWrapper>
          </Box>
          <TabPanel value={value} index={TAB.CONTACTS}>
            <ContactsGrid
              state={state}
              filter={filter}
              activeRowCount={(count) => setActiveRowCount(count)}
              activeRow={(row) => setActiveRow(row)}
              contacts={contacts}
            />
          </TabPanel>
          <TabPanel value={value} index={TAB.PDFS}>
            <PdfGrid
              state={state}
              filter={filter}
              activeRowCount={(count) => setActiveRowCount(count)}
              activeRow={(row) => setActiveRow(row)}
              contactsPdfs={contactsPdfs}
            />
          </TabPanel>
        </Box>
      )}
    </PageWrapper>
  );
};
