import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { requests } from "../store/Store";
import { useEffect } from "react";
import { GetRequestsFn } from "../backends/queries/GetRequestsFn";

export const useRequests = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery(
    "requests",
    GetRequestsFn
  );
  const [requestsList, setRequestsList] = useRecoilState(requests);

  useEffect(() => {
    isSuccess && data.data && setRequestsList(data.data);
  }, [data?.data, isSuccess, setRequestsList]);

  return {
    requests: requestsList,
    refreshRequests: refetch,
    loading: isLoading,
  };
};
