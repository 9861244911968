import FadeIn from "react-fade-in";
import { FullPageLoadingIndicator } from "../loadingIndicator/FullPageLoadingIndicator";
import Navbar from "../navbar/Navbar";
import { StyledPageWrapper } from "./PageWrapper.styles";
import { PageWrapperProps } from "./PageWrapper.types";

export const PageWrapper = ({
  children,
  hidePadding,
  style,
  loading,
  hideScrolling,
}: PageWrapperProps) => {
  return (
    <>
      <Navbar />
      {loading ? (
        <FullPageLoadingIndicator />
      ) : (
        <div style={style}>
          <StyledPageWrapper
            hidePadding={hidePadding}
            hideScrolling={hideScrolling}
          >
            <FadeIn>{children}</FadeIn>
          </StyledPageWrapper>
        </div>
      )}
    </>
  );
};
