import styled from "@emotion/styled/macro";
import { Card, Fab, TextField } from "@mui/material";
import { Loader } from "semantic-ui-react";

interface StyledCardProps {
  lastItem?: boolean;
  activeSite?: boolean;
}

export const StyledFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

export const StyledCardRightDiv = styled.div<StyledCardProps>`
  height: 90px;
  flex-grow: 1;
  padding: 10px;
  background-color: ${(props) => (props.activeSite ? "#000" : "#fff")};
`;

export const StyledCard = styled(Card)<StyledCardProps>`
  margin-bottom: ${(props) => (props.lastItem ? "0" : "8px")};
  display: flex;
  cursor: pointer;

  &:hover {
    ${StyledCardRightDiv} {
      background-color: ${(props) => (props.activeSite ? "#000" : "#e3e3e3")};
    }
  }
`;

export const SiteWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const ButtonPosition = styled.div`
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.9);
`;

export const ImageWrapper = styled.div`
  position: fixed;
  width: 80%;
  left: 50%;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
`;

export const StyledChangeSiteFab = styled(Fab)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

export const StyledSiteLoader = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledNotes = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Maven Pro", sans-serif;

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgb(0, 0, 0, 1);
    font-family: "Maven Pro", sans-serif;
    font-size: 14px;
  }
`;
