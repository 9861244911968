import { CheckCircleOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { TagDialog } from "./TagDialog";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

export const TagRenderer = (props: any) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {props?.value?.length ? (
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          size="small"
          style={{
            color: "black",
            borderColor: "black",
            fontFamily: "'Maven Pro', sans-serif",
          }}
        >
          View Tags
        </Button>
      ) : null}
      <TagDialog open={open} onClose={handleClose} value={props.value} />
    </>
  );
};

export const AddressRenderer = (props: any) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://maps.google.com/?q=${props.value}`}
      style={{ fontFamily: "'Maven Pro', sans-serif" }}
    >
      {props.value}
    </a>
  );
};

export const EmailRenderer = (props: any) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`mailto:${props.value}`}
      style={{ fontFamily: "'Maven Pro', sans-serif" }}
    >
      {props.value}
    </a>
  );
};

export const CellRenderer = (props: any) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`tel:${props.value}`}
      style={{ fontFamily: "'Maven Pro', sans-serif" }}
    >
      {props.value}
    </a>
  );
};

export const AlwaysAvailableRenderer = (props: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {props.value ? <CheckCircleOutline color="success" /> : null}
    </div>
  );
};

export const WebsiteRenderer = (props: any) => {
  return (
    <>
      {props.value ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={`${props.value}`}
          style={{ fontFamily: "'Maven Pro', sans-serif" }}
        >
          {props.value}
        </a>
      ) : null}
    </>
  );
};
