import {
  Badge,
  Box,
  Button,
  CardActions,
  CircularProgress,
  ClickAwayListener,
  debounce,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Popper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { RestartAlt } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { useSites } from "../../hooks/useSites";
import {
  StyledBorder,
  StyledBox,
  StyledCloseIcon,
  StyledDivider,
  StyledDoneIcon,
  StyledEmptyTitle,
  StyledEmptyWrapper,
  StyledHeader,
  StyledInnerBorder,
  StyledInnerTableWrapper,
  StyledInput,
  StyledLoader,
  StyledOuterTableWrapper,
  StyledTd,
  StyledWrapper,
} from "./ComplianceOverview.styles";
import "./ComplianceOverview.css";
import { OPERATING_COMPANIES, STATES } from "../../SharedConstants";
import { HEADERS } from "./ComplianceOverview.constants";
import { Link } from "react-router-dom";
import { States, StyledSelect } from "../facilities/SiteList";
import ReplayIcon from "@mui/icons-material/Replay";

export const ComplianceOverview = () => {
  const [stateFilter, setStateFilter] = useState("None");
  const [ocFilter, setOcFilter] = useState("None");
  const [searchString, setSearchString] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const { sites, loading } = useSites();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  // const [filterString, setFilterString] = useState<string>("");

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const resetFilters = () => {
    setStateFilter("None");
    setOcFilter("None");
    setSearchString("");
    const input = document.getElementById("filterSiteText") as HTMLInputElement;
    input.value = "";
  };

  const debouncedSearch = useRef(
    debounce(async (criteria) => {
      setSearchString(criteria);
      setShowSpinner(false);
    }, 1000)
  ).current;

  async function debounceString(e: any) {
    setShowSpinner(true);
    debouncedSearch(e.target.value);
  }

  const getStatus = (status: any, applicable: any) => {
    return !applicable ? null : status === "Done" ? (
      <StyledDoneIcon />
    ) : (
      <StyledCloseIcon />
    );
  };

  const showBadge = () => {
    return stateFilter === "None" && ocFilter === "None";
  };

  const getSites = () => {
    let filteredSites = sites
      ? sites?.filter(
          (site) =>
            site?.siteNumber
              ?.toLowerCase()
              .includes(searchString.toLowerCase()) ||
            site?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
            site?.city?.toLowerCase().includes(searchString.toLowerCase()) ||
            site?.state?.toLowerCase().includes(searchString.toLowerCase())
        )
      : [];

    if (!filteredSites.length) {
      return [];
    }

    if (stateFilter !== "None") {
      filteredSites = filteredSites.filter((e) =>
        e.state.includes(stateFilter)
      );
    }

    if (ocFilter !== "None") {
      filteredSites = filteredSites.filter((e) =>
        e.oCompany.includes(ocFilter)
      );
    }

    return filteredSites;
  };

  const sortFunction = (a: any, b: any) => {
    if (a.state < b.state) {
      return -1;
    } else if (a.state > b.state) {
      return 1;
    } else if (a.name.includes("NoCal")) {
      return -1;
    } else if (b.name.includes("SoCal")) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <PageWrapper hidePadding loading={loading}>
      <StyledWrapper>
        {sites.length > 0 ? (
          <StyledBorder>
            <StyledInnerBorder>
              <Tooltip title="Filters">
                <IconButton
                  sx={{ p: "10px" }}
                  aria-label="menu"
                  onClick={handleClick}
                  size="large"
                >
                  <Badge color="warning" variant="dot" invisible={showBadge()}>
                    <FilterListIcon style={{ color: "#000" }} />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Popover
                id={id}
                disablePortal
                open={open}
                anchorEl={anchorEl}
                style={{ zIndex: 1500 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: 0,
                }}
              >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                  <Box
                    style={{
                      backgroundColor: "#fff",
                      width: 300,
                      padding: 10,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      height: 150,
                      boxShadow: "0 10px 8px 0 rgba(0,0,0,0.2)",
                    }}
                  >
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        State
                      </InputLabel>

                      <StyledSelect
                        MenuProps={{
                          style: { width: "290px", minWidth: "0px" },
                          disablePortal: true,
                          anchorReference: "anchorPosition",
                          anchorPosition: { top: 63, left: -10 },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stateFilter}
                        label="State"
                        onChange={(e: any) => setStateFilter(e.target.value)}
                      >
                        {States.map((state) => (
                          <MenuItem
                            key={state}
                            value={state}
                            style={{ fontFamily: "'Maven Pro', sans-serif" }}
                          >
                            {state}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      style={{ marginTop: 20 }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        Operating Company
                      </InputLabel>
                      <StyledSelect
                        MenuProps={{
                          style: { width: "290px", minWidth: "0px" },
                          disablePortal: true,
                          anchorReference: "anchorPosition",
                          anchorPosition: { top: 142, left: 0 },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ocFilter}
                        label="Operating Company"
                        onChange={(e: any) => setOcFilter(e.target.value)}
                      >
                        {OPERATING_COMPANIES.map((oc) => (
                          <MenuItem
                            key={oc}
                            value={oc}
                            style={{ fontFamily: "'Maven Pro', sans-serif" }}
                          >
                            {oc}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                  </Box>
                </ClickAwayListener>
              </Popover>
              <InputBase
                id="filterSiteText"
                onChange={debounceString}
                style={{
                  color: "#000",
                  flexGrow: 1,
                  fontFamily: "'Maven Pro', sans-serif",
                }}
                placeholder="Search..."
                inputProps={{ "aria-label": "search google maps" }}
              />
              <Divider
                orientation="vertical"
                style={{
                  backgroundColor: "#000",
                  color: "#000",
                  width: 1,
                  height: 31,
                  marginTop: 8,
                }}
              />
              <Tooltip title="Reset Filters">
                <IconButton
                  type="submit"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => resetFilters()}
                  size="large"
                >
                  <ReplayIcon style={{ color: "#000" }} />
                </IconButton>
              </Tooltip>
            </StyledInnerBorder>

            <StyledOuterTableWrapper>
              <div style={{ minWidth: 2154 }}>
                <StyledInnerTableWrapper>
                  <table>
                    <tr>
                      {HEADERS.map((header) => (
                        <StyledHeader key={header}>{header}</StyledHeader>
                      ))}
                    </tr>

                    {showSpinner ? (
                      <StyledLoader color="inherit" size={30} />
                    ) : (
                      <>
                        {getSites()
                          .sort(sortFunction)
                          .map((site: any, index) => (
                            <tr key={`info-${index}`}>
                              <StyledTd>
                                <CardActions
                                  style={{
                                    marginTop: "auto",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Link
                                    to={`/facilities?site=${site.siteNumber}`}
                                    style={{
                                      color: "#000000",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Button size="small">
                                      Navigate To Site
                                    </Button>
                                  </Link>
                                </CardActions>
                              </StyledTd>
                              <StyledTd>{site.name}</StyledTd>

                              <StyledTd>{site.state}</StyledTd>
                              <StyledTd>{site.siteNumber}</StyledTd>
                              <td>
                                {getStatus(
                                  site.dataFields[0].status,
                                  site.dataFields[0].applicable
                                )}
                              </td>
                              <td>
                                {getStatus(
                                  site.dataFields[1].status,
                                  site.dataFields[1].applicable
                                )}
                              </td>
                              <td>
                                {getStatus(
                                  site.dataFields[2].quarters[0].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].quarters[1].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].quarters[2].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].quarters[3].status,
                                  site.dataFields[2].applicable
                                )}
                              </td>
                              <td>
                                {getStatus(
                                  site.dataFields[2].months[0].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[1].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[2].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[3].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[4].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[5].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[6].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[7].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[8].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[9].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[10].status,
                                  site.dataFields[2].applicable
                                )}
                                {getStatus(
                                  site.dataFields[2].months[11].status,
                                  site.dataFields[2].applicable
                                )}
                              </td>
                              <td>
                                {getStatus(
                                  site.dataFields[3].status,
                                  site.dataFields[3].applicable
                                )}
                              </td>
                              {site.dataFields[3].type !== "Yearly" ? (
                                <>
                                  <td>
                                    {getStatus(
                                      site.dataFields[3].quarters.status,
                                      site.dataFields[3].applicable
                                    )}

                                    {getStatus(
                                      site.dataFields[3].quarters.status,
                                      site.dataFields[3].applicable
                                    )}

                                    {getStatus(
                                      site.dataFields[3].quarters.status,
                                      site.dataFields[3].applicable
                                    )}

                                    {getStatus(
                                      site.dataFields[3].quarters.status,
                                      site.dataFields[3].applicable
                                    )}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>- - - - </td>
                                </>
                              )}

                              <td>
                                {getStatus(
                                  site.dataFields[4].status,
                                  site.dataFields[4].applicable
                                )}
                              </td>
                              <td>
                                {getStatus(
                                  site.dataFields[5].status,
                                  site.dataFields[5].applicable
                                )}
                              </td>
                              <td>
                                {getStatus(
                                  site.dataFields[6].months[0].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[1].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[2].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[3].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[4].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[5].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[6].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[7].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[8].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[9].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[10].status,
                                  site.dataFields[6].applicable
                                )}
                                {getStatus(
                                  site.dataFields[6].months[11].status,
                                  site.dataFields[6].applicable
                                )}
                              </td>
                              {site.state === "California" ? (
                                <td>
                                  {getStatus(
                                    site.hazardousWaste.months[0].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[1].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[2].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[3].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[4].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[5].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[6].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[7].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[8].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[9].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[10].status,
                                    true
                                  )}
                                  {getStatus(
                                    site.hazardousWaste.months[11].status,
                                    true
                                  )}
                                </td>
                              ) : (
                                <>
                                  <td>- - - - </td>
                                </>
                              )}
                            </tr>
                          ))}
                      </>
                    )}
                  </table>
                </StyledInnerTableWrapper>
              </div>
            </StyledOuterTableWrapper>
          </StyledBorder>
        ) : (
          <StyledEmptyWrapper>
            <StyledEmptyTitle>No Sites To Display</StyledEmptyTitle>
            <StyledEmptyTitle>Go home to add a new site</StyledEmptyTitle>
          </StyledEmptyWrapper>
        )}
      </StyledWrapper>
    </PageWrapper>
  );
};
