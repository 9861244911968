export enum DATAFIELD_INDEX {
  TIER_II,
  WASTE_MANAGEMENT,
  STORMWATER,
  WASTEWATER,
  AIR,
  FIRE_BACKFLOW,
  SPCC,
  NO_REPORT_TYPE,
}

export const DefaultDataFields = [
  {
    name: "Tier II",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    externalDB: "https://www.google.com/",
    note: "Notes",
    applicable: true,
  },
  {
    name: "Waste Management",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    externalDB: "https://www.google.com/",
    note: "Notes",
    applicable: true,
  },
  {
    name: "Stormwater",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    externalDB: "https://www.google.com/",
    note: "Notes",
    applicable: true,
    documents: [],
    quarters: [
      {
        name: "Q1",
        status: "Not Done",
      },
      {
        name: "Q2",
        status: "Not Done",
      },
      {
        name: "Q3",
        status: "Not Done",
      },
      {
        name: "Q4",
        status: "Not Done",
      },
    ],
    months: [
      {
        month: "January",
        status: "Not Done",
      },
      {
        month: "February",
        status: "Not Done",
      },
      {
        month: "March",
        status: "Not Done",
      },
      {
        month: "April",
        status: "Not Done",
      },
      {
        month: "May",
        status: "Not Done",
      },
      {
        month: "June",
        status: "Not Done",
      },
      {
        month: "July",
        status: "Not Done",
      },
      {
        month: "August",
        status: "Not Done",
      },
      {
        month: "September",
        status: "Not Done",
      },
      {
        month: "October",
        status: "Not Done",
      },
      {
        month: "November",
        status: "Not Done",
      },
      {
        month: "December",
        status: "Not Done",
      },
    ],
  },
  {
    name: "Wastewater",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    externalDB: "https://www.google.com/",
    note: "Notes",
    applicable: true,
    documents: [],
    quarters: [
      {
        name: "Q1",
        status: "Not Done",
      },
      {
        name: "Q2",
        status: "Not Done",
      },
      {
        name: "Q3",
        status: "Not Done",
      },
      {
        name: "Q4",
        status: "Not Done",
      },
    ],
    type: "Both",
  },
  {
    name: "Air",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    applicable: true,
    documents: [],
    note: "Notes",
  },
  {
    name: "Fire/Backflow",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    applicable: true,
    documents: [],
    note: "Notes",
  },
  {
    name: "SPCC",
    lastDone: undefined,
    jsDate: new Date(),
    status: "Not Done",
    applicable: true,
    documents: [],
    note: "Notes",
    months: [
      {
        month: "January",
        status: "Not Done",
      },
      {
        month: "February",
        status: "Not Done",
      },
      {
        month: "March",
        status: "Not Done",
      },
      {
        month: "April",
        status: "Not Done",
      },
      {
        month: "May",
        status: "Not Done",
      },
      {
        month: "June",
        status: "Not Done",
      },
      {
        month: "July",
        status: "Not Done",
      },
      {
        month: "August",
        status: "Not Done",
      },
      {
        month: "September",
        status: "Not Done",
      },
      {
        month: "October",
        status: "Not Done",
      },
      {
        month: "November",
        status: "Not Done",
      },
      {
        month: "December",
        status: "Not Done",
      },
    ],
  },
  {
    name: "No Report Type",
    lastDone: "",
    jsDate: new Date(),
    applicable: true,
    documents: [],
    note: "Notes",
  },
];

export const DefaultHazardousWaste = {
  name: "Hazardous Waste",
  jsDate: new Date(),
  status: "Not Done",
  applicable: true,
  note: "Notes",
  months: [
    {
      month: "January",
      status: "Not Done",
    },
    {
      month: "February",
      status: "Not Done",
    },
    {
      month: "March",
      status: "Not Done",
    },
    {
      month: "April",
      status: "Not Done",
    },
    {
      month: "May",
      status: "Not Done",
    },
    {
      month: "June",
      status: "Not Done",
    },
    {
      month: "July",
      status: "Not Done",
    },
    {
      month: "August",
      status: "Not Done",
    },
    {
      month: "September",
      status: "Not Done",
    },
    {
      month: "October",
      status: "Not Done",
    },
    {
      month: "November",
      status: "Not Done",
    },
    {
      month: "December",
      status: "Not Done",
    },
  ],
};
