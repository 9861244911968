import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { useSites } from "../../hooks/useSites";
import { SiteList } from "./SiteList";
import { ActiveSite } from "./ActiveSite";
import { StyledFlex } from "./Facilities.styles";
import { AddEditSiteDialog } from "./components/AddEditSiteDialog";
import { useRecords } from "../../hooks/useRecords";

export const Facilities = () => {
  const [activeSite, setActiveSite] = useState();
  const { sites, refreshSites } = useSites();
  const { records, refreshRecords } = useRecords();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const found = searchParams.get("site");
    if (!!found) {
      const activeSite = sites.find((site) => site.siteNumber === found);

      if (activeSite) {
        setActiveSite(activeSite);
      } else {
        if (sites.length) {
          setActiveSite(sites[0]);
          setSearchParams({ site: sites[0].siteNumber });
        }
      }
    } else {
      if (sites.length) {
        setActiveSite(sites[0]);
        setSearchParams({ site: sites[0].siteNumber });
      }
    }
  }, [searchParams, setSearchParams, sites]);

  const refresh = () => {
    refreshSites();
    refreshRecords();
  };

  return (
    <PageWrapper hidePadding hideScrolling>
      <StyledFlex>
        <SiteList
          sites={sites}
          setActiveSite={setActiveSite}
          setSearchParams={setSearchParams}
          scrollActiveSite={activeSite}
          activeSite={activeSite}
        />
        <ActiveSite
          activeSite={activeSite}
          siteCount={sites.length}
          refresh={refresh}
          records={records}
        />
      </StyledFlex>
      <AddEditSiteDialog type="ADD" onSuccess={() => refresh()} />
    </PageWrapper>
  );
};
