import { Architecture } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledChangeSiteFab } from "../Facilities.styles";

interface SiteDrawerProps {
  children: any;
  scrollActiveSite?: any;
}

export const SitesDrawer = ({
  children,
  scrollActiveSite,
}: SiteDrawerProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setOpenDrawer(false);
  }, [scrollActiveSite]);

  return (
    <>
      <StyledChangeSiteFab
        size="medium"
        variant="extended"
        onClick={() => setOpenDrawer(true)}
      >
        <Architecture />
        Change Site
      </StyledChangeSiteFab>
      <Drawer
        PaperProps={{
          sx: { maxWidth: "94%" },
        }}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {children}
      </Drawer>
    </>
  );
};
