import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { GetSitesFn } from "../backends/queries/GetSitesFn";
import { sites } from "../store/Store";
import { useEffect } from "react";

export const useSites = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery("sites", GetSitesFn);

  const [siteList, setSites] = useRecoilState(sites);

  useEffect(() => {
    isSuccess && data.data && setSites(data.data);
  }, [data?.data, isSuccess, setSites]);

  return { sites: siteList, refreshSites: refetch, loading: isLoading };
};
