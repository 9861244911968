import { supabase } from "../../supabase";

export async function DeleteUserFn(auth_id: string) {
  return new Promise(async function (resolve, reject) {
    // Delete User from Auth
    const deletedAuth = await supabase.auth.api.deleteUser(auth_id);

    if (deletedAuth.error !== null) {
      return resolve(false);
    }

    // Delete User Record
    const deleteRow = await supabase
      .from("users")
      .delete()
      .match({ auth_id: auth_id });

    if (deleteRow.error !== null) {
      return resolve(false);
    }

    return resolve(true);
  });
}
