import { supabase } from "../../supabase";

export async function EditSiteFn(
  data: any,
  existingContactFormLink: any,
  existingWasteFormLink: any
) {
  //Emergency Form Change
  if (data.contactFormLink) {
    var newEmergencyURL = await uploadPhotoEmergency(
      data,
      existingContactFormLink
    );
  }

  //Main Image Change
  if (data.image) {
    await uploadPhotoAerial(data);
  }

  //Waste Form Change
  if (data.wasteFormLink) {
    var newWasteURL = await uploadPhotoWaste(data, existingWasteFormLink);
  }

  delete data.picture;

  return new Promise(async function (resolve, reject) {
    let newNics = data.nics;
    let newSic = data.sic;
    let name = data.name;
    let oCompany = data.oCompany;
    let address = data.address;
    let city = data.city;
    let state = data.state;
    let zip = data.zip;
    let dataFields = data.dataFields;

    let test = await supabase
      .from("sites")
      .update({
        nics: newNics,
        sic: newSic,
        zip: zip,
        state: state,
        city: city,
        name: name,
        address: address,
        oCompany: oCompany,
        dataFields: dataFields,
        contactFormLink: data.contactFormLink
          ? newEmergencyURL
          : existingContactFormLink,
        wasteFormLink: data.wasteFormLink ? newWasteURL : existingWasteFormLink,
      })
      .eq("siteNumber", data.siteNumber);

    if (test.error === null) {
      return resolve(true);
    } else {
      return resolve(false);
    }
  });
}

export async function uploadPhotoAerial(info: any) {
  await supabase.storage
    .from("sites")
    .update(`${info.siteNumber}/aerial`, info.image[0]);
}

export async function uploadPhotoEmergency(
  info: any,
  existingContactFormLink: any
) {
  // Check if existing file upload
  if (existingContactFormLink) {
    await supabase.storage
      .from("sites")
      .update(`${info.siteNumber}/contactForm`, info.contactFormLink, {
        cacheControl: "3600",
        upsert: false,
      });
  } else {
    await supabase.storage
      .from("sites")
      .upload(`${info.siteNumber}/contactForm`, info.contactFormLink);
  }

  return `${info.siteNumber}/contactForm`;
}

export async function uploadPhotoWaste(info: any, existingWasteFormLink: any) {
  // Check if existing file upload
  if (existingWasteFormLink) {
    await supabase.storage
      .from("sites")
      .update(`${info.siteNumber}/wasteForm`, info.wasteFormLink, {
        cacheControl: "3600",
        upsert: false,
      });
  } else {
    await supabase.storage
      .from("sites")
      .upload(`${info.siteNumber}/wasteForm`, info.wasteFormLink);
  }

  return `${info.siteNumber}/wasteForm`;
}
