import styled from "@emotion/styled/macro";
import { Autocomplete, TextField } from "@mui/material";

export const StyledGridWrapper = styled.div`
  min-height: 355px;
  width: 100%;
  height: calc(100vh - 200px);
`;

export const StyledAutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 2px;
  }
`;

export const StyledTextField = styled(TextField)({
  ".css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root": {
    padding: 0,
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    height: "36px",
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});
