import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import styled from "@emotion/styled";
import {
  CircularProgress,
  Divider,
  InputBase,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

export const StyledCloseIcon = styled(CloseIcon)`
  color: red;
  font-size: 15px;
  margin-left: 2px;
  margin-right: 3px;
`;

export const StyledDoneIcon = styled(DoneIcon)`
  color: green;
  font-size: 15px;
  margin-left: 2px;
  margin-right: 3px;
`;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledEmptyWrapper = styled.div`
  width: 100%;
  background-color: white;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
`;

export const StyledEmptyTitle = styled(Typography)`
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: "Maven Pro", sans-serif;
`;

export const StyledBorder = styled.div`
  border-right: 0px;
  width: 100%;
`;

export const StyledInnerBorder = styled.div`
  width: 100%;
  margin-top: 0px;
  border-bottom: 1px solid #e8e8e8;
  color: #000;
  display: flex;
  justify-content: space-between;
`;

export const StyledBox = styled(Box)`
  background-color: #fff;
  width: 300px;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 135px;
  box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.2);
`;

export const StyledInput = styled(InputBase)`
  color: #000;
  flex-grow: 1;
  height: 50px;
  font-family: "Maven Pro", sans-serif;
`;

export const StyledDivider = styled(Divider)`
  background-color: #000;
  color: #000;
  width: 1px;
  height: 31px;
  margin-top: 8px;
`;

export const StyledOuterTableWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 96px);
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
`;

export const StyledInnerTableWrapper = styled.div`
  width: 98%;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 1px 1px #888888;
  margin-left: auto;
  margin-right: auto;
  min-width: 1805px;
`;

export const StyledHeader = styled.th`
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
  position: sticky;
  top: -10px;
  background: white;
  z-index: 1;
`;

export const StyledLoader = styled(CircularProgress)`
  color: black;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 23px;
`;

export const StyledTd = styled.td`
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
`;
