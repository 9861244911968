export function getFormattedDate(date: any) {
  const test = new Date(date.seconds * 1000);
  var year = test.getFullYear();
  var month = (1 + test.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;
  var day = test.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return month + "/" + day + "/" + year;
}

export const getEvents = (sites: any[]) => {
  let temp: any = [];

  sites.forEach((site: any) =>
    site.dataFields.forEach((field: any) => {
      //Filter these 2 out, dont have date inputs for them
      if (field.name !== "No Report Type" && field.name !== "Stormwater") {
        let split = field.jsDate.split("/");
        let dateFormat = split[2] + "-" + split[0] + "-" + split[1];

        let titleFormat = field.name;
        temp.push({
          display: field.name,
          title: titleFormat,
          date: dateFormat,
          groupId: dateFormat,
          id: site.siteNumber,
        });
      }
    })
  );

  return temp;
};
