import styled from "@emotion/styled";
import { InputBase, Tabs, TextField, Typography } from "@mui/material";

export const StyledFlex = styled.div<{ mobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.mobile ? "row" : "column")};
`;

export const StyledHeader = styled.h3`
  font-family: "Maven Pro", sans-serif;
  font-size: 24px;
  margin-right: 15px;
  margin-bottom: 0px;
`;

export const StyledSecondary = styled(Typography)<{ showMargin?: boolean }>`
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  margin-bottom: ${(props) => (props.showMargin ? "5px" : "0px")};
`;

export const StyledSecondaryFlex = styled.div<{ lastItem?: boolean }>`
  display: flex;
  gap: 5px;
  margin-bottom: ${(props) => (props.lastItem ? "25px" : "10px")};
`;

export const StyledParagraph = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-self: self-start;
`;

export const StyledImage = styled.img<{ mobile: boolean }>`
  width: ${(props) => (props.mobile ? "350px" : "100%")};
  border-radius: 15px;
  flex: 1 1 auto;
  margin: 20px;
  max-width: ${(props) => (props.mobile ? "400px" : "none")};
  cursor: ${(props) => (props.mobile ? "pointer" : "initial")};
`;

export const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    height: "36px",
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

export const StyledSmallHeader = styled.h3`
  font-family: "Maven Pro", sans-serif;
`;

export const StyledInput = styled(InputBase)<{ mobile: boolean }>`
  color: #000;
  flex-grow: 1;
  height: 36.6px;
  font-family: "Maven Pro", sans-serif;
  border: 0.5px solid #cccccc;
  padding: 5px;
  min-width: 175px;
  border-radius: 5px;
  max-width: ${(props) => (props.mobile ? "300px" : "100%")};
  margin-top: ${(props) => (props.mobile ? "0" : "15px")};
`;

export const StyledGridWrapper = styled.div`
  min-height: 300px;
  width: 100%;
  margin-top: 5px;
  height: calc(100vh - 165px);
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-left: 20px;
`;

export const StyledTabs = styled(Tabs)`
  .css-1bez0fg-MuiTabs-root {
    overflow: unset !important;
  }
`;

export const StyledWrapper = styled.div`
  position: fixed;
  width: 80%;
  height: auto;
  max-height: 90%;
  left: 50%;
  top: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  max-width: 1000px;
`;

export const StyledInner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  gap: 15px;
`;

export const StyledFullScreenImageWrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.9);
`;

export const StyledImageWrapper = styled.div`
  position: fixed;
  width: 80%;
  left: 50%;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
`;

export const StyledImageMain = styled.img`
  width: 100%;
  border-radius: 10px;
  display: block;
  margin: auto;
  max-width: 95vh;
`;

export const StyledPinWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
