import { supabase } from "../../supabase";

export async function UpdatePasswordFn(auth_id: string, password: string) {
  return new Promise(async function (resolve, reject) {
    const updateAuth = await supabase.auth.api.updateUserById(auth_id, {
      password: password,
    });

    if (updateAuth.error !== null) {
      return resolve(false);
    }

    return resolve(true);
  });
}
