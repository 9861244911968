import { supabase } from "../../supabase";

export const UploadContactFn = async (values: any) => {
  return new Promise(async function (resolve, _reject) {
    if (values.id) {
      //update
      const res = await supabase
        .from("contacts")
        .update({
          company: values.company,
          clientName: values.clientName,
          number: values.number,
          email: values.email,
          address: values.address,
          tags: values.tags,
          alwaysAvailable: values.alwaysAvailable,
          website: values.website,
        })
        .eq("id", values.id);

      if (res.error !== null) {
        return resolve(false);
      }

      return resolve(true);
    } else {
      //add
      const res = await supabase.from("contacts").insert(values);

      if (res.error !== null) {
        return resolve(false);
      }

      return resolve(true);
    }
  });
};
