/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";

import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import {
  Badge,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";

import styled from "@emotion/styled";
import { ApprovedPassword } from "./ApprovedPassword";
import { supabase } from "../../supabase";
import AlertToaster from "../../components/alert/Alert";
import { useRequests } from "../../hooks/useRequests";
import { NotificationsActive } from "@mui/icons-material";

const StyledTextField = styled(TextField)({
  marginBottom: 10,
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(255, 255, 255)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "white",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

interface NewUserDialogProps {
  onSave: () => void;
}

export const ViewRequests = ({ onSave }: NewUserDialogProps) => {
  const mobile = useMediaQuery("(min-width: 500px)");
  const [open, setOpen] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [showApproved, setShowApproved] = useState<boolean>(false);
  const [approvedUser, setApprovedUser] = useState<any>();
  const { requests } = useRequests();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = async (user: any) => {
    setApprovedUser(user);
    setShowApproved(true);
  };

  const handleDeny = async (email: string) => {
    await supabase.functions
      .invoke("emailDenied", {
        responseType: "text",
        body: JSON.stringify({ email: email }),
      })
      .then(async () => {
        setAlertMessage("User Denied");
        setSuccessAlert(true);

        await supabase.from("requests").delete().match({ email: email });

        const newRequests: Array<any> = [];
        requests.map((request) => {
          if (request.email !== email) {
            newRequests.push(request);
          }
        });

        setTimeout(function () {
          setSuccessAlert(false);
        }, 3000);
      });
  };

  return (
    <div style={{ width: mobile ? "initial" : "100%" }}>
      {successAlert ? (
        <AlertToaster message="Success" severity="success" />
      ) : null}

      <Button
        variant="outlined"
        onClick={handleClickOpen}
        fullWidth
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          color: "#000",
          flexGrow: 1,
        }}
      >
        <Badge
          badgeContent={requests.length}
          color="primary"
          style={{ marginRight: 10 }}
        >
          <NotificationsActive color="action" />
        </Badge>
        View Requested Users
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {showApproved && (
          <ApprovedPassword
            onSave={onSave}
            name={approvedUser.name}
            email={approvedUser.email}
            reasonForRequest={approvedUser.reasonForRequest}
            setShowApproved={setShowApproved}
            setRequests={(test: any) => {}}
            requests={requests}
          />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              position: "fixed",
              width: "50%",
              height: "auto",
              minHeight: 450,
              left: "50%",
              top: "50%",
              overflow: "hidden",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: 10,
              maxWidth: 1800,
              minWidth: 300,
            }}
          >
            <IconButton
              style={{ position: "absolute", top: 5, right: 5 }}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
            {/* BOUNDARY INSIDE */}
            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontSize: 17,
                  textAlign: "center",
                  fontFamily: "'Maven Pro', sans-serif",
                  marginBottom: 15,
                  color: "#000",
                }}
              >
                Requested Users
              </Typography>
              <div
                style={{
                  width: "100%",
                  height: 500,
                  overflowY: "auto",
                }}
              >
                {requests.length > 0 ? (
                  requests.map((user, idx, arr) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "calc(100% - 25px)",
                          padding: 10,
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography
                            style={{
                              fontFamily: "'Maven Pro', sans-serif",
                              fontSize: 14,
                              color: "#000",
                            }}
                          >
                            {user.name}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "'Maven Pro', sans-serif",
                              fontSize: 14,
                              color: "#000",
                            }}
                          >
                            {user.email}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "'Maven Pro', sans-serif",
                              fontSize: 14,
                              color: "#000",
                            }}
                          >
                            {user.reasonForRequest}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: 50,
                            gap: 10,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="success"
                            onClick={() => handleApprove(user)}
                            style={{
                              fontFamily: "'Maven Pro', sans-serif",
                              color: "#000",
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeny(user.email)}
                            style={{
                              fontFamily: "'Maven Pro', sans-serif",
                              color: "#000",
                            }}
                          >
                            Deny
                          </Button>
                        </div>
                      </div>
                      {idx !== arr.length - 1 && <Divider />}
                    </>
                  ))
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "'Maven Pro', sans-serif",
                      color: "#000",
                    }}
                  >
                    No Requests
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
