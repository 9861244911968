import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PapeLogo from "../../images/PapeVector.svg";
import { supabase } from "../../supabase";
import {
  StyledEmailText,
  StyledImage,
  StyledMenuItem,
  StyledNav,
  StyledWrapper,
} from "./Navbar.styled";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const mobile = useMediaQuery("(min-width:435px)");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  return (
    <StyledNav>
      <StyledWrapper>
        <StyledImage src={PapeLogo} alt="pape" />
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          <>
            {mobile ? (
              <Button
                href="/"
                startIcon={<LibraryBooksIcon />}
                size="small"
                style={{
                  color: "white",
                  borderColor: "white",
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                Library
              </Button>
            ) : (
              <IconButton href="/">
                <LibraryBooksIcon style={{ fontSize: 20, color: "#ffffff" }} />
              </IconButton>
            )}
          </>
          <>
            {mobile ? (
              <Button
                href="/profile"
                startIcon={<AccountBoxIcon />}
                size="small"
                style={{
                  color: "white",
                  borderColor: "white",
                  fontFamily: "'Maven Pro', sans-serif",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                Profile
              </Button>
            ) : (
              <IconButton href="/profile">
                <AccountBoxIcon
                  style={{
                    fontSize: 20,
                    color: "#ffffff",
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                />
              </IconButton>
            )}
          </>
          {mobile ? (
            <Button
              onClick={signOut}
              startIcon={<ExitToAppIcon />}
              size="small"
              style={{
                color: "white",
                borderColor: "white",
                fontFamily: "'Maven Pro', sans-serif",
                marginRight: 5,
              }}
            >
              Logout
            </Button>
          ) : (
            <IconButton onClick={signOut}>
              <ExitToAppIcon
                style={{ fontSize: 20, color: "#ffffff", marginRight: 5 }}
              />
            </IconButton>
          )}
        </div>
      </StyledWrapper>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={0}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledEmailText>
          <b>email</b>
        </StyledEmailText>
        <Divider style={{ marginTop: 10 }} />
        {/* <Link to="/" style={{ color: "#000000", textDecoration: "none" }}>
          <StyledMenuItem onClick={handleClose}>
            <HomeIcon style={{ marginRight: 10, fontSize: 20 }} /> Library
          </StyledMenuItem>
        </Link> */}
        <Link
          to="/profile"
          style={{ color: "#000000", textDecoration: "none" }}
        >
          <StyledMenuItem onClick={handleClose}>
            <AccountBoxIcon style={{ marginRight: 10, fontSize: 20 }} /> Profile
          </StyledMenuItem>
        </Link>
        {/* <Link
          to="/calendar"
          style={{ color: "#000000", textDecoration: "none" }}
        >
          <StyledMenuItem onClick={handleClose}>
            <EventAvailableIcon style={{ marginRight: 10, fontSize: 20 }} />{" "}
            Calendar
          </StyledMenuItem>
        </Link> */}
        {/* <Link
          to="/sitesoverview"
          style={{ color: "#000000", textDecoration: "none" }}
        >
          <StyledMenuItem onClick={handleClose}>
            <ListIcon style={{ marginRight: 10, fontSize: 20 }} /> Sites
            Overview
          </StyledMenuItem>
        </Link> */}
        <StyledMenuItem onClick={() => signOut()}>
          <ExitToAppIcon style={{ marginRight: 10, fontSize: 20 }} /> Logout
        </StyledMenuItem>
      </Menu>
    </StyledNav>
  );
};

export default Navbar;
