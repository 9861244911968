import styled from "@emotion/styled";
import { Check } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { useState } from "react";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { StyledField, StyledHeader } from "./Feedback.styles";
import * as Yup from "yup";
import { SendFeedbackEmailFn } from "../../backends/edgeFunctions/SendFeedbackEmailFn";
import { useProvideAuth } from "../../auth/AuthProvider";
import { useUser } from "../../hooks/useUser";

export interface FeedbackForm {
  type: string;
  feedback: string;
  firstName: string;
  lastName: string;
  email?: string;
}

export const YupSchemaFeedback = () => {
  return Yup.object().shape({
    type: Yup.string().required("Required"),
    feedback: Yup.string()
      .min(10, "Minimum 10 characters")
      .max(10000, "Maximum 10,000 characters")
      .required("Required"),
    firstName: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(50, "Maximum 50 characters")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Minimum 2 characters")
      .max(50, "Maximum 50 characters")
      .required("Required"),
  });
};

export const Feedback = () => {
  const auth = useProvideAuth();
  const { user } = useUser(auth.user.email);
  const [submitted, setSubmitted] = useState(false);
  const formikContext = useFormikContext<FeedbackForm>();

  const handleSave = (values: FeedbackForm) => {
    setSubmitted(true);
    values.email = user[0]?.email ?? "unknown email";
    SendFeedbackEmailFn(values);
  };

  const submitAnother = () => {
    setSubmitted(false);
    formikContext.resetForm();
  };

  return (
    <PageWrapper>
      <StyledHeader>Feedback Form</StyledHeader>
      <p style={{ marginTop: 5 }}>
        We would love to hear your thoughts, suggestions, concerns or problems
        with anything so we can improve!
      </p>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      {submitted ? (
        <>
          <h1>Thanks!</h1>
          <h3>Your feedback has been submitted and is highly valued.</h3>
          <Button
            onClick={submitAnother}
            variant="outlined"
            endIcon={<Check />}
            style={{
              color: "black",
              borderColor: "black",
              marginTop: 20,
            }}
          >
            Submit Another
          </Button>
        </>
      ) : (
        <Formik<FeedbackForm>
          enableReinitialize
          initialValues={{
            type: "Comment",
            feedback: "",
            firstName: "",
            lastName: "",
          }}
          onSubmit={handleSave}
          validationSchema={YupSchemaFeedback}
        >
          {({
            errors,
            submitForm,
            handleChange,
            values,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <p>Feedback Type</p>
              <FormControl>
                <RadioGroup
                  row
                  name="type"
                  value={values.type}
                  onClick={handleChange}
                >
                  <FormControlLabel
                    value="Comment"
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        Comment
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="Suggestion"
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        Suggestion
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="Concern/Problem"
                    control={<Radio />}
                    label={
                      <Typography
                        style={{ fontFamily: "'Maven Pro', sans-serif" }}
                      >
                        Concern/Problem
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
              <p style={{ marginTop: 10 }}>Describe Your Feedback: * </p>
              <StyledField
                style={{
                  marginBottom: 10,
                  color: "red",
                }}
                fullWidth
                id="outlined-multiline-static"
                multiline
                minRows={8}
                maxRows={15}
                name="feedback"
                onChange={handleChange}
                error={!!errors.feedback && touched.feedback}
                helperText={errors.feedback}
              />
              <p style={{ marginTop: 5, marginBottom: 5 }}>Name: *</p>
              <div style={{ display: "flex", gap: 10 }}>
                <StyledTextField
                  name="firstName"
                  onChange={handleChange}
                  id="outlined-size-normal"
                  placeholder="First"
                  variant="outlined"
                  fullWidth
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    maxWidth: 350,
                  }}
                  error={!!errors.firstName && touched.firstName}
                  helperText={errors.firstName}
                />
                <StyledTextField
                  name="lastName"
                  onChange={handleChange}
                  id="outlined-size-normal"
                  placeholder="Last"
                  variant="outlined"
                  fullWidth
                  style={{
                    fontFamily: "'Maven Pro', sans-serif",
                    maxWidth: 350,
                  }}
                  error={!!errors.lastName && touched.lastName}
                  helperText={errors.lastName}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  endIcon={<Check />}
                  onClick={submitForm}
                  style={{
                    color: "green",
                    borderColor: "green",
                    marginTop: 20,
                  }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </PageWrapper>
  );
};

export const StyledTextField = styled(TextField)({
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});
