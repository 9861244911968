import { supabase } from "../../supabase";

export async function DeleteSiteFn(info: any) {
  return new Promise(async function (resolve, reject) {
    //DELETE STORAGE 'sites' - 3 top level files
    const filesToDelete = await supabase.storage
      .from("sites")
      .list(`${info.siteNumber}`);

    const filesPathsArray = filesToDelete?.data?.map(
      (x) => `${info.siteNumber}/${x.name}`
    );

    if (filesPathsArray && filesPathsArray.length > 0) {
      const filesDelete = await supabase.storage
        .from("sites")
        .remove(filesPathsArray);

      if (filesDelete.error !== null) {
        return resolve(false);
      }

      const checkNestedFolder = filesToDelete?.data
        ?.map((x) => x.name)
        .includes("HelpfulDocuments");

      //means it has 'Helpful Documents' folder
      if (checkNestedFolder) {
        const nestedFilesToDelete = await supabase.storage
          .from("sites")
          .list(`${info.siteNumber}/HelpfulDocuments`);
        const nestedFilesPathsArray = nestedFilesToDelete?.data?.map(
          (x) => `${info.siteNumber}/HelpfulDocuments/${x.name}`
        );

        if (nestedFilesPathsArray && nestedFilesPathsArray.length > 0) {
          const nestedFilesDelete = await supabase.storage
            .from("sites")
            .remove(nestedFilesPathsArray);

          if (nestedFilesDelete.error !== null) {
            return resolve(false);
          }
        }
      }
    }

    //DELETE STORAGE 'records'
    const recordsToDelete = await supabase.storage
      .from("records")
      .list(`${info.siteNumber}`);

    const recordPathsArray = recordsToDelete?.data?.map(
      (x) => `${info.siteNumber}/${x.name}`
    );

    if (recordPathsArray && recordPathsArray.length > 0) {
      const recordsDelete = await supabase.storage
        .from("records")
        .remove(recordPathsArray);

      if (recordsDelete.error !== null) {
        return resolve(false);
      }
    }

    //DELETE DATABASE 'site'
    const deleteSite = await supabase
      .from("sites")
      .delete()
      .match({ id: info.id });

    if (deleteSite.error !== null) {
      return resolve(false);
    }

    //DELETE DATABASE 'records'
    const deleteRecords = await supabase
      .from("records")
      .delete()
      .eq("siteNumber", info.siteNumber);

    if (deleteRecords.error !== null) {
      return resolve(false);
    }

    return resolve(true);
  });
}
