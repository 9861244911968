/* eslint-disable */
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";

import styled from "@emotion/styled";
import { supabase } from "../../supabase";

const StyledTextField = styled(TextField)({
  marginBottom: 10,
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    color: "rgb(0, 0, 0)",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label": {
    color: "black",
    fontFamily: "'Maven Pro', sans-serif",
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

interface UserProps {
  name: string;
  email: string;
  reasonForRequest: string;
  onSave: () => void;
  setShowApproved: (show: boolean) => void;
  setRequests: (requests: Array<any>) => void;
  requests: Array<any>;
}

export const ApprovedPassword = ({
  name,
  email,
  reasonForRequest,
  onSave,
  setShowApproved,
  setRequests,
  requests,
}: UserProps) => {
  const [passwordString, setPasswordString] = useState("");
  const [showPasswordString, setShowPasswordString] = useState(false);

  const handleClose = () => {
    setShowApproved(false);
    onSave();
  };

  const handleApprove = async (values: any) => {
    const data = {
      email: email.toLowerCase(),
      admin: values.administrator,
      name: name,
      introduction: "Resume Introduction...",
      location: "TBD",
      position: "TBD",
      yearsWithCompany: 1,
      cantView: "{}",
      cantUpload: "{}",
      cantEdit: "{}",
      cantDelete: "{}",
      auth_id: "",
    };

    const names = name.split(" ").filter((x) => x.replace(/\s/g, ""));
    const password = names[0].charAt(0) + names[1] + values.fourDigitKey;

    setPasswordString(password);
    setShowPasswordString(true);

    await supabase.auth.api
      .createUser({
        email: email.toLowerCase(),
        password: password,
        email_confirm: true,
      })
      .then((res) => {
        if (res.error === null) {
          data.auth_id = res.data.id;
          supabase
            .from("users")
            .insert(data)
            .then(async () => {
              await supabase.from("requests").delete().match({ email: email });
            })
            .then(async () => {
              await supabase.functions
                .invoke("emailApproved", {
                  responseType: "text",
                  body: JSON.stringify({
                    name: name,
                    email: email,
                    status: "Approved",
                    password: password,
                  }),
                })
                .then(() => {
                  return true;
                });
            });
          return true;
        } else {
          return false;
        }
      });

    const newRequests: Array<any> = [];
    requests.map((request) => {
      if (request.email !== email) {
        newRequests.push(request);
      }
    });

    setRequests(newRequests);
  };

  const YupSchema = () => {
    return Yup.object().shape({
      fourDigitKey: Yup.string()
        .min(4, "Must be (4) characters")
        .max(4, "Must be (4) characters")
        .trim()
        .required("Required"),
      administrator: Yup.boolean(),
    });
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            fourDigitKey: "",
            administrator: false,
          }}
          validationSchema={YupSchema}
          onSubmit={handleApprove}
        >
          {({ errors, submitForm, handleChange, values, setFieldValue }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  position: "fixed",
                  width: "30%",
                  height: "auto",
                  left: "50%",
                  top: "50%",
                  overflow: "hidden",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  maxWidth: 1800,
                }}
              >
                <IconButton
                  style={{ position: "absolute", top: 5, right: 5 }}
                  onClick={handleClose}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                {/* BOUNDARY INSIDE */}
                <div
                  style={{
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 20,
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 17,
                      textAlign: "center",
                      fontFamily: "'Maven Pro', sans-serif",
                      marginBottom: 15,
                    }}
                  >
                    Approved
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      height: 320,
                      overflowY: "auto",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 15,
                        marginBottom: 5,
                        marginTop: 10,
                      }}
                    >
                      {name}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 15,
                        marginBottom: 5,
                      }}
                    >
                      {email}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "'Maven Pro', sans-serif",
                        fontSize: 15,
                        marginBottom: 30,
                      }}
                    >
                      {reasonForRequest}
                    </Typography>

                    {!showPasswordString ? (
                      <>
                        <StyledTextField
                          name="fourDigitKey"
                          id="outlined-basic"
                          error={Boolean(errors.fourDigitKey)}
                          helperText={errors.fourDigitKey ?? ""}
                          label="4 Digit Unique Key"
                          variant="outlined"
                          onChange={handleChange}
                          fullWidth
                          style={{ marginBottom: 10 }}
                        />
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{}}
                                checked={values.administrator}
                                name="administrator"
                                onClick={() =>
                                  setFieldValue(
                                    "administrator",
                                    !values.administrator
                                  )
                                }
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontSize: 15,
                                  fontFamily: "'Maven Pro', sans-serif",
                                }}
                              >
                                Administrator (All Privilege Access)
                              </Typography>
                            }
                          />
                        </FormGroup>
                        <Button
                          variant="outlined"
                          onClick={submitForm}
                          style={{
                            marginBottom: 5,
                            fontFamily: "'Maven Pro', sans-serif",
                            flexGrow: 1,
                            width: "100%",
                            marginTop: 15,
                          }}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <Typography
                        style={{
                          fontFamily: "'Maven Pro', sans-serif",
                          fontSize: 15,
                          marginBottom: 30,
                        }}
                      >
                        Generated Password: <i>{passwordString}</i>
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
