import { supabase } from "../../supabase";

export const UploadContactPdfFn = async (values: any, state: any) => {
  return new Promise(async function (resolve, reject) {
    const existing = await supabase
      .from("contactsPdfs")
      .select()
      .eq("path", `${state}/${values.documentName}`);

    //file already exists
    if (!!existing.body?.length) {
      return resolve(false);
    }

    let urlUpload = await uploadPdf(values, state);

    if (urlUpload.error) {
      return resolve(false);
    }

    values.state = state;
    values.path = `${state}/${values.documentName}`;
    const res = await supabase.from("contactsPdfs").insert(values);

    if (res.error !== null) {
      return resolve(false);
    }

    return resolve(true);
  });
};

async function uploadPdf(info: any, state: any) {
  return await supabase.storage
    .from("contact-pdfs")
    .upload(`${state}/${info.documentName}`, info.path);
}
