import styled from "@emotion/styled";
import { Dialog, Typography } from "@mui/material";

export const StyledProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 90px;
`;

export const StyledCalendarWrapper = styled.div`
  position: relative;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;

export const StyledInnerDialog = styled.div`
  position: fixed;
  width: auto;
  height: auto;
  max-height: 80%;
  min-width: 300px;
  left: 50%;
  top: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  max-width: 1800px;
`;

export const StyledFlexGrow = styled.div`
  flex-grow: 1;
`;

export const StyledDialog = styled(Dialog)`
  position: fixed;
  z-index: 100;
`;

export const StyledTitle = styled(Typography)`
  text-overflow: ellipsis;
  font-family: "Maven Pro", sans-serif;
  font-size: 16px;
`;

export const StyledSecondary = styled(Typography)`
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
`;

export const StyledDate = styled(Typography)`
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  margin-bottom: 12px;
  text-overflow: ellipsis;
`;
