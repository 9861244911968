import { Link } from "@mui/material";

export const DocumentationItemComponent: React.FC = () => {
  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
      }}
    >
      <Link
        href="#"
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          fontSize: 14,
        }}
      >
        Tier II Reporting
      </Link>
    </div>
  );
};

export const SdsItemComponent: React.FC = () => {
  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
      }}
    >
      <Link
        href="https://pape.kha.com/"
        target="_blank"
        rel="noreferrer"
        style={{
          fontFamily: "'Maven Pro', sans-serif",
          fontSize: 14,
          color: "black",
        }}
      >
        KHA Portal
      </Link>
    </div>
  );
};
