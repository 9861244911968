import { supabase } from "../../supabase";

export async function NewUserFn(data: any, password: string) {
  return new Promise(async function (resolve, reject) {
    let test = await supabase.auth.api
      .createUser({
        email: data.email,
        password: password,
        email_confirm: true,
      })
      .then(async (res) => {
        if (!res.error) {
          data.auth_id = res.data.id;
          await supabase.from("users").insert(data);
          return true;
        } else {
          return false;
        }
      });

    return resolve(test);
  });
}
