import styled from "@emotion/styled";
import { MenuItem, Typography } from "@mui/material";

export const StyledNav = styled.div`
  height: 45px;
  background-color: #000;
  z-index: 1001;
  width: 100%;
  position: relative;
`;

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: 2000px;
  display: flex;
  justify-content: space-between;
`;

export const StyledImage = styled.img`
  width: 45px;
  margin-left: 10px;
  margin-top: 0px;
  height: 45px;
`;

export const StyledEmailText = styled(Typography)`
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  font-family: "Maven Pro", sans-serif;
  display: flex;
  font-size: 14px;
  color: #000;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
`;
