import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { users } from "../store/Store";
import { useEffect } from "react";
import { GetUsersFn } from "../backends/queries/GetUsersFn";

export const useUsers = () => {
  const { data, isSuccess, refetch, isLoading } = useQuery("users", GetUsersFn);

  const [usersList, setUsers] = useRecoilState(users);

  useEffect(() => {
    isSuccess && data && setUsers(data);
  }, [data, isSuccess, setUsers]);

  return { users: usersList, refreshUsers: refetch, loading: isLoading };
};
