import { FeedbackForm } from "../../pages/feedback/Feedback";
import { supabase } from "../../supabase";

export const SendFeedbackEmailFn = async (values: FeedbackForm) => {
  return new Promise(async function (resolve) {
    //insert into feedback table
    await supabase.from("feedback").insert(values);

    //send email
    const test = await supabase.functions.invoke("feedback", {
      responseType: "text",
      body: JSON.stringify({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        type: values.type,
        feedback: values.feedback,
      }),
    });

    return resolve(test);
  });
};
