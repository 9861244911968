import styled from "@emotion/styled";
import { Button, Card, Grid, Typography } from "@mui/material";

export const StyledCardWrapper = styled(Grid)`
  grid-auto-rows: 1fr;
`;

export const StyledCardItem = styled(Card)`
  position: relative;
  flex-grow: 1;
  height: 180px;
  transition: transform 0.2s;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;

export const StyledNav = styled(Button)`
  font-family: "Maven Pro", sans-serif;
  color: #000;

  &:hover {
    color: #fff;
  }
`;

export const StyledImage = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: black;
`;

export const StyledCardTitle = styled(Typography)`
  font-family: "Maven Pro", sans-serif;
  font-size: 18px;
  margin-right: 5px;
`;

export const StyledCardFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
`;
