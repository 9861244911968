import { supabase } from "../../supabase";

export async function DeleteContactFn(data: any) {
  return new Promise(async function (resolve, reject) {
    const deleteDb = await supabase
      .from("contacts")
      .delete()
      .match({ id: data.id });

    return resolve(deleteDb);
  });
}
